import { SET_SIDEBAR } from '../Actions/types';
import { SET_CHATBAR } from '../Actions/types';

const initialState = {
  open: true,
  chatOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDEBAR:
      return {
        ...state,
        open: action.payload,
      };
    case SET_CHATBAR:
      return {
        ...state,
        chatOpen: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
