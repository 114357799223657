import {
  SET_CHAT_MEMBERSHIP,
  NEW_CHAT_MEMBERSHIP,
  NEW_CHAT_MESSAGE,
} from './types';
import PubNub from 'pubnub';

export default function setChatMemberships(
  user,
  type = 'setChat',
  channel,
  newUser
) {
  return async (dispatch, getState) => {
    const items = getState().debug;
    const event = getState().event;

    try {
      const pubnub = new PubNub({
        publishKey: event.event.publish_key,
        subscribeKey: event.event.subscribe_key,
        uuid: user.email,
        autoNetworkDetection: true,
        restore: true,
      });

      await pubnub.addListener({
        status: function (statusEvent) {},
        message: (response) => {
          const newMessage = {
            channel: response.channel,
            timetoken: response.timetoken,
            entry: {
              from: response.message.from,
              text: response.message.text,
              accountName: response.message.accountName,
              department: response.message.department,
              email: response.message.email,
              first_name: response.message.first_name,
              last_name: response.message.last_name,
              roles: response.message.roles,
              site: response.message.site,
              title: response.message.title,
            },
          };
          if (newMessage.channel === user.email) {
            typeHandler();
          }
          dispatch(
            setChatMembershipsAsync(
              null,
              null,
              null,
              newMessage,
              'NEW_CHAT_MESSAGE'
            )
          );
        },
        presence: function (presenceEvent) {},
      });

      // Set the current user's meta data
      await pubnub.objects.setUUIDMetadata({
        data: {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          custom: {
            accountName: user.account_name,
            department: user.department,
            site: user.site,
            title: user.title,
          },
        },
      });

      const typeHandler = async () => {
        switch (type) {
          case 'setChat':
            let memberships = [];
            let subscriptions = [];

            // Get all memberships that the current user is a part of
            const newMemberships = await pubnub.objects.getMemberships({
              uuid: user.email,
              include: {
                channelFields: true,
              },
            });

            newMemberships.data.map((e) => memberships.push(e));

            // Subscribe to each one of these memberships
            await pubnub.subscribe({
              channels: [
                ...newMemberships.data.map((element) => element.channel.id),
                user.email,
              ],
              withPresence: true,
            });

            // Get the current user's subscribed channels.
            const newSubscriptions = await pubnub.getSubscribedChannels();

            newSubscriptions.map((e) => subscriptions.push(e));

            let users = [];

            const channelUsers = await memberships.map((membership) => {
              return pubnub.objects
                .getChannelMembers({
                  channel: membership.channel.id,
                })
                .then((response) => {
                  let newChannelUser = response.data.filter(
                    (u) => u.uuid.id !== user.email
                  )[0];
                  newChannelUser.channel = membership.channel.id;
                  users.push(newChannelUser);
                })
                .catch((err) => {
                  console.log('not found');
                });
            });

            await Promise.all(channelUsers);

            dispatch(
              setChatMembershipsAsync(
                memberships,
                subscriptions,
                null,
                null,
                'SET_CHAT_MEMBERSHIP',
                users
              )
            );
            break;
          case 'leave':
            pubnub.removeListener();
            break;
          default:
            break;
        }
      };

      typeHandler();
    } catch (error) {
      if (items.debug) {
        alert(`Error: Could not set the chat membership. ${error}`);
      }
    }
  };
}

function setChatMembershipsAsync(
  memberships,
  subscriptions,
  membership,
  message,
  type = 'SET_CHAT_MEMBERSHIP',
  users
) {
  if (type === 'SET_CHAT_MEMBERSHIP') {
    return {
      type: SET_CHAT_MEMBERSHIP,
      payload: {
        memberships,
        subscriptions,
        users,
      },
    };
  } else if (type === 'NEW_CHAT_MEMBERSHIP') {
    return {
      type: NEW_CHAT_MEMBERSHIP,
      payload: {
        membership,
      },
    };
  } else if (type === 'NEW_CHAT_MESSAGE') {
    return {
      type: NEW_CHAT_MESSAGE,
      payload: {
        message,
      },
    };
  } else {
    return {
      type: SET_CHAT_MEMBERSHIP,
      payload: {
        memberships,
        subscriptions,
      },
    };
  }
}
