import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Client } from '@twilio/conversations';
import twilioInitialize from '../Store/Actions/twilioInitialize';
import { useDispatch, useSelector } from 'react-redux';
import {
  getConversationsClient,
  getConversations,
} from '../Store/Reducers/twilio';
import twilioJoin from '../Store/Actions/twilioJoin';
import ChatConversationScreen from '../Components/ChatConversation';
import { Auth } from 'aws-amplify';
import axios from 'axios';

// const secret = 'w1Y02SMnk8mn0znSgOhY5DcFCUovxe5g';
// const testChatSID = 'SKbf6b85fcbb3669f52eef32da1b939283';
// const accountSid = 'AC7bc3614e50bd43f7106439d011769fda';
// const authToken = '17352b203a3f7b8087259b29f56ee4c6';
// const client = require('twilio')(accountSid, authToken);

/**
 * REMOTE CONVERSATION
 * {
  accountSid: 'AC7bc3614e50bd43f7106439d011769fda',
  chatServiceSid: 'IS63492420ac934168a8e313a8cc1e61a8',
  messagingServiceSid: 'MG7011bb700caa94df3900679b06fb0b35',
  sid: 'CH7522e19bfc034dd0bbfac1a936f4891a',
  friendlyName: 'hello-there',
  uniqueName: null,
  attributes: '{}',
  state: 'active',
  dateCreated: 2021-11-05T17:45:37.000Z,
  dateUpdated: 2021-11-05T17:45:37.000Z,
  timers: {},
  url: 'https://conversations.twilio.com/v1/Conversations/CH7522e19bfc034dd0bbfac1a936f4891a',
  links: {
    participants: 'https://conversations.twilio.com/v1/Conversations/CH7522e19bfc034dd0bbfac1a936f4891a/Participants',
    messages: 'https://conversations.twilio.com/v1/Conversations/CH7522e19bfc034dd0bbfac1a936f4891a/Messages',
    webhooks: 'https://conversations.twilio.com/v1/Conversations/CH7522e19bfc034dd0bbfac1a936f4891a/Webhooks'
  },
  bindings: null
}
 * 
 * 
 */

const conversationSid = 'CH7522e19bfc034dd0bbfac1a936f4891a';

const helloConversationSid = 'CH69e113f6103a4d6a80b93e52199f4316';
export default function RootScreen() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const client = useSelector(getConversationsClient);
  const conversations = useSelector(getConversations);
  useEffect(() => {
    (async () => {
      try {
        setInitailized(false);
        await dispatch(twilioInitialize(user));
        setInitailized(true);
      } catch (err) {
        console.log(err.message);
      }
    })();
  }, [user]);

  const [room, setRoom] = useState('');
  const [initialized, setInitailized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [channel, setChannel] = useState(null);
  const [text, setText] = useState('');

  const joinChannel = async (channel) => {
    if (channel.channelState.status !== 'joined') {
      await channel.join();
      setChannel(channel);
      //  setLoading(false)
    }

    channel.on('messageAdded', function (message) {
      handleMessageAdded(message);
    });
    // scrollToBottom();
  };
  const handleMessageAdded = (message) => {
    setMessages((messages) => [...messages, message]);
    // scrollToBottom();
  };

  // const scrollToBottom = () => {
  //   const scrollHeight = scrollDiv.current.scrollHeight;
  //   const height = scrollDiv.current.clientHeight;
  //   const maxScrollTop = scrollHeight - height;
  //   scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  // };
  const sendMessage = () => {
    if (text) {
      console.log(String(text).trim());
      setLoading(true);
      channel.sendMessage(String(text).trim());
      setText('');
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: 20, paddingTop: 40 }}>
      <button
        onClick={async () => {
          let conversation = await client.getConversationByUniqueName('hello');
          console.log(
            '🚀 ~ file: ChatScreen.js ~ line 105 ~ onClick={ ~ conversation',
            conversation
          );
          if (!conversation) {
            const results = await client.createConversation({
              uniqueName: 'hello',
              friendlyName: 'hello',
            });
            console.log(
              '🚀 ~ file: ChatScreen.js ~ line 116 ~ onClick={ ~ results',
              results
            );
          } else {
            const participants = await conversation.getParticipants();
            console.log(
              '🚀 ~ file: ChatScreen.js ~ line 120 ~ onClick={ ~ participants',
              participants.map((p) => p.identity).join(',')
            );
            await conversation.add('ytwater@gmail.com');
            console.log(`conversation ${conversation.sid} exists`);
          }
        }}
      >
        create conversation hello (local)
      </button>
      <button
        onClick={async () => {
          const data = await Auth.currentSession();
          const response = await axios({
            method: 'post',
            url: `/twilio/conversations`,
            headers: { idtoken: data.idToken.jwtToken },
            data: { friendlyName: 'hello-there' },
          });
          console.log(
            '🚀 ~ file: twilioJoin.js ~ line 27 ~ .then ~ response',
            response
          );
        }}
      >
        create conversation hello-remote (remote)
      </button>

      <button
        onClick={async () => {
          try {
            const data = await Auth.currentSession();
            const response = await axios({
              method: 'get',
              // url: `/twilio/conversations/${helloConversationSid}`,
              url: `/twilio/conversations`,
              headers: { idtoken: data.idToken.jwtToken },
            });
            console.log(
              '🚀 ~ file: twilioJoin.js ~ line 27 ~ .then ~ response',
              response
            );
          } catch (error) {
            console.error('get conversations error:', error.message);
          }
          // setRoom('general');
        }}
      >
        get conversations (remote)
      </button>
      <button
        onClick={async () => {
          try {
            const data = await Auth.currentSession();
            const response = await axios({
              method: 'get',
              // url: `/twilio/conversations/${helloConversationSid}`,
              url: `/twilio/conversations/${conversationSid}`,
              headers: { idtoken: data.idToken.jwtToken },
            });
            console.log(
              '🚀 ~ file: twilioJoin.js ~ line 27 ~ .then ~ response',
              response
            );
          } catch (error) {
            console.error('join conversation error:', error.message);
          }
          // setRoom('general');
        }}
      >
        get conversation (remote)
      </button>
      <br />
      <br />
      <button
        onClick={async () => {
          try {
            const data = await Auth.currentSession();
            const response = await axios({
              method: 'get',
              url: `/twilio/conversations/${helloConversationSid}/participants`,
              headers: { idtoken: data.idToken.jwtToken },
            });
            console.log(
              '🚀 ~ file: twilioJoin.js ~ line 27 ~ .then ~ response',
              response
            );
          } catch (error) {
            console.error('join conversation error:', error.message);
          }
          // setRoom('general');
        }}
      >
        get conversations partipicants (remote)
      </button>
      <br />
      <br />

      <button
        onClick={async () => {
          try {
            const results = await dispatch(
              // remote hello there
              twilioJoin('CH7522e19bfc034dd0bbfac1a936f4891a')
              // twilioJoin('CH69e113f6103a4d6a80b93e52199f4316')
            );
            console.log(
              '🚀 ~ file: ChatScreen.js ~ line 49 ~ onClick={ ~ results',
              results
            );
          } catch (error) {
            console.error('join conversation error:', error.message);
          }
          // setRoom('general');
        }}
      >
        join conversation hello-there (remote)
      </button>
      <button
        onClick={async () => {
          // try {
          //   const channel = await client.getConversationByUniqueName('hello');
          //   console.log(channel);
          //   joinChannel(channel);
          //   // const results = await dispatch(
          //   //   twilioJoin('CHcee314ee3ed54e18b36250a0143714ca')
          //   //   // twilioJoin('CH69e113f6103a4d6a80b93e52199f4316')
          //   // );
          //   // console.log(
          //   //   '🚀 ~ file: ChatScreen.js ~ line 49 ~ onClick={ ~ results',
          //   //   results
          //   // );
          // } catch (error) {
          //   console.error('join conversation error:', error.message);
          // }
          setRoom(helloConversationSid);
          // setRoom(conversationSid);

          // const channel = await client.getConversationByUniqueName('chatroom');
          // console.log(
          //   '🚀 ~ file: ChatScreen.js ~ line 135 ~ onClick={ ~ channel',
          //   channel
          // );
        }}
      >
        join conversation hello-there (local)
      </button>
      <button
        onClick={async () => {
          try {
            const sid = 'CH7522e19bfc034dd0bbfac1a936f4891a';
            const thisConversation = await client.getConversationBySid(sid);
            await thisConversation.join();
            setRoom(sid);
            // const results = await dispatch(
            //   // remote hello there
            //   twilioJoin('CH7522e19bfc034dd0bbfac1a936f4891a')
            //   // twilioJoin('CH69e113f6103a4d6a80b93e52199f4316')
            // );
            // console.log(
            //   '🚀 ~ file: ChatScreen.js ~ line 49 ~ onClick={ ~ results',
            //   results
            // );
          } catch (error) {
            console.error('join conversation error:', error.message);
          }
          // setRoom('general');
        }}
      >
        leave conversation hello-there (local)
      </button>
      <br />
      <br />
      <button
        onClick={async () => {
          try {
            const results = await dispatch(
              twilioJoin(conversationSid)
              // twilioJoin('CH69e113f6103a4d6a80b93e52199f4316')
            );
            console.log(
              '🚀 ~ file: ChatScreen.js ~ line 49 ~ onClick={ ~ results',
              results
            );
          } catch (error) {
            console.error('join conversation error:', error.message);
          }
          // setRoom('general');
        }}
      >
        join conversation chatroom (remote)
      </button>

      <button
        onClick={async () => {
          // try {
          //   const channel = await client.getConversationByUniqueName('hello');
          //   console.log(channel);
          //   joinChannel(channel);
          //   // const results = await dispatch(
          //   //   twilioJoin('CHcee314ee3ed54e18b36250a0143714ca')
          //   //   // twilioJoin('CH69e113f6103a4d6a80b93e52199f4316')
          //   // );
          //   // console.log(
          //   //   '🚀 ~ file: ChatScreen.js ~ line 49 ~ onClick={ ~ results',
          //   //   results
          //   // );
          // } catch (error) {
          //   console.error('join conversation error:', error.message);
          // }
          setRoom(helloConversationSid);
          // setRoom(conversationSid);

          // const channel = await client.getConversationByUniqueName('chatroom');
          // console.log(
          //   '🚀 ~ file: ChatScreen.js ~ line 135 ~ onClick={ ~ channel',
          //   channel
          // );
        }}
      >
        join conversation chatroom (local)
      </button>
      <br />
      <br />
      {/* <button
        onClick={async () => {
          const channel = await client.getConversationByUniqueName('chatroom');
          console.log(
            '🚀 ~ file: ChatScreen.js ~ line 135 ~ onClick={ ~ channel',
            channel
          );
        }}
      >
        test
      </button> */}
      <h4>Conversations</h4>
      <ul>
        {Object.keys(conversations).map((key) => (
          <li key={conversations[key].sid}>
            entityName: {conversations[key].channelState.friendlyName}{' '}
            <button
              onClick={() => {
                setRoom(conversations[key].sid);
              }}
            >
              join
            </button>
            <button
              onClick={() => {
                conversations[key].leave();
              }}
            >
              leave
            </button>
            <button
              onClick={() => {
                conversations[key].sendMessage('hello');
              }}
            >
              send
            </button>
          </li>
        ))}
      </ul>
      {room && <ChatConversationScreen conversationSid={room} />}
    </div>
  );
}
