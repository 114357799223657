import React, { Component } from 'react';

// Configure Router
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Lobby from './Containers/Lobby';
import LandingPage from './Containers/LandingPages/LandingPage';
import ChatScreen from './Containers/ChatScreen';
import EngagementZone from './Containers/EngagementZone';
import ChatSidebar from './Containers/ChatSidebar';
import NotificationWatcher from './Components/NotificationWatcher';

import Signup from './Containers/Authentication/Signup';
import Signin from './Containers/Authentication/Signin';
import ConfirmationCode from './Containers/Authentication/ConfirmationCode';
import ForgotPassword from './Containers/Authentication/ForgotPassword';
import ExhibitHall from './Containers/Exhibits/ExhibitionHall';
import ExhibitionBooth from './Containers/Exhibits/ExhibitBooth';
import DiscussionForum from './Containers/DiscussionForum';
import PosterSessions from './Containers/Posters/PosterSessions';
import Poster from './Containers/Posters/PosterFull';
import Art from './Containers/Art/Art';
import Help from './Containers/Help';
import Agenda from './Containers/Agenda/Agenda';
import MyPersonalAgenda from './Containers/Agenda/MyPersonalAgenda';
import Briefcase from './Containers/Briefcase/Briefcase';
import ConferenceCenter from './Containers/ConferenceCenter/ConferenceCenter';
import ChatRoom from './Containers/Chat/Chat';
import Unregistered from './Containers/Unregistered';
import Layout from './Components/MiniSidebar';
import Settings from './Containers/Settings';
import Leads from './Containers/Leads/Leads';
import Reporting from './Containers/Reporting/Layout';
import DigitalLab from './Containers/Exhibits/DigitalLab';
import WhatsNew from './Containers/WhatsNew';
import Debug from './Containers/Debug';
import Registration from './Containers/Authentication/Registration';
// import Registration from './Containers/Authentication/MerckFederated';
import UnderConstruction from './Containers/UnderConstruction';
import AbstractSubmission from './Containers/Authentication/AbstractSubmission';
import AccountSettings from './Containers/AttendeeAccount/AccountSettings';
import Workshop from './Containers/Workshop/Workshop';
import arrayComparison from './Utilities/arrayComparison';
// import WhereBy from './Containers/WherebyDemo';
import WhereByGuest from './Containers/Whereby/Guest';
import WhereByHost from './Containers/Whereby/Host';
import ActivitiesHall from './Containers/Activities/ActivitiesHall';
import ScienceAsArt from './Containers/ScienceAsArt/ScienceAsArtHall';
import Archives from './Containers/Archive';
import DemoPage from './Containers/DemoPage';

// Helmet
import { Helmet } from 'react-helmet';
import { title, faviconLocation } from './config';

// Matierial Theme
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { colors, demoEnabled } from './config';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setAuthenticatedUser from './Store/Actions/setUser';
import setEventElements from './Store/Actions/setEventElements';

class Router extends Component {
  components = {
    Lobby,
    LandingPage,
    Signup,
    Signin,
    ConfirmationCode,
    ForgotPassword,
    ExhibitHall,
    DigitalLab,
    Debug,
    ExhibitionBooth,
    DiscussionForum,
    PosterSessions,
    Poster,
    Art,
    WhatsNew,
    Help,
    Agenda,
    Briefcase,
    MyPersonalAgenda,
    ConferenceCenter,
    ChatRoom,
    Unregistered,
    Layout,
    Settings,
    Leads,
    Reporting,
    // Privacy,
    AbstractSubmission,
    AccountSettings,
    Workshop,
    ActivitiesHall,
    EngagementZone,
  };

  async componentDidMount() {
    await this.props.setEventElements();
  }

  render() {
    const theme = createMuiTheme({
      palette: {
        primary: {
          main: this.props.event.event.main_color
            ? this.props.event.event.main_color
            : colors.main, //"#311B4C",
          light: this.props.event.event.light_color
            ? this.props.event.event.light_color
            : colors.light, //'#a380cf'
        },
        secondary: {
          main: '#2b377c',
        },
      },
    });

    let zohoSalesIqScript;
    let googleTagManager;
    let googleDataLayer;
    let indexing;

    if (demoEnabled) {
      googleTagManager = (
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-T4TE73PM0Z"
        ></script>
      );

      googleDataLayer = (
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-T4TE73PM0Z');`}
        </script>
      );

      zohoSalesIqScript = (
        <script type="text/javascript" id="zsiqchat">
          {`var $zoho = $zoho || {};
        $zoho.salesiq = $zoho.salesiq || {
          widgetcode:
            'b6446ccf314360784ae16351be4c27a129f7ffe0de9c7bb07624be95d894c87eeb2a5c143039246ce0c80c95a0280405',
          values: {},
          ready: function () {},
        };
        var d = document;
        s = d.createElement('script');
        s.type = 'text/javascript';
        s.id = 'zsiqscript';
        s.defer = true;
        s.src = 'https://salesiq.zoho.com/widget';
        t = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);`}
        </script>
      );
    } else {
      zohoSalesIqScript = (
        <script type="text/javascript" id="zsiqchat">
          {`var $zoho = $zoho || {};
        $zoho.salesiq = $zoho.salesiq || {
          widgetcode:
            'bae35401627d95c796b4ab826839f7550e10cab10d485812886004438114089b201c52485bd3462a4490fe4c8e27d674679df280cb279534ae1b68109fdda64d',
          values: {},
          ready: function () {},
        };
        var d = document;
        s = d.createElement('script');
        s.type = 'text/javascript';
        s.id = 'zsiqscript';
        s.defer = true;
        s.src = 'https://salesiq.zoho.com/widget';
        t = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);`}
        </script>
      );

      indexing = <meta name="robots" content="noindex"></meta>;
    }

    // Available Attendee Routes
    if (!this.props.user.user) {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                {this.props.event ? this.props.event.event.title : title}
              </title>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <meta name="theme-color" content="#000000" />
              <meta
                name="description"
                content="Virtual Environment Powered by PlanetConnect"
              />
              <link
                rel="icon"
                type="image/png"
                href={
                  this.props.event
                    ? this.props.event.event.favicon_location
                    : faviconLocation
                }
                sizes="16x16"
              />
              <link
                rel="apple-touch-icon"
                href={
                  this.props.event
                    ? this.props.event.event.favicon_location
                    : faviconLocation
                }
              />

              {zohoSalesIqScript}
              {googleTagManager}
              {googleDataLayer}
              {indexing}
            </Helmet>
            <Switch>
              <Route path="/signin" render={(props) => <Signin />} />
              <Route
                path="/forgotpassword"
                render={(props) => <ForgotPassword />}
              />
              <Route
                path="/confirmationcode"
                render={(props) => <ConfirmationCode />}
              />
              <Route path="/signup" render={(props) => <Signup />} />
              <Route
                exact
                path="/registration"
                render={(props) => <Registration />}
              />
              <Route
                path="/abstractsubmission"
                render={(props) => <AbstractSubmission />}
              />
              <Route path="/demo" render={(props) => <DemoPage />} />
              <Route path="/" render={(props) => <LandingPage />} />
              <Route exact path="/chat" component={ChatScreen} />
              <Route
                path="/engagementzone"
                render={(props) => <EngagementZone />}
                // component={EngagementZone}
              />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      );
    } else if (this.props.user.user === 'unregistered') {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                {this.props.event ? this.props.event.event.title : title}
              </title>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <meta name="theme-color" content="#000000" />
              <meta
                name="description"
                content="Virtual Environment Powered by PlanetConnect"
              />
              <link
                rel="icon"
                type="image/png"
                href={
                  this.props.event
                    ? this.props.event.event.favicon_location
                    : faviconLocation
                }
                sizes="16x16"
              />
              <link
                rel="apple-touch-icon"
                href={
                  this.props.event
                    ? this.props.event.event.favicon_location
                    : faviconLocation
                }
              />

              {zohoSalesIqScript}
              {googleTagManager}
              {googleDataLayer}
              {indexing}
            </Helmet>
            <Switch>
              <Route path="/signin" render={(props) => <Unregistered />} />
              <Route
                path="/forgotpassword"
                render={(props) => <ForgotPassword />}
              />
              <Route
                path="/confirmationcode"
                render={(props) => <ConfirmationCode />}
              />
              <Route path="/signup" render={(props) => <Signup />} />
              <Route
                exact
                path="/registration"
                render={(props) => <Registration />}
              />
              <Route path="/demo" render={(props) => <DemoPage />} />
              <Route exact path="/" render={(props) => <LandingPage />} />
              <Route
                path="/abstractsubmission"
                render={(props) => <AbstractSubmission />}
              />
              <Route path="/signin" render={(props) => <Unregistered />} />
              <Route path="/" render={(props) => <Unregistered />} />
              <Route exact path="/chat" component={ChatScreen} />
              <Route exact path="/engagementzone" component={EngagementZone} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      );
    } else if (
      this.props.user.user.roles &&
      this.props.event.event.under_construction &&
      !this.props.user.user.email.toLowerCase().includes('@planetconnect.com')
    ) {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                {this.props.event ? this.props.event.event.title : title}
              </title>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <meta name="theme-color" content="#000000" />
              <meta
                name="description"
                content="Virtual Environment Powered by PlanetConnect"
              />
              <link
                rel="icon"
                type="image/png"
                href={
                  this.props.event
                    ? this.props.event.event.favicon_location
                    : faviconLocation
                }
                sizes="16x16"
              />
              <link
                rel="apple-touch-icon"
                href={
                  this.props.event
                    ? this.props.event.event.favicon_location
                    : faviconLocation
                }
              />

              {zohoSalesIqScript}
              {googleTagManager}
              {googleDataLayer}
              {indexing}
            </Helmet>
            <Layout>
              <Switch>
                <Route
                  path="/abstractsubmission"
                  render={(props) => <AbstractSubmission />}
                />
                <Route path="/demo" render={(props) => <DemoPage />} />
                <Route path="/" render={(props) => <UnderConstruction />} />
              </Switch>
            </Layout>
          </BrowserRouter>
        </ThemeProvider>
      );
    } else if (
      this.props.user.user.roles &&
      this.props.event.event.available_routes
    ) {
      let allowedRoutes = JSON.parse(
        this.props.event.event.available_routes
      ).routes.filter((route) => {
        const includesRole = arrayComparison(
          route.allowedRoles,
          this.props.user.user.roles.split(', ')
        );

        if (includesRole) {
          return route;
        } else {
          return false;
        }
      });

      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                {this.props.event ? this.props.event.event.title : title}
              </title>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <meta name="theme-color" content="#000000" />
              <meta
                name="description"
                content="Virtual Environment Powered by PlanetConnect"
              />
              <link
                rel="icon"
                type="image/png"
                href={
                  this.props.event
                    ? this.props.event.event.favicon_location
                    : faviconLocation
                }
                sizes="16x16"
              />
              <link
                rel="apple-touch-icon"
                href={
                  this.props.event
                    ? this.props.event.event.favicon_location
                    : faviconLocation
                }
              />

              {zohoSalesIqScript}
              {googleTagManager}
              {googleDataLayer}
              {indexing}
            </Helmet>
            <Layout>
              <Switch>
                {allowedRoutes.map((route) => {
                  const TagName = this.components[route.container];

                  if (route.title === 'Divider') {
                    return null;
                  }

                  return (
                    <Route
                      key={route.title}
                      path={`${route.route}`}
                      render={(props) => (
                        <TagName timestamp={new Date().toString()} {...props} />
                      )}
                    />
                  );
                })}
                <Route
                  exact
                  path="/engagementzone"
                  render={(props) => <EngagementZone />}
                  // component={ChatScreenTwilio}
                />
                <Route
                  exact
                  path="/scienceasart"
                  render={(props) => <ScienceAsArt />}
                />
                <Route
                  exact
                  path="/archives"
                  render={(props) => <Archives />}
                />
                <Route
                  exact
                  path="/whereby/host"
                  render={(props) => <WhereByHost />}
                />
                <Route
                  exact
                  path="/whereby/guest"
                  render={(props) => <WhereByGuest />}
                />
                <Route path="/demo" render={(props) => <DemoPage />} />
                <Route
                  path="/abstractsubmission"
                  render={(props) => <AbstractSubmission />}
                />
                <Route path="/" render={(props) => <Lobby />} />
                <Route exact path="/chat" component={ChatScreen} />
              </Switch>
              {this.props.twilioInitialized && <ChatSidebar />}
              {this.props.twilioInitialized && <NotificationWatcher />}
            </Layout>
          </BrowserRouter>
        </ThemeProvider>
      );
    } else {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                {this.props.event ? this.props.event.event.title : title}
              </title>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <meta name="theme-color" content="#000000" />
              <meta
                name="description"
                content="Virtual Environment Powered by PlanetConnect"
              />
              <link
                rel="icon"
                type="image/png"
                href={
                  this.props.event
                    ? this.props.event.event.favicon_location
                    : faviconLocation
                }
                sizes="16x16"
              />
              <link
                rel="apple-touch-icon"
                href={
                  this.props.event
                    ? this.props.event.event.favicon_location
                    : faviconLocation
                }
              />

              {zohoSalesIqScript}
              {googleTagManager}
              {googleDataLayer}
              {indexing}
            </Helmet>
            <Switch>
              <Route path="/signin" render={(props) => <Signin />} />
              <Route
                path="/forgotpassword"
                render={(props) => <ForgotPassword />}
              />
              <Route
                path="/confirmationcode"
                render={(props) => <ConfirmationCode />}
              />
              <Route path="/signup" render={(props) => <Signup />} />
              <Route
                path="/registration"
                render={(props) => <Registration />}
              />
              <Route
                path="/abstractsubmission"
                render={(props) => <AbstractSubmission />}
              />
              <Route path="/demo" render={(props) => <DemoPage />} />
              <Route path="/" render={(props) => <LandingPage />} />
              <Route exact path="/chat" component={ChatScreen} />
              <Route path="/engagementzone" component={EngagementZone} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
    twilioInitialized:
      state.twilio.conversationsInitialized &&
      // state.twilio.contactsInitialized &&
      state.twilio.hereNowInitialized,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setAuthenticatedUser: setAuthenticatedUser,
      setEventElements: setEventElements,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);
