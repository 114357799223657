import { ADD_BRIEFCASE_ELEMENT } from './types';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { eventId } from '../../config';
import trackAction from '../../Utilities/tracker';

export default function addBriefcaseElement(
  resourceType,
  resourceId,
  resourceTitle,
  resourceOwner,
  accountId,
  accountName,
  presenter,
  startTime
) {
  let accountIdOptional = null;
  let accountNameOptional = null;
  let presenterOptional = null;
  let startTimeOptional = null;
  if (accountId) {
    accountIdOptional = accountId;
  }
  if (accountName) {
    accountNameOptional = accountName;
  }
  if (presenter) {
    presenterOptional = presenter;
  }
  if (startTime) {
    startTimeOptional = startTime;
  }
  return (dispatch, getState) => {
    const items = getState().debug;

    const event = {
      action: 'Added',
      resource_type: 'Briefcase',
      resource_id: resourceId,
      resource_account_id: resourceOwner,
      resource_name: resourceTitle,
    };

    trackAction(event);

    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'post',
          url: `/briefcase/${eventId}`,
          headers: { idtoken: data.idToken.jwtToken },
          data: {
            resource_type: resourceType,
            resource_id: resourceId,
            resource_title: resourceTitle,
            resource_owner: resourceOwner,
            account_id: accountIdOptional,
            company: accountNameOptional,
            presenter: presenterOptional,
            start_time: startTimeOptional,
          },
        })
          .then((response) => {
            dispatch(addBriefcaseElementAsync(response.data[0]));
          })
          .catch((error) => {
            if (items.debug) {
              alert(`Error: Could not add to the briefcase. ${error}`);
            }
          });
      })
      .catch((error) => {
        if (items.debug) {
          alert(`Error: Could not update the briefcase. ${error}`);
        }
      });
  };
}

function addBriefcaseElementAsync(briefcaseElement) {
  return {
    type: ADD_BRIEFCASE_ELEMENT,
    payload: briefcaseElement,
  };
}
