import { REMOVE_ALL_CHAT } from './types';

export default function removeAllChat(id) {
  return (dispatch) => {
    dispatch(removeAllChatAsync(id));
  };
}

function removeAllChatAsync(id) {
  return {
    type: REMOVE_ALL_CHAT,
    payload: id,
  };
}
