import axios from 'axios';
import { Auth } from 'aws-amplify';
import { eventId } from '../config';

const trackAction = (event) => {
  Auth.currentSession()
    .then((data) => {
      axios({
        method: 'post',
        url: `/audit/${eventId}`,
        headers: {
          idtoken: data.idToken.jwtToken,
        },
        data: event,
      })
        .then((response) => {})
        .catch((error) => {});
    })
    .catch((error) => {});
};

export default trackAction;
