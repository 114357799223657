import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import BriefcaseListItem from './BriefcaseListItem';
import moment from 'moment';
import { eventId } from '../../config';
import prepareObject from '../../Utilities/newObjectFromArrayFieldsAgenda';
import trackAction from '../../Utilities/tracker';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';

const useStyles = (theme) => ({
  gridContainer: {},
  root: {
    width: '100%',
    '& .MuiTypography-root': {
      alignSelf: 'center',
    },
    '& .MuiChip-root	': {
      alignSelf: 'center',
    },
  },
  expansionPanelMain: { padding: '0px 8px 0px 8px' },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: '60%',
    flexShrink: 0,
  },
  panelExpanded: {
    '& .MuiGrid-item': {
      textAlign: 'center',
    },
  },
  icon: {
    minWidth: '10em',
    maxWidth: '30em',
    maxHeight: '30em',
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  headings: {
    padding: theme.spacing(3),
  },
  inputControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  button: {
    color: 'white',
    backgroundColor: '#6f1a1d',
  },
  textBody: {
    fontSize: theme.typography.pxToRem(15),
    alignSelf: 'center',
    textAlign: 'left',
  },
});

class Briefcase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      briefcasePresentations: [],
      activeRoutes: {
        agenda: false,
        posterHall: false,
        exhibitHall: false,
        workshop: false,
      },
    };
  }

  componentDidMount() {
    this.getActiveRoutes();
    this.getBriefcasePresentations();

    const event = {
      action: 'Viewed',
      resource_type: 'Briefcase',
      resource_id: null,
      url_visited: '/briefcase',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    trackAction(event);
  }

  getActiveRoutes = () => {
    let activeRoutes = {
      agenda: false,
      posterHall: false,
      exhibitHall: false,
      workshop: false,
    };
    JSON.parse(this.props.event.event.available_routes).routes.filter(
      (route) => {
        const allowed = route.allowedRoles.filter((role) =>
          this.props.user.user.roles.includes(role)
        );

        if (allowed.length) {
          switch (route.container) {
            case 'Agenda':
              activeRoutes.agenda = true;
              break;
            case 'PosterSessions':
              activeRoutes.posterHall = true;
              break;
            case 'ExhibitHall':
              activeRoutes.exhibitHall = true;
              break;
            case 'Workshop':
              activeRoutes.workshop = true;
              break;
            default:
              break;
          }
          return route;
        } else {
          return null;
        }
      }
    );

    this.setState({
      activeRoutes,
    });
  };

  getBriefcasePresentations = () => {
    this.props.setBriefcase();

    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/agenda/${eventId}`,
          headers: { idtoken: data.idToken.jwtToken },
          params: {
            order_by: 'start_time',
            order_dir: 'asc',
          },
        })
          .then((response) => {
            let abstracts = [];

            for (let x = 0; x < response.data.data.length; x++) {
              let abstract = response.data.data[x];
              // this line of code is because I needed the abstract dates to match after formatting through moment.js
              abstract.date = moment(abstract.start_time).format('YYYY-MM-DD');

              if (abstract.start_time) {
                abstracts.push(abstract);
              }
            }

            let idArray = this.props.briefcase.briefcase
              .filter((item) => {
                if (
                  item.resource_type === 'Presentation' ||
                  item.resource_type === 'Workshop'
                ) {
                  return true;
                } else {
                  return false;
                }
              })
              .map((item) => item.resource_id);

            let briefcaseElements = abstracts.filter((abstract) => {
              let briefcaseElement = null;
              idArray.map((id) => {
                if (id === abstract.abstract_id) {
                  briefcaseElement = abstract;
                  return abstract;
                }
                return abstract;
              });

              if (briefcaseElement) {
                return true;
              } else {
                return false;
              }
            });
            this.setState({
              briefcasePresentations: [...briefcaseElements],
            });
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Briefcase. ${error}`);
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display Briefcase. ${error}`);
        }
      });
  };

  updateState(id) {
    let newState = this.state.briefcasePresentations.filter((item) => {
      return item.abstract_id !== id;
    });
    this.setState({ briefcasePresentations: newState });
  }

  render() {
    const { classes } = this.props;

    const allRoutes = JSON.parse(
      this.props.event.event.available_routes
    ).routes;
    let pageTitle = '';
    allRoutes.forEach((route) => {
      if (route.route === '/briefcase') {
        pageTitle = route.title;
      }
    });

    return (
      <Grid
        container
        layout={'row'}
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xs={12}>
          <Paper>
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="h4"
              color="textPrimary"
            >
              Welcome to Your {pageTitle}
            </Typography>
            <Typography
              style={{ textAlign: 'center', padding: '0px 16px 16px 16px' }}
              variant="subtitle1"
              color="textPrimary"
            >
              Clicking the briefcase icon throughout the event saves that item
              to your briefcase. Any presentations that you have briefcased will
              also display in your personal agenda.
            </Typography>
          </Paper>
        </Grid>
        {this.state.activeRoutes.exhibitHall ? (
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Typography variant="h3" align="center">
                Exhibits
              </Typography>
            </Paper>
            {this.props.briefcase.briefcase
              .filter((favorite) => favorite.resource_type === 'Exhibit')
              .sort(function (a, b) {
                var textA = a.resource_title.toUpperCase();
                var textB = b.resource_title.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
              .map((favorite, index) => {
                return (
                  <BriefcaseListItem
                    key={favorite.resource_id}
                    index={index}
                    favorite={favorite}
                    route="exhibithall/"
                    type="exhibition_id"
                  />
                );
              })}
          </Grid>
        ) : null}
        {this.state.activeRoutes.posterHall ? (
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Typography variant="h3" align="center">
                Posters
              </Typography>
            </Paper>
            {this.props.briefcase.briefcase
              .filter((favorite) => favorite.resource_type === 'Poster')
              .sort(function (a, b) {
                var textA = a.resource_title.toUpperCase();
                var textB = b.resource_title.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
              .map((favorite, index) => {
                return (
                  <BriefcaseListItem
                    key={favorite.resource_id}
                    index={index}
                    favorite={favorite}
                    route="posterhall/"
                    type="poster_id"
                  />
                );
              })}
          </Grid>
        ) : null}
        {this.state.activeRoutes.agenda ? (
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Typography variant="h3" align="center">
                Presentations
              </Typography>
            </Paper>
            {this.state.briefcasePresentations
              .filter((item) => item.accepted_type !== 'Workshop')
              .map((favorite, index) => {
                let requiredFields = [
                  'description',
                  'duration',
                  'endDatetime',
                  'location',
                  'startDatetime',
                  'title',
                ];
                // let requiredFields = ['description', 'duration', 'endDatetime', 'location', 'startDatetime', 'timezone', 'title'];
                let briefcaseAbstract = prepareObject(
                  favorite,
                  requiredFields,
                  'presentation'
                );
                let presentationsFromBriefcase =
                  this.props.briefcase.briefcase.filter(
                    (favorite) => favorite.resource_type === 'Presentation'
                  );
                let singlePresentationBriefcase =
                  presentationsFromBriefcase.filter(
                    (item) => item.resource_id === favorite.abstract_id
                  );
                return (
                  <BriefcaseListItem
                    key={favorite.abstract_id}
                    index={index}
                    favorite={favorite}
                    propsBriefcase={singlePresentationBriefcase}
                    briefcaseAbstract={briefcaseAbstract}
                    updateState={(id) => this.updateState(id)}
                    route="agenda/"
                    type="presentation_id"
                  />
                );
              })}
          </Grid>
        ) : null}
        {this.state.activeRoutes.exhibitHall ? (
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Typography variant="h3" align="center">
                Products
              </Typography>
            </Paper>
            {this.props.briefcase.briefcase
              .filter((favorite) => {
                return favorite.resource_type === 'Product';
              })
              .sort(function (a, b) {
                var textA = a.resource_title.toUpperCase();
                var textB = b.resource_title.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
              .map((favorite, index) => {
                return (
                  <BriefcaseListItem
                    key={favorite.resource_id}
                    index={index}
                    favorite={favorite}
                    route="exhibithall/"
                    type="product_id"
                  />
                );
              })}
          </Grid>
        ) : null}
        {this.state.activeRoutes.workshop ? (
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Typography variant="h3" align="center">
                Workshops
              </Typography>
            </Paper>
            {this.state.briefcasePresentations
              .filter((item) => item.accepted_type === 'Workshop')
              .map((favorite, index) => {
                let requiredFields = [
                  'description',
                  'duration',
                  'endDatetime',
                  'location',
                  'startDatetime',
                  'title',
                ];
                // let requiredFields = ['description', 'duration', 'endDatetime', 'location', 'startDatetime', 'timezone', 'title'];
                let briefcaseAbstract = prepareObject(
                  favorite,
                  requiredFields,
                  'workshop'
                );
                let presentationsFromBriefcase =
                  this.props.briefcase.briefcase.filter(
                    (favorite) => favorite.resource_type === 'Workshop'
                  );
                let singlePresentationBriefcase =
                  presentationsFromBriefcase.filter(
                    (item) => item.resource_id === favorite.abstract_id
                  );
                return (
                  <BriefcaseListItem
                    key={favorite.abstract_id}
                    index={index}
                    favorite={favorite}
                    propsBriefcase={singlePresentationBriefcase}
                    briefcaseAbstract={briefcaseAbstract}
                    updateState={(id) => this.updateState(id)}
                    route="workshop/"
                    type="workshop_id"
                  />
                );
              })}
          </Grid>
        ) : null}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    debug: state.debug,
    user: state.user,
    event: state.event,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Briefcase));
