import { REMOVE_SNACKBAR } from './types';

export default function removeSnackbar(id) {
  return (dispatch) => {
    dispatch(removeSnackbarAsync(id));
  };
}

function removeSnackbarAsync(id) {
  return {
    type: REMOVE_SNACKBAR,
    payload: id,
  };
}
