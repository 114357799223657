import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = (theme) => ({
  searchBox: {
    width: '20vw',
    backgroundColor: 'white',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    '& .MuiFormLabel-root': {
      fontSize: '0.85rem',
      paddingLeft: theme.spacing(1),
    },
  },
});

class GlobalSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      searchError: false,
      searchErrorMessage: '',
      searchResults: {
        options: [],
        getOptionLabel: (option) => option.title,
      },
    };
  }

  handleSearchTypeAhead = async (text) => {
    try {
      this.setState({
        searchQuery: text,
      });

      if (text.length > 0) {
        const currentUser = await Auth.currentSession();

        const initialSearchResults = await axios({
          method: 'get',
          url: `search/${this.props.eventId}`,
          headers: { idtoken: currentUser.idToken.jwtToken },
          params: {
            search_value: this.state.searchQuery,
            search_columns: 'title,text,people',
          },
        });

        let options = [];

        let max;
        if (initialSearchResults.data.data.length < 10) {
          max = initialSearchResults.data.data.length;
        } else {
          max = 10;
        }

        for (let i = 0; i < max; i++) {
          let result = initialSearchResults.data.data[i];
          if (result.title && result.type !== '') {
            if (result.type === 'External' || result.type === 'Internal') {
              result.type = 'Exhibit';
            }
          }

          if (
            result?.people
              ?.toLowerCase()
              .includes(this.state.searchQuery.toLowerCase())
          ) {
            result.title = (
              <Typography variant="subtitle2">
                <b>{result.people}</b>
                <br />
                {result.title}
              </Typography>
            );
            // result.title = `Person: ${result.people} - `.concat(result.title);
          }

          options.push(result);
        }

        //   let options = initialSearchResults.data.data.filter((result) => {
        //       if(result.title && result.type !== ""){
        //           if(result.type === 'External' || result.type === 'Internal'){
        //             result.type = 'Exhibit'
        //           }
        //           return result
        //       } else {
        //           return null
        //       }
        //   })

        options = options.sort((a, b) => {
          if (a.type < b.type) {
            return -1;
          } else {
            return 1;
          }
        });

        this.setState({
          searchResults: {
            options: options,
            getOptionLabel: (option) => option.title,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  selectGlobalSearch(value) {
    if (value.value) {
      switch (value.value.type) {
        case 'Networking':
          this.props.history.push(`/discussionforum/`);
          window.location.reload();
          break;
        case 'Poster':
          this.props.history.push(`/posterhall/?poster_id=${value.value.id}`);
          window.location.reload();
          break;
        case 'External':
          this.props.history.push(
            `/exhibithall/?exhibition_id=${value.value.id}`
          );
          window.location.reload();
          break;
        case 'Internal':
          this.props.history.push(
            `/exhibithall/?exhibition_id=${value.value.id}`
          );
          window.location.reload();
          break;
        case 'Exhibit':
          this.props.history.push(
            `/exhibithall/?exhibition_id=${value.value.id}`
          );
          window.location.reload();
          break;
        case 'Talk':
          this.props.history.push(`/agenda/?presentation_id=${value.value.id}`);
          window.location.reload();
          break;
        case 'Workshop':
          this.props.history.push(`/workshop/?workshop_id=${value.value.id}`);
          window.location.reload();
          break;
        case 'Track Talk':
          this.props.history.push(`/agenda/?presentation_id=${value.value.id}`);
          window.location.reload();
          break;
        case 'Plenary Poster':
          this.props.history.push(`/posterhall/?poster_id=${value.value.id}`);
          window.location.reload();
          break;
        default:
          this.props.history.push('/');
      }
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Autocomplete
        filterOptions={(x) => x}
        groupBy={(option) => option.type}
        // getOptionLabel={(option) => option.title}
        {...this.state.searchResults}
        onChange={(event, value) => this.selectGlobalSearch({ value })}
        renderInput={(params) => (
          <TextField
            className={classes.searchBox}
            onChange={(event) => this.handleSearchTypeAhead(event.target.value)}
            {...params}
            label="Global Search"
            fullWidth
          />
        )}
      />
    );
  }
}

export default withRouter(withStyles(useStyles)(GlobalSearch));
