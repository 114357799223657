import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import ImageMapper from 'react-image-mapper';
import { connect } from 'react-redux';
import AuditoriumImage from '../../Assets/OPRS-Atlantic_v2.jpg';
import Dialog from '../../Components/DialogWrapper';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import trackAction from '../../Utilities/tracker';
import moment from 'moment';

// table components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

// config
import { drawerWidth, eventId } from '../../config';

const useStyles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  imageMapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  gridContainer: {
    margin: '0px -24px -24px -24px',
    [theme.breakpoints.down('xs')]: {
      margin: '0px',
    },
  },
  table: {
    // minWidth: 650,
  },
  header: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    position: 'fixed',
    zIndex: '2',
    paddingRight: theme.spacing(5),
  },
  title: {
    marginTop: theme.spacing(-1),
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    transform: 'translate(-68%, 0)',
    marginLeft: '50%',
    position: 'fixed',
    zIndex: '2',
    backgroundColor: '#efefef',
    borderRadius: '8px',
  },
  titleMobile: {
    marginTop: theme.spacing(1),
    marginLeft: '30%',
  },
  backButton: {
    position: 'relative',
    padding: theme.spacing(1),
  },
  preSelectedRow: {
    background: '#6f1a1d33',
  },
  mobileButtonsText: {
    padding: `${theme.spacing(4)}px 0px`,
  },
  mobileButtons: {
    width: '100%',
    height: '100%',
  },
  mobileGridForButton: {
    margin: `${theme.spacing(4)}px 0px 0px`,
  },
  mobileLineItemContainer: {
    backgroundColor: '#EFEFEF',
    borderRadius: '4px',
    marginBottom: theme.spacing(2),
    border: `solid ${theme.palette.primary.main}`,
  },
  mobileGridSpacing: {
    padding: theme.spacing(0.5),
    // marginBottom: theme.spacing(1),
  },
});

class Auditorium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webinars: [],
      imageWidth: window.innerWidth * 0.955,
      modalOpen: false,
      upcomingWebinar: false,
      archivedWebinar: false,
      roomName: null,
      preSelectedPresentation: null,
    };
  }

  MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'ArchivedWebinars',
        shape: 'poly',
        coords: [
          1300,
          686, //bottom left
          1300,
          514, //top left
          1015,
          490, //top right
          1015,
          690, //bottom right
        ],
        strokeColor: '',
        lineWidth: '4',
        href: '/auditorium',
      },
      {
        name: 'UpcomingWebinars',
        shape: 'poly',
        coords: [
          630,
          690, //bottom right
          630,
          466, //top right
          115,
          425, //top left
          115,
          694, //bottom left
        ],
        strokeColor: '',
        lineWidth: '6',
        href: '/auditorium',
      },
    ],
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateCoordinates);

    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/agenda/${eventId}`,
          headers: { idtoken: data.idToken.jwtToken },
          params: {
            order_by: 'start_time',
            order_dir: 'asc',
            filter_column: 'room',
            filter_value: this.props.roomName,
          },
        })
          .then(
            (response) => {
              if (this.props.location.search) {
                const query = new URLSearchParams(this.props.location.search);
                for (let param of query.entries()) {
                  if (param[0] === 'presentation_id') {
                    this.setState({
                      preSelectedPresentation: param[1],
                    });
                  }
                }
              }

              let authorizedPresentations = [];

              for (let x = 0; x < response.data.data.length; x++) {
                let presentation = response.data.data[x];
                presentation.date = moment(presentation.start_time).format(
                  'YYYY-MM-DD'
                );

                if (
                  presentation.start_time &&
                  presentation.accepted_type !== 'Workshop'
                ) {
                  authorizedPresentations.push(presentation);
                }
              }

              // Sort by month, sort by day, sort by time
              authorizedPresentations = authorizedPresentations
                .sort(
                  (a, b) =>
                    moment(a.date).format('MM') - moment(b.date).format('MM')
                ) // by month
                .sort(
                  (a, b) =>
                    moment(a.date).format('DD') - moment(b.date).format('DD')
                ) // by day
                .sort((a, b) => {
                  if (a.date === b.date) {
                    return (
                      moment.duration(a.start_time).asMinutes() -
                      moment.duration(b.start_time).asMinutes()
                    );
                  } else {
                    return 1;
                  }
                }); // by time
              for (let presentation of authorizedPresentations) {
                if (
                  presentation.abstract_id ===
                  this.state.preSelectedPresentation
                ) {
                  let date = presentation.date.split('T')[0];
                  let time = presentation.end_time;
                  time = moment(time).format('HH:mm:ss');
                  let timeNow = moment().format('HH:mm:ss');
                  let dateNow = moment().format('YYYY-MM-DD');

                  switch (true) {
                    case date < dateNow:
                      // date is in the past
                      this.setState({
                        modalOpen: true,
                        upcomingWebinar: false,
                        archivedWebinar: true,
                      });
                      break;
                    case date === dateNow:
                      // date is today
                      if (time < timeNow) {
                        // time is in the past
                        this.setState({
                          modalOpen: true,
                          upcomingWebinar: false,
                          archivedWebinar: true,
                        });
                      } else {
                        // time is in the future
                        this.setState({
                          modalOpen: true,
                          upcomingWebinar: true,
                          archivedWebinar: false,
                        });
                      }
                      break;
                    default:
                      // time is in the future
                      this.setState({
                        modalOpen: true,
                        upcomingWebinar: true,
                        archivedWebinar: false,
                      });
                      break;
                  }
                }
              }
              // Authorization

              if (
                !this.props.user.user.roles.includes('Pharma Attendee') &&
                !this.props.user.user.roles.includes('Attendee')
              ) {
                authorizedPresentations = authorizedPresentations.filter(
                  (presentation) =>
                    presentation.account_id ===
                      this.props.user.user.account_id ||
                    presentation.cleared_for_public_view === 'Yes - Approved'
                );
              }

              this.setState({
                webinars: authorizedPresentations,
              });
            },
            (error) => {
              this.setState({
                webinars: [],
              });
              if (this.props.debug.debug) {
                alert(`Error: Could not display Auditorium. ${error}`);
              }
            }
          )
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Auditorium. ${error}`);
            }
          });
      })
      .catch((error) => {
        this.setState({
          webinars: [],
        });
        if (this.props.debug.debug) {
          alert(`Error: Could not display Auditorium. ${error}`);
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[1] !== prevState.preSelectedPresentation) {
          if (param[0] === 'presentation_id') {
            this.setState({
              preSelectedPresentation: param[1],
            });
          }
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCoordinates);
  }

  updateCoordinates = () => {
    let width = window.innerWidth * 0.955;

    this.setState({
      imageWidth: width,
    });
  };

  imageClick(event) {
    if (event.name === 'UpcomingWebinars') {
      this.setState({
        modalOpen: true,
        upcomingWebinar: true,
      });
    } else {
      this.setState({
        modalOpen: true,
        archivedWebinar: true,
      });
    }
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      upcomingWebinar: false,
      archivedWebinar: false,
      preSelectedPresentation: null,
    });
  }

  viewPresentation(webinar) {
    const event = {
      action: 'Clicked',
      resource_type: 'Presentation',
      resource_id: webinar.abstract_id,
      url_visited: '/auditorium',
      // user_account_id: this.props.user.user.account_id,
      // new fields
      resource_account_id: webinar.account_id,
      resource_account_name: webinar.account_name,
      resource_name: webinar.title,
      //old fields
      // user_account_name: this.props.user.user.account_name,
      // user_email: this.props.user.user.email,
      // user_first_name: this.props.user.user.first_name,
      // user_last_name: this.props.user.user.last_name,
      // user_title: this.props.user.user.title,
      // user_department: this.props.user.user.department,
      // user_site: this.props.user.user.site
    };

    trackAction(event);

    if (webinar.presentation_link) {
      window.open(webinar.presentation_link, '_blank');
    }
  }

  viewArchive(webinar) {
    const event = {
      action: 'Clicked',
      resource_type: 'Archive',
      resource_id: webinar.abstract_id,
      url_visited: '/auditorium',
      // user_account_id: this.props.user.user.account_id,
      // new fields
      resource_account_id: webinar.account_id,
      resource_account_name: webinar.account_name,
      resource_name: webinar.title,
      //old fields
      // user_account_name: this.props.user.user.account_name,
      // user_email: this.props.user.user.email,
      // user_first_name: this.props.user.user.first_name,
      // user_last_name: this.props.user.user.last_name,
      // user_title: this.props.user.user.title,
      // user_department: this.props.user.user.department,
      // user_site: this.props.user.user.site
    };

    trackAction(event);

    if (webinar.archive_link) {
      window.open(webinar.archive_link, '_blank');
    }
  }

  render() {
    const { classes } = this.props;

    let loadingGraphic = (
      <Grid container align="center">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <br />
          <br />
          <CircularProgress color="inherit" />
          <Typography variant="h4" color="textPrimary">
            Loading...
          </Typography>
        </Grid>
      </Grid>
    );

    let pageWidth = !this.props.sidebar.open
      ? this.state.imageWidth
      : window.innerWidth - drawerWidth;

    let webinars;
    if (this.state.modalOpen) {
      if (this.state.archivedWebinar) {
        webinars = (
          <React.Fragment>
            <Typography variant="h4" gutterBottom align="center">
              Recorded{' '}
              <Hidden smUp>
                <br />
              </Hidden>
              Presentations
            </Typography>
            {/* THIS IS THE DESTKOP SIZE CONTENT */}
            <Hidden xsDown>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Title</TableCell>
                      <TableCell align="center">Time</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Link</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.webinars
                      .filter((webinar) => webinar.room === this.props.roomName)
                      .map((webinar, index) => {
                        let date = webinar.date.split('T')[0];
                        let time = webinar.end_time;
                        time = moment(time).format('HH:mm:ss');
                        let timeNow = moment().format('HH:mm:ss');
                        let dateNow = moment().format('YYYY-MM-DD');

                        if (webinar.contacts) {
                          webinar.presenters = webinar.contacts
                            .filter(
                              (contact) =>
                                contact.roles.includes('presenter') ||
                                contact.roles.includes('Presenter')
                            )
                            .sort((a, b) => {
                              if (a.order === null) {
                                a.order = 1000;
                              }

                              if (b.order === null) {
                                b.order = 1000;
                              }

                              return a.order - b.order;
                            });
                        } else {
                          webinar.presenters = [];
                        }
                        switch (true) {
                          case date > dateNow:
                            return null;
                          case date === dateNow && timeNow <= time:
                            return null;
                          default:
                            return (
                              <React.Fragment key={index}>
                                <TableRow
                                  className={
                                    this.state.preSelectedPresentation ===
                                    webinar.abstract_id
                                      ? classes.preSelectedRow
                                      : ''
                                  }
                                >
                                  <TableCell
                                    align="left"
                                    style={{ maxWidth: '40vw' }}
                                  >
                                    <b>Title: </b>
                                    {webinar.title}
                                    <br />
                                    {webinar.presenters[0] ? (
                                      <React.Fragment>
                                        <b>Presenter: </b>
                                        {webinar.presenters[0].first_name +
                                          ' ' +
                                          webinar.presenters[0].last_name +
                                          ' - ' +
                                          webinar.presenters[0].title}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                    <br />
                                    {webinar.account_name ? (
                                      <React.Fragment>
                                        <b>Company: </b>
                                        {webinar.account_name}{' '}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                    <br />
                                    {webinar.cleared_for_public_view ===
                                    'Yes - Approved' ? (
                                      <React.Fragment>
                                        <b>Visibility: </b>Public
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <b>Visibility: </b>Private
                                      </React.Fragment>
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {webinar.start_time ? (
                                      moment(webinar.start_time).format(
                                        'h:mm a'
                                      )
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {webinar.date ? (
                                      moment(webinar.date).format(
                                        'dddd, MMMM Do'
                                      )
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {webinar.archive_link ? (
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        target="#blank"
                                        onClick={() =>
                                          this.viewArchive(webinar)
                                        }
                                      >
                                        View Replay
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled
                                      >
                                        Not Available
                                      </Button>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                        }
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {this.state.webinars.length <= 0 ? loadingGraphic : null}
            </Hidden>
            {/* THIS IS THE MOBILE CONTENT */}
            <Hidden smUp>
              {this.state.webinars.length <= 0 ? loadingGraphic : null}
              {this.state.webinars
                .filter((webinar) => webinar.room === this.props.roomName)
                .map((webinar, index) => {
                  let date = webinar.date.split('T')[0];
                  let time = webinar.end_time;
                  time = moment(time).format('HH:mm:ss');
                  let timeNow = moment().format('HH:mm:ss');
                  let dateNow = moment().format('YYYY-MM-DD');

                  if (webinar.contacts) {
                    webinar.presenters = webinar.contacts
                      .filter(
                        (contact) =>
                          contact.roles.includes('presenter') ||
                          contact.roles.includes('Presenter')
                      )
                      .sort((a, b) => {
                        if (a.order === null) {
                          a.order = 1000;
                        }

                        if (b.order === null) {
                          b.order = 1000;
                        }

                        return a.order - b.order;
                      });
                  } else {
                    webinar.presenters = [];
                  }

                  switch (true) {
                    case date > dateNow:
                      return null;
                    case date === dateNow && timeNow <= time:
                      return null;
                    default:
                      return (
                        <Grid
                          container
                          spacing={1}
                          justify="flex-start"
                          alignItems="stretch"
                          className={classes.mobileLineItemContainer}
                          key={index}
                        >
                          <Grid item container xs={8}>
                            <Grid
                              item
                              xs={12}
                              className={classes.mobileGridSpacing}
                            >
                              <Typography variant="body2">
                                <b>Title: </b>
                                {webinar.title}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes.mobileGridSpacing}
                            >
                              <Typography variant="body2">
                                {webinar.presenters[0] ? (
                                  <React.Fragment>
                                    <b>Presenter: </b>
                                    {webinar.presenters[0].first_name +
                                      ' ' +
                                      webinar.presenters[0].last_name +
                                      ' - ' +
                                      webinar.presenters[0].title}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                <br />
                                {webinar.account_name ? (
                                  <React.Fragment>
                                    <b>Company: </b>
                                    {webinar.account_name}{' '}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                <br />
                                {webinar.cleared_for_public_view ===
                                'Yes - Approved' ? (
                                  <React.Fragment>
                                    <b>Visibility: </b>Public
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <b>Visibility: </b>Private
                                  </React.Fragment>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={4} justify="space-between">
                            <Grid
                              item
                              xs={12}
                              className={classes.mobileGridSpacing}
                              style={{ textAlign: 'end' }}
                            >
                              <Typography variant="body2">
                                {webinar.start_time ? (
                                  moment(webinar.start_time).format('h:mm a')
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                <br />
                                <br />
                                {webinar.date ? (
                                  moment(webinar.date).format('dddd, MMMM Do')
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes.mobileGridSpacing}
                              style={{ alignSelf: 'flex-end' }}
                            >
                              {webinar.archive_link ? (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  target="#blank"
                                  onClick={() => this.viewArchive(webinar)}
                                >
                                  <Typography variant="body2">
                                    View Replay
                                  </Typography>
                                </Button>
                              ) : (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  disabled
                                >
                                  Coming Soon
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                  }
                })}
            </Hidden>
          </React.Fragment>
        );
      } else {
        webinars = (
          <React.Fragment>
            <Typography variant="h4" gutterBottom align="center">
              Upcoming{' '}
              <Hidden smUp>
                <br />
              </Hidden>
              Presentations
            </Typography>
            <Hidden xsDown>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Title</TableCell>
                      <TableCell align="center">Time</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Link</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.webinars
                      .filter((webinar) => webinar.room === this.props.roomName)
                      .map((webinar, index) => {
                        let date = webinar.date.split('T')[0];
                        let time = webinar.end_time;
                        time = moment(time).format('HH:mm:ss');
                        let timeNow = moment().format('HH:mm:ss');
                        let dateNow = moment().format('YYYY-MM-DD');

                        if (webinar.contacts) {
                          webinar.presenters = webinar.contacts
                            .filter(
                              (contact) =>
                                contact.roles.includes('presenter') ||
                                contact.roles.includes('Presenter')
                            )
                            .sort((a, b) => {
                              if (a.order === null) {
                                a.order = 1000;
                              }

                              if (b.order === null) {
                                b.order = 1000;
                              }

                              return a.order - b.order;
                            });
                        } else {
                          webinar.presenters = [];
                        }

                        switch (true) {
                          case date < dateNow:
                            return null;
                          case date === dateNow && timeNow >= time:
                            return null;
                          default:
                            return (
                              <React.Fragment key={index}>
                                <TableRow
                                  className={
                                    this.state.preSelectedPresentation ===
                                    webinar.abstract_id
                                      ? classes.preSelectedRow
                                      : ''
                                  }
                                >
                                  <TableCell
                                    align="left"
                                    style={{ maxWidth: '40vw' }}
                                  >
                                    <b>Title: </b>
                                    {webinar.title}
                                    <br />
                                    {webinar.presenters[0] ? (
                                      <React.Fragment>
                                        <b>Presenter: </b>
                                        {webinar.presenters[0].first_name +
                                          ' ' +
                                          webinar.presenters[0].last_name +
                                          ' - ' +
                                          webinar.presenters[0].title}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                    <br />
                                    {webinar.account_name ? (
                                      <React.Fragment>
                                        <b>Company: </b>
                                        {webinar.account_name}{' '}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                    <br />
                                    {webinar.cleared_for_public_view ===
                                    'Yes - Approved' ? (
                                      <React.Fragment>
                                        <b>Visibility: </b>Public
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <b>Visibility: </b>Private
                                      </React.Fragment>
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {webinar.start_time ? (
                                      moment(webinar.start_time).format(
                                        'h:mm a'
                                      )
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {webinar.date ? (
                                      moment(webinar.date).format(
                                        'dddd, MMMM Do'
                                      )
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                  </TableCell>

                                  <TableCell align="center">
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      target="#blank"
                                      onClick={() =>
                                        this.viewPresentation(webinar)
                                      }
                                    >
                                      Launch
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                        }
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {this.state.webinars.length <= 0 ? loadingGraphic : null}
            </Hidden>
            {/* THIS IS THE MOBILE CONTENT */}
            <Hidden smUp>
              {this.state.webinars.length <= 0 ? loadingGraphic : null}
              {this.state.webinars
                .filter((webinar) => webinar.room === this.props.roomName)
                .map((webinar, index) => {
                  let date = webinar.date.split('T')[0];
                  let time = webinar.end_time;
                  time = moment(time).format('HH:mm:ss');
                  let timeNow = moment().format('HH:mm:ss');
                  let dateNow = moment().format('YYYY-MM-DD');

                  if (webinar.contacts) {
                    webinar.presenters = webinar.contacts
                      .filter(
                        (contact) =>
                          contact.roles.includes('presenter') ||
                          contact.roles.includes('Presenter')
                      )
                      .sort((a, b) => {
                        if (a.order === null) {
                          a.order = 1000;
                        }

                        if (b.order === null) {
                          b.order = 1000;
                        }

                        return a.order - b.order;
                      });
                  } else {
                    webinar.presenters = [];
                  }

                  switch (true) {
                    case date < dateNow:
                      return null;
                    case date === dateNow && timeNow >= time:
                      return null;
                    default:
                      return (
                        <Grid
                          container
                          spacing={1}
                          justify="flex-start"
                          alignItems="stretch"
                          className={classes.mobileLineItemContainer}
                          key={index}
                        >
                          <Grid item container xs={8}>
                            <Grid
                              item
                              xs={12}
                              className={classes.mobileGridSpacing}
                            >
                              <Typography variant="body2">
                                <b>Title: </b>
                                {webinar.title}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes.mobileGridSpacing}
                            >
                              <Typography variant="body2">
                                {webinar.presenters[0] ? (
                                  <React.Fragment>
                                    <b>Presenter: </b>
                                    {webinar.presenters[0].first_name +
                                      ' ' +
                                      webinar.presenters[0].last_name +
                                      ' - ' +
                                      webinar.presenters[0].title}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                <br />
                                {webinar.account_name ? (
                                  <React.Fragment>
                                    <b>Company: </b>
                                    {webinar.account_name}{' '}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                <br />
                                {webinar.cleared_for_public_view ===
                                'Yes - Approved' ? (
                                  <React.Fragment>
                                    <b>Visibility: </b>Public
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <b>Visibility: </b>Private
                                  </React.Fragment>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={4} justify="space-between">
                            <Grid
                              item
                              xs={12}
                              className={classes.mobileGridSpacing}
                              style={{ textAlign: 'end' }}
                            >
                              <Typography variant="body2">
                                {webinar.start_time ? (
                                  moment(webinar.start_time).format('h:mm a')
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                <br />
                                <br />
                                {webinar.date ? (
                                  moment(webinar.date).format('dddd, MMMM Do')
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes.mobileGridSpacing}
                              style={{ alignSelf: 'flex-end' }}
                            >
                              {webinar.archive_link ? (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  target="#blank"
                                  onClick={() => this.viewPresentation(webinar)}
                                >
                                  <Typography variant="body2">
                                    Launch
                                  </Typography>
                                </Button>
                              ) : (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  disabled
                                >
                                  Coming Soon
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                  }
                })}
            </Hidden>
          </React.Fragment>
        );
      }
    }

    let auditoriumHeader = (
      // <React.Fragment>
      <Grid item container xs={12}>
        <Grid item xs={2} align="left" className={classes.header}>
          <Button
            variant="contained"
            color="default"
            onClick={() => this.props.closeAuditorium()}
            className={classes.backButton}
          >
            <ChevronLeftIcon /> Back
          </Button>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={8} className={classes.title}>
            <Typography variant="h4">{this.props.roomName}</Typography>
          </Grid>
          <Grid item xs={2} />
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12} className={classes.titleMobile}>
            <Typography variant="body1">{this.props.roomName}</Typography>
          </Grid>
        </Hidden>
      </Grid>
      // </React.Fragment>
    );
    return (
      <Grid
        container
        className={classes.gridContainer}
        align="center"
        justify="center"
      >
        {auditoriumHeader}
        <Grid item className={classes.imageMapper} xs={12}>
          <Dialog
            open={this.state.modalOpen}
            handleClose={this.handleClose.bind(this)}
            scroll="body"
          >
            {webinars}
          </Dialog>
          <Hidden xsDown>
            <ImageMapper
              width={pageWidth}
              imgWidth={2000}
              src={AuditoriumImage}
              map={this.MAP}
              onClick={(event) => this.imageClick(event)}
            />
          </Hidden>
        </Grid>
        <Hidden smUp>
          <Grid item xs={12} className={classes.mobileGridForButton}>
            <Button
              variant="contained"
              color="primary"
              className={classes.mobileButtons}
              onClick={() => this.imageClick({ name: 'UpcomingWebinars' })}
            >
              <Typography
                variant="h6"
                align="justify"
                className={classes.mobileButtonsText}
              >
                Upcoming
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.mobileGridForButton}>
            <Button
              variant="contained"
              color="primary"
              className={classes.mobileButtons}
              onClick={() => this.imageClick({ name: 'Recordings' })}
            >
              <Typography
                variant="h6"
                align="justify"
                className={classes.mobileButtonsText}
              >
                Recordings
              </Typography>
            </Button>
          </Grid>
        </Hidden>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    sidebar: state.sidebar,
    user: state.user,
    debug: state.debug,
  };
}

export default connect(
  mapStateToProps,
  null
)(withStyles(useStyles)(withRouter(Auditorium)));
