import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import trackAction from '../../Utilities/tracker';
import GroupConversation from '../../Components/GroupConversation';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpIconOutlined from '@material-ui/icons/ThumbUpOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { eventId } from '../../config';
import getTwilioGroupConversationUniqueName from '../../Utilities/getTwilioGroupConversationUniqueName';
// import GroupIcon from '@material-ui/icons/Group';
// import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { fallbackImages } from '../../config';
import ChatProfile from '../../Components/ChatProfile';
import getTwilioUserIdentity from '../../Utilities/getTwilioUserIdentity';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';
import twilioCreateJoinOneOnOneConversation from '../../Store/Actions/twilioCreateJoinOneOnOneConversation';

const useStyles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  underTabsContainer: {
    // padding: theme.spacing(2),
    textAlign: 'center',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
    textDecoration: 'none',
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  linePhoto: {
    borderRadius: '50%',
    width: '5rem',
    height: '5rem',
    [theme.breakpoints.down('xs')]: {
      width: '3rem',
      height: '3rem',
    },
  },
  avatarPhoto: {
    width: '4rem',
    height: '4rem',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    borderRadius: '50%',
  },
  lineTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingBottom: theme.spacing(1),
  },
  lineAbstract: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  lineSpeakerName: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  lineSpeakerInfo: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  tabSingle: {
    minWidth: 'auto',
  },
});

class PosterFull extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 2,
      poster: null,
      currentPosterTopicList: null,
      relatedPosters: null,
      upvote: false,
      groupInterested: false,
    };
  }

  topOfModal = React.createRef();

  scrollToTop = () => {
    if (this.topOfModal.current) {
      this.topOfModal.current.scrollIntoView();
    }
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  navigate(poster) {
    this.getPoster(poster.abstract_id);
  }

  recordEvent = (data) => {
    const event = {
      action: 'Clicked',
      resource_type: 'Poster',
      resource_id: data.abstract_id,
      url_visited: '/posterhall',
      resource_account_id: data.account_id,
      resource_account_name: data.name,
      resource_name: data.title,
    };

    trackAction(event);
  };

  meetMeClick() {
    if (
      this.state.poster.presenters.find(
        (e) => this.props.user.user.email === e.email
      )
    ) {
      const event = {
        action: 'Clicked',
        resource_type: 'Meet Me / Poster / Host',
        resource_id: this.state.poster.abstract_id,
        url_visited: '/posterhall',
        resource_account_id: this.state.poster.account_id,
        resource_account_name: this.state.poster.name,
        resource_name: this.state.poster.title,
      };

      trackAction(event);
    } else {
      const event = {
        action: 'Clicked',
        resource_type: 'Meet Me / Poster / Guest',
        resource_id: this.state.poster.abstract_id,
        url_visited: '/posterhall',
        resource_account_id: this.state.poster.account_id,
        resource_account_name: this.state.poster.name,
        resource_name: this.state.poster.title,
      };

      trackAction(event);
    }

    if (
      this.state.poster.presenters.find(
        (e) => this.props.user.user.email === e.email
      ) &&
      this.state.poster.presentation_host_link
    ) {
      window.open(this.state.poster.presentation_host_link, '_blank');
    } else {
      window.open(this.state.poster.presentation_link, '_blank');
    }
  }

  posterPdfClick() {
    const event = {
      action: 'Clicked',
      resource_type: 'Poster Pdf / Poster',
      resource_id: this.state.poster.abstract_id,
      url_visited: '/posterhall',
      resource_account_id: this.state.poster.account_id,
      resource_account_name: this.state.poster.name,
      resource_name: this.state.poster.title,
    };

    trackAction(event);

    window.open(this.state.poster.presentation_pdf, '_blank');
  }

  introVideoClick() {
    const event = {
      action: 'Clicked',
      resource_type: 'Intro Video / Poster',
      resource_id: this.state.poster.abstract_id,
      url_visited: '/posterhall',
      resource_account_id: this.state.poster.account_id,
      resource_account_name: this.state.poster.name,
      resource_name: this.state.poster.title,
    };

    trackAction(event);

    window.open(this.state.poster.presentation_video, '_blank');
  }

  topicStringToArray = (poster) => {
    const topics = [];
    let topicsSplit = poster.topic.split(';');
    topicsSplit.forEach((topic) => {
      topic = topic.trim();
      topics.push(topic);
    });
    return topics;
  };

  comparePosters = (
    posters,
    currentPosterId,
    currentTopicList,
    masterTopicListLength
  ) => {
    const relatedPosters = [];
    let pointSystem = {
      match: masterTopicListLength,
      miss: 2,
      extra: 3,
    };
    posters.forEach((poster) => {
      if (poster.abstract_id === currentPosterId) {
        // do nothing
      } else {
        let potentialTopicList = this.topicStringToArray(poster);

        let overlap = currentTopicList.filter((topic) =>
          potentialTopicList.includes(topic)
        );
        if (overlap.length > 0) {
          let masterLookup = {};

          for (let j in potentialTopicList) {
            masterLookup[potentialTopicList[j]] = potentialTopicList[j];
          }
          let matches = 0;
          let matchPoints = 0;
          let missPoints = 0;
          let extraPoints = 0;

          for (let i in currentTopicList) {
            if (typeof masterLookup[currentTopicList[i]] != 'undefined') {
              matchPoints =
                matchPoints + currentTopicList.length * pointSystem.match - i;
              matches = matches + 1;
            }
          }
          extraPoints = potentialTopicList.length - matches;
          missPoints = masterTopicListLength - extraPoints - matches;

          let relatedValue =
            matchPoints -
            missPoints * pointSystem.miss -
            extraPoints * pointSystem.extra;
          poster.relatedValue = relatedValue;

          relatedPosters.push(poster);
        }
      }
      relatedPosters.sort(function (a, b) {
        return b.relatedValue - a.relatedValue;
      });
    });
    return relatedPosters;
  };

  async componentDidMount() {
    let posterId = this.props.posterId;

    if (this.props.location) {
      if (this.props.location.search) {
        const query = new URLSearchParams(this.props.location.search);
        for (let param of query.entries()) {
          if (param[0] === 'poster_id') {
            this.setState({
              posterSelected: true,
              posterId: param[1],
            });

            posterId = param[1];
          }
        }
      }
    }

    try {
      const user = await Auth.currentSession();

      // Add user to the room using twilio sync

      const poster = await axios({
        method: 'get',
        url: `/group/${eventId}/${posterId}`,
        headers: {
          idtoken: user.idToken.jwtToken,
        },
      });

      const currentTopicList = this.topicStringToArray(poster.data.data[0]);

      let masterTopicListDirty = [];

      this.props.allPosters.forEach((poster) => {
        let posterTopics = this.topicStringToArray(poster);
        masterTopicListDirty = [...masterTopicListDirty, ...posterTopics];
      });

      let masterTopicList = [...new Set(masterTopicListDirty)];

      const relatedPosters = await this.comparePosters(
        this.props.allPosters,
        poster.data.data[0].abstract_id,
        currentTopicList,
        masterTopicList.length
      );
      if (relatedPosters.length > 5) {
        relatedPosters.length = 5;
      }

      if (poster.data.data[0].contacts) {
        poster.data.data[0].presenters = poster.data.data[0].contacts
          .filter(
            (contact) =>
              contact.roles.includes('presenter') ||
              contact.roles.includes('Presenter')
          )
          .sort((a, b) => {
            if (a.order === null) {
              a.order = 1000;
            }

            if (b.order === null) {
              b.order = 1000;
            }

            return a.order - b.order;
          });

        poster.data.data[0].authors = poster.data.data[0].contacts
          .filter(
            (contact) =>
              contact.roles.includes('Author') ||
              contact.roles.includes('author')
          )
          .sort((a, b) => {
            if (a.order === null) {
              a.order = 1000;
            }

            if (b.order === null) {
              b.order = 1000;
            }

            return a.order - b.order;
          });

        poster.data.data[0].submitters = poster.data.data[0].contacts
          .filter(
            (contact) =>
              contact.roles.includes('submitter') ||
              contact.roles.includes('Submitter')
          )
          .sort((a, b) => {
            if (a.order === null) {
              a.order = 1000;
            }

            if (b.order === null) {
              b.order = 1000;
            }

            return a.order - b.order;
          });
      } else {
        poster.data.data[0].presenters = [];
        poster.data.data[0].authors = [];
        poster.data.data[0].submitters = [];
      }

      let firstPresenter = null;
      if (poster.data.data[0].presenters[0]) {
        firstPresenter = {
          first_name: poster.data.data[0].presenters[0].first_name,
          last_name: poster.data.data[0].presenters[0].last_name,
          account_name: poster.data.data[0].presenters[0].account_name
            ? poster.data.data[0].presenters[0].account_name
            : poster.data.data[0].presenters[0].department,
        };
      }

      this.setState({
        poster: poster.data.data[0],
        currentPosterTopicList: currentTopicList,
        relatedPosters: relatedPosters,
        firstPresenter: firstPresenter,
      });

      this.recordEvent(poster.data.data[0]);

      if (poster.data.data[0].liked) {
        this.setState({ upvote: true });
      } else {
        this.setState({ upvote: false });
      }

      if (poster.data.data[0].groupInterested) {
        this.setState({ groupInterested: true });
      } else {
        this.setState({ groupInterested: false });
      }

      // this.getPoster(this.props.posterId)
    } catch (error) {
      console.log(error);
    }
  }

  async getPoster(potentialPosterId) {
    await this.setState({
      poster: null,
    });

    try {
      let posters = this.props.allPosters.filter(
        (a) => a.abstract_id === potentialPosterId
      );

      const currentTopicList = this.topicStringToArray(posters[0]);

      let masterTopicListDirty = [];

      this.props.allPosters.forEach((poster) => {
        let posterTopics = this.topicStringToArray(poster);
        masterTopicListDirty = [...masterTopicListDirty, ...posterTopics];
      });

      let masterTopicList = [...new Set(masterTopicListDirty)];

      const relatedPosters = await this.comparePosters(
        this.props.allPosters,
        posters[0].abstract_id,
        currentTopicList,
        masterTopicList.length
      );
      if (relatedPosters.length > 5) {
        relatedPosters.length = 5;
      }

      if (posters[0].contacts) {
        posters[0].presenters = posters[0].contacts
          .filter(
            (contact) =>
              contact.roles.includes('presenter') ||
              contact.roles.includes('Presenter')
          )
          .sort((a, b) => {
            if (a.order === null) {
              a.order = 1000;
            }

            if (b.order === null) {
              b.order = 1000;
            }

            return a.order - b.order;
          });

        posters[0].authors = posters[0].contacts
          .filter(
            (contact) =>
              contact.roles.includes('Author') ||
              contact.roles.includes('author')
          )
          .sort((a, b) => {
            if (a.order === null) {
              a.order = 1000;
            }

            if (b.order === null) {
              b.order = 1000;
            }

            return a.order - b.order;
          });

        posters[0].submitters = posters[0].contacts
          .filter(
            (contact) =>
              contact.roles.includes('submitter') ||
              contact.roles.includes('Submitter')
          )
          .sort((a, b) => {
            if (a.order === null) {
              a.order = 1000;
            }

            if (b.order === null) {
              b.order = 1000;
            }

            return a.order - b.order;
          });
      } else {
        posters[0].presenters = [];
        posters[0].authors = [];
        posters[0].submitters = [];
      }

      let firstPresenter = null;
      if (posters[0].presenters[0]) {
        firstPresenter = {
          first_name: posters[0].presenters[0].first_name,
          last_name: posters[0].presenters[0].last_name,
          account_name: posters[0].presenters[0].account_name
            ? posters[0].presenters[0].account_name
            : posters[0].presenters[0].department,
        };
      }

      this.setState({
        poster: posters[0],
        currentPosterTopicList: currentTopicList,
        relatedPosters: relatedPosters,
        firstPresenter: firstPresenter,
      });

      this.recordEvent(posters[0]);

      if (posters[0].liked) {
        this.setState({ upvote: true });
      } else {
        this.setState({ upvote: false });
      }

      if (posters[0].groupInterested) {
        this.setState({ groupInterested: true });
      } else {
        this.setState({ groupInterested: false });
      }
    } catch (error) {
      if (this.props.debug.debug) {
        alert(`Error: Could not display Poster. ${error}`);
      }
    }
    this.scrollToTop();
  }

  async upvotePoster() {
    this.setState({ tabValue: this.state.tabValue });

    try {
      const data = await Auth.currentSession();

      if (!this.state.upvote) {
        await axios({
          method: 'POST',
          url: '/likes/' + eventId,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.state.poster.abstract_id,
            resource_type: this.state.poster.accepted_type,
          },
        });
        const event = {
          action: 'Liked',
          resource_type: this.state.poster.accepted_type,
          resource_id: this.state.poster.abstract_id,
          url_visited: '/posterhall',
          resource_account_id: this.state.poster.account_id,
          resource_account_name: null,
          resource_name: this.state.poster.title,
        };

        trackAction(event);
        this.setState({ upvote: true });
        this.props.handleUpvote(this.state.poster.abstract_id);
      } else {
        await axios({
          method: 'DELETE',
          url: '/likes/' + eventId + '/' + this.state.poster.abstract_id,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.state.poster.abstract_id,
          },
        });
        this.setState({ upvote: false });
        this.props.handleUpvote(this.state.poster.abstract_id);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async groupAdd() {
    this.setState({ tabValue: this.state.tabValue });

    try {
      const data = await Auth.currentSession();

      if (!this.state.groupInterested) {
        await axios({
          method: 'POST',
          url: `/group/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.state.poster.abstract_id,
            resource_type: this.state.poster.accepted_type,
          },
        });
        this.setState({ groupInterested: true });
        this.props.handleGroupAdd();
      } else {
        await axios({
          method: 'DELETE',
          url: `/group/${eventId}/${this.state.poster.abstract_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.state.poster.abstract_id,
          },
        });
        this.setState({ groupInterested: false });
        this.props.handleGroupAdd();
      }
    } catch (error) {
      console.log(error);
    }
  }

  briefcaseSelect() {
    this.setState({ tabValue: this.state.tabValue });

    let favorited = false;
    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (
          this.props.briefcase.briefcase[n].resource_id ===
          this.state.poster.abstract_id
        ) {
          favorited = true;
          break;
        }
      }
    }

    if (favorited) {
      this.props.deleteBriefcaseElement(this.state.poster.abstract_id);
    } else {
      this.props.addBriefcaseElement(
        'Poster',
        this.state.poster.abstract_id,
        this.state.poster.title,
        this.state.poster.account_id,
        this.props.user.user.account_id,
        this.state.poster.account_name,
        this.state.firstPresenter
      );
    }
  }

  render() {
    const { classes } = this.props;
    let posterContent;

    if (!this.state.poster) {
      return <CircularProgress color="inherit" />;
    }

    if (this.state.poster) {
      switch (this.state.tabValue) {
        case 2:
          let meetMeButton = null;

          if (
            this.state.poster.presenters.find(
              (e) => this.props.user.user.email === e.email
            )
          ) {
            if (this.state.poster.presentation_host_link) {
              meetMeButton = (
                <Tooltip
                  title="Click to enter your live group video chat"
                  aria-label="add"
                >
                  <Button
                    color="inherit"
                    variant="contained"
                    className={classes.button}
                    onClick={() => this.meetMeClick()}
                  >
                    Meet Me
                  </Button>
                </Tooltip>
              );
            } else if (this.state.poster.presentation_link) {
              meetMeButton = (
                <Tooltip
                  title="Click to enter live group video chat"
                  aria-label="add"
                >
                  <Button
                    color="inherit"
                    variant="contained"
                    className={classes.button}
                    onClick={() => this.meetMeClick()}
                  >
                    Meet Me
                  </Button>
                </Tooltip>
              );
            } else {
              meetMeButton = (
                <Button
                  disabled
                  color="inherit"
                  variant="contained"
                  className={classes.button}
                >
                  Meet Me
                </Button>
              );
            }
          } else {
            if (this.state.poster.presentation_link) {
              meetMeButton = (
                <Tooltip
                  title="Click to enter live group video chat"
                  aria-label="add"
                >
                  <Button
                    color="inherit"
                    variant="contained"
                    className={classes.button}
                    onClick={() => this.meetMeClick()}
                  >
                    Meet Me
                  </Button>
                </Tooltip>
              );
            } else {
              meetMeButton = (
                <Button
                  disabled
                  color="inherit"
                  variant="contained"
                  className={classes.button}
                >
                  Meet Me
                </Button>
              );
            }
          }

          posterContent = (
            <React.Fragment>
              <Grid item xs={12}>
                <Hidden xsDown>
                  <Typography variant="h4" color="textPrimary">
                    {this.state.poster.title}
                  </Typography>
                  <br />
                  <Typography variant="h6" color="textPrimary">
                    <b>Topic: </b>
                    {this.state.poster.topic.split(';').join(', ')}
                  </Typography>
                </Hidden>
                <Hidden smUp>
                  <Typography variant="body1" color="textPrimary">
                    <b>{this.state.poster.title}</b>
                  </Typography>
                  <br />
                  <Typography variant="body2" color="textPrimary">
                    <b>Topic: </b>
                    {this.state.poster.topic.split(';').join(', ')}
                  </Typography>
                </Hidden>
                <br />
                <Divider className={classes.divider} variant="fullWidth" />
              </Grid>
              <Grid item container xs={12} style={{ height: '100%' }}>
                <Grid item xs={4}>
                  {meetMeButton}
                </Grid>
                <Grid item xs={4}>
                  {this.state.poster.presentation_pdf ? (
                    <Tooltip
                      title="View pdf version of this poster"
                      aria-label="add"
                    >
                      <Button
                        color="inherit"
                        variant="contained"
                        className={classes.button}
                        onClick={() => this.posterPdfClick()}
                      >
                        Poster PDF
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      disabled
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                    >
                      Poster PDF
                    </Button>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {this.state.poster.presentation_video ? (
                    <Tooltip
                      title="Watch a pre-recorded video about this poster"
                      aria-label="add"
                    >
                      <Button
                        color="inherit"
                        variant="contained"
                        className={classes.button}
                        onClick={() => this.introVideoClick()}
                      >
                        Intro Video
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      disabled
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                    >
                      Intro Video
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} variant="fullWidth" />
                <br />
                <Typography variant="h6" color="textPrimary" align="center">
                  <b>Abstract</b>
                </Typography>
                <pre style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    align="justify"
                  >
                    {this.state.poster.content}
                  </Typography>
                </pre>
                <Divider className={classes.divider} variant="fullWidth" />
                <Typography variant="h6" color="textPrimary" align="center">
                  <b>Related Posters</b>
                </Typography>
                {this.state.relatedPosters.map((poster, index) => {
                  return (
                    <div key={index}>
                      <Button
                        color="primary"
                        variant="outlined"
                        size="large"
                        style={{ margin: '4px 0px' }}
                        onClick={() => this.navigate(poster)}
                      >
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          align="center"
                        >
                          {poster.title}
                        </Typography>
                      </Button>
                    </div>
                  );
                })}
              </Grid>
            </React.Fragment>
          );
          break;
        case 3:
          const keyValue = (key, value) => {
            if (value) {
              return (
                <>
                  <b>{key}:</b> {value}
                </>
              );
            }
          };
          posterContent = (
            <React.Fragment>
              <Grid item xs={12}>
                {this.state.poster.presenters.length ? (
                  <React.Fragment>
                    <Typography variant="h5" color="textPrimary">
                      <b>Presenters</b>
                    </Typography>
                    <Divider className={classes.divider} variant="fullWidth" />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {this.state.poster.presenters.map((presenter, index) => {
                  return (
                    <Grid
                      container
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={async () => {
                        const me = this.props.user.user;

                        const isMe =
                          getTwilioUserIdentity(me) === presenter?.email;

                        try {
                          if (!isMe) {
                            this.props
                              .twilioCreateJoinOneOnOneConversation(
                                presenter?.email
                              )
                              .catch((error) => {
                                console.log(
                                  '🚀 ~ file: AgendaRowItem.js ~ line 622 ~ onClick={ ~ error',
                                  error
                                );
                              });
                          }
                        } catch (error) {
                          console.log('chat error: ', error.message);
                        }
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          className={classes.lineSpeakerName}
                          variant="h6"
                        >
                          {`${presenter.first_name} ${presenter.last_name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {/* {presenter.photo_url ? (
                          <img
                            className={classes.linePhoto}
                            src={presenter.photo_url}
                            alt={'Presenter'}
                          />
                        ) : null} */}
                        <div
                          alt={'Presenter'}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                          }}
                        >
                          <ChatProfile
                            identity={presenter.email}
                            size={'4rem'}
                            photo_url={presenter.photo_url}
                          />
                          {/* // </div> */}
                          {/* <img
                            className={classes.linePhoto}
                            src={this.state.currentContact.photo_url}
                            alt={'Presenter'}
                          /> */}
                        </div>
                      </Grid>
                      <Grid item xs={10} style={{ paddingLeft: '8px' }}>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Title', presenter.title)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Account', presenter.account_name)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Department', presenter.department)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Expertise', presenter.expertise_area)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Email', presenter.email)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Biography', presenter.bio)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider
                          className={classes.divider}
                          variant="fullWidth"
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '3vw' }}>
                {this.state.poster.authors.length ? (
                  <React.Fragment>
                    <Typography variant="h5" color="textPrimary">
                      <b>Authors</b>
                    </Typography>
                    <Divider className={classes.divider} variant="fullWidth" />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {this.state.poster.authors.map((author, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.lineSpeakerName}
                          variant="h6"
                        >
                          {`${author.first_name} ${author.last_name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {author.photo_url ? (
                          <div
                            className={classes.avatarPhoto}
                            style={{
                              backgroundImage: `url('${author.photo_url}')`,
                            }}
                          />
                        ) : null}
                      </Grid>
                      <Grid item xs={10} style={{ paddingLeft: '8px' }}>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Title', author.title)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Account', author.account_name)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Department', author.department)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Expertise', author.expertise_area)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Email', author.email)}
                        </Typography>
                        {/* <Typography variant='body1' className={classes.lineSpeakerInfo}>
                            {keyValue('Biography', author.bio)}
                          </Typography> */}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider
                          className={classes.divider}
                          variant="fullWidth"
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </React.Fragment>
          );
          break;
        //       case 4:

        // if(this.state.poster.group_interests){

        //       posterContent = (
        //         <React.Fragment>
        //             <Grid item xs={12}>
        //             {this.state.poster.group_interests.length ?
        //                 <React.Fragment>
        //                   <Typography variant='h5' color='textPrimary'>
        //                     <b>Attendees Interested in Adding this to Their Group</b>
        //                     </Typography>
        //                   <Divider className={classes.divider} variant="fullWidth" />
        //                   </React.Fragment>
        //                   : <React.Fragment/>}
        //               {this.state.poster.group_interests.map((attendee, index)=>{

        //                 return (
        //                   <Grid container key={index} >
        //                     <Grid item xs={12}>
        //                         <Typography className={classes.lineSpeakerInfo} >
        //                           {`Name: ${attendee.first_name} ${attendee.last_name}`}
        //                         </Typography>
        //                         <Typography className={classes.lineSpeakerInfo} >
        //                           {`Email: ${attendee.email}`}
        //                         </Typography>
        //                         <Typography className={classes.lineSpeakerInfo} >
        //                           {`Department: ${attendee.department}`}
        //                         </Typography>
        //                       </Grid>
        //                     <Grid item xs={12}>
        //                       <Divider className={classes.divider} variant="fullWidth" />
        //                     </Grid>
        //                   </Grid>
        //                   )
        //                 })}
        //             </Grid>
        //         </React.Fragment>
        //       );

        //       } else {

        //         posterContent = (
        //           <Grid item xs={12}>
        //             <Typography variant='h5' color='textPrimary'>
        //               <b>Attendees Interested in Adding this to Their Group</b>
        //               </Typography>
        //             </Grid>
        //           )
        //       }

        //       break;
        default:
          posterContent = <div>No Content</div>;
          break;
      }
    } else {
      posterContent = <div></div>;
      return <div></div>;
    }

    let favorited = false;
    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (
          this.props.briefcase.briefcase[n].resource_id ===
          this.state.poster.abstract_id
        ) {
          favorited = true;
          break;
        }
      }
    }
    let logo;
    const topicMap = JSON.parse(this.props.event.event.topic_map);
    if (this.state.poster.supporting_file_url) {
      logo = this.state.poster.supporting_file_url;
    } else if (topicMap[this.state.poster.topic]) {
      logo = topicMap[this.state.poster.topic];
    } else if (this.props.event.event.topic_map[this.state.poster.topic]) {
      logo = this.props.event.event.topic_map[this.state.poster.topic];
    } else {
      logo = fallbackImages.posterCard;
    }

    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={2}
        id="posterModalFull"
      >
        {<span ref={this.topOfModal} />}
        <Grid item xs={12} sm={2} align="left">
          <Button onClick={() => this.props.closeBooth()}>
            <ChevronLeftIcon /> Back
          </Button>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {favorited ? (
              <Tab
                className={classes.tabSingle}
                onClick={() => {
                  this.briefcaseSelect();
                }}
                icon={<WorkIcon />}
              />
            ) : (
              <Tab
                className={classes.tabSingle}
                onClick={() => {
                  this.briefcaseSelect();
                }}
                icon={<WorkOutlineIcon />}
              />
            )}
            {this.state.upvote ? (
              <Tab
                className={classes.tabSingle}
                onClick={() => {
                  this.upvotePoster();
                }}
                icon={<ThumbUpIcon />}
              />
            ) : (
              <Tab
                className={classes.tabSingle}
                onClick={() => {
                  this.upvotePoster();
                }}
                icon={<ThumbUpIconOutlined />}
              />
            )}
            {/* {this.state.groupInterested ?
              <Tooltip title={<h2>I am interested in potential uses of this technology/process in my group</h2>} aria-label="add">
                <Tab 
                  className={classes.tabSingle} 
                  onClick={() => {
                    this.groupAdd()
                  }} 
                  icon = {<GroupIcon/>}
                />
                </Tooltip>
                :
                <Tooltip title={<h2>I am interested in potential uses of this technology/process in my group</h2>} aria-label="add">
                <Tab 
                  className={classes.tabSingle} 
                  onClick={() => {
                    this.groupAdd()
                  }}
                  icon = {<GroupAddOutlinedIcon />}
              /> 
              </Tooltip>
              } */}
            <Tab className={classes.tabSingle} label={'Poster'} />
            <Tab className={classes.tabSingle} label={'Contributors'} />
            {/* {this.state.poster.presenters.find((e) => this.props.user.user.email === e.email) ? <Tab className={classes.tabSingle} label={'Interest'} /> : null} */}
          </Tabs>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={false} sm={2} align="right" />
        </Hidden>
        <Grid item xs={12} md={6}>
          <div className={classes.paper}>
            <Grid
              className={classes.underTabsContainer}
              container
              spacing={2}
              justify="center"
              alignItems="center"
            >
              {posterContent}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.paper}>
            <GroupConversation
              type="poster"
              eventId={eventId}
              abstractId={this.state.poster.abstract_id}
              friendlyName={this.state.poster.title}
              uniqueName={getTwilioGroupConversationUniqueName(
                this.state.poster
              )}
              logo={logo}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    debug: state.debug,
    briefcase: state.briefcase,
    user: state.user,
    event: state.event,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
      twilioCreateJoinOneOnOneConversation:
        twilioCreateJoinOneOnOneConversation,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(PosterFull));
