import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DialogWrapper from '../Components/DialogWrapper';
import Privacy from './Privacy';

// redux
import { connect } from 'react-redux';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  headings: {
    padding: theme.spacing(3),
  },
  inputControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    width: '100%',
    height: '100%',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  closeButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    width: '60%',
    marginLeft: '20%',
    marginRight: '20%',
    fontSize: '1rem',
  },
});

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privacyOpen: false,
    };
  }

  handleChange(value, inputIdentifier) {
    this.setState({ [inputIdentifier]: value });
  }

  render() {
    const { classes } = this.props;

    const allRoutes = JSON.parse(
      this.props.event.event.available_routes
    ).routes;
    let pageTitle = '';
    allRoutes.forEach((route) => {
      if (route.route === '/help') {
        pageTitle = route.title;
      }
    });

    return (
      <Grid
        container
        layout={'row'}
        spacing={2}
        style={{ marginBottom: '32px' }}
      >
        <Grid item xs={12}>
          <Paper>
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="h4"
              align="center"
              color="textPrimary"
            >
              {pageTitle}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h4" align="center" gutterBottom>
                Browser Recommendation
              </Typography>
              <Typography variant="body1" align="justify">
                For the best experience, visit the virtual environment using a
                Chrome browser on your desktop or laptop computer.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h4" align="center" gutterBottom>
                Chat and Code of Conduct
              </Typography>
              <Typography variant="body1" align="justify">
                Please note that the group chats are publicly visible to all
                participants and that one-on-one chats are private. A
                professional code of conduct is expected at all times both
                publicly and privately throughout the environment.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h4" align="center" gutterBottom>
                Concierge / Help Desk
              </Typography>
              <Typography variant="body1" align="justify">
                At the bottom right of your screen is a chat function that gives
                you direct access our technical support team. Live support is
                available from 9am to 5pm ET Monday through Friday and during
                all event hours.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h4" align="center" gutterBottom>
                Security / Privacy
              </Typography>
              <Typography variant="body1" align="justify">
                You will be required to log-in each time you enter the virtual
                environment. Your username and password are unique to you and
                should not be shared. Note that portions of the Event are open
                to the Event's sponsors and exhibitors. Events that are
                proprietary will be noted. We encourage you to use discretion
                regarding proprietary conversations as you would at the
                in-person event.
              </Typography>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => this.handleChange(true, 'privacyOpen')}
                style={{ marginTop: '16px' }}
              >
                View Privacy Policy
              </Button>
            </CardContent>
            <DialogWrapper
              open={this.state.privacyOpen}
              handleClose={(e) => this.setState({ privacyOpen: false })}
            >
              <Privacy />
              <Button
                className={classes.closeButton}
                variant="contained"
                onClick={(e) => this.handleChange(false, 'privacyOpen')}
              >
                Close
              </Button>
            </DialogWrapper>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    debug: state.debug,
    event: state.event,
  };
}

export default connect(mapStateToProps, null)(withStyles(useStyles)(Account));
