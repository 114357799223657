import { REMOVE_EVENT_ELEMENTS } from './types';
export default function setEventElements() {
  return (dispatch) => {
    dispatch(removeEventElementsAsync());
  };
}

function removeEventElementsAsync() {
  return {
    type: REMOVE_EVENT_ELEMENTS,
  };
}
