import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import ChatProfileRow from './ChatProfileRow';
import sortParticipantsByLastName from '../Utilities/sortParticipantsByLastName';
import { getUsers } from '../Store/Reducers/twilio';

import { colors } from '../config';

const ChatConversationHereNow = forwardRef(({ conversation }, ref) => {
  // const dispatch = useDispatch();
  // const user = useSelector((state) => state.user.user);
  // console.log('🚀 ~ file: ChatConversationHereNow.js ~ line 43 ~ user', user);
  // const userTwilio = useSelector((state) => state.twilio.user?.client);
  const users = useSelector(getUsers);

  const hereNow =
    useSelector((state) => state.twilio.herenow?.[conversation.uniqueName]) ||
    {};
  const exhibitionId = conversation?.client?.attributes?.exhibitionId;
  const abstractId = conversation?.client?.attributes?.abstractId;

  // console.log(
  //   '🚀 ~ file: ChatConversationHereNow.js ~ line 44 ~ ChatConversationHereNow ~ openConversation',
  //   openConversation
  // );

  const admins = [];
  const participants = [];

  Object.keys(hereNow).forEach((identity) => {
    if (users[identity] && users[identity].isOnline) {
      const isAdmin = conversation?.attributes?.admins?.find(
        (admin) => admin.email === identity
      );
      if (isAdmin) {
        admins.push(users[identity]);
      } else {
        participants.push(users[identity]);
      }
    }
  });
  admins.sort(sortParticipantsByLastName(users));
  participants.sort(sortParticipantsByLastName(users));

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        // height: 500,
        width: '100%',
        height: '100%',

        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <div style={styles.chatRoot}>
        {admins && (
          <div
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              color: colors.main,
            }}
          >
            {exhibitionId ? 'Booth Staff' : 'Poster Presenter'}
          </div>
        )}
        {admins &&
          admins.map((participant, index) => (
            <div key={`admins_content_${conversation.sid}_${participant.sid}`}>
              <ChatProfileRow profileUser={participant} page={undefined} />
            </div>
          ))}
        {participants && (
          <div
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              color: colors.main,
            }}
          >
            Participants
          </div>
        )}
        {participants &&
          participants.map((participant, index) => (
            <div
              key={`participants_content_${conversation.sid}_${participant.sid}`}
            >
              <ChatProfileRow profileUser={participant} page={undefined} />
            </div>
          ))}
      </div>
    </div>
  );
});

const styles = {
  chatRoot: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // height: 500,
    width: '100%',
    height: '100%',
    // border: '1px solid',
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    // backgroundColor: 'purple',
  },
  chatHeader: {
    height: 100,
    backgroundColor: 'green',
  },
  chatContainer: {
    // flex: 1,
    // overflowX: 'hidden',
    // overflowY: 'scroll',
    // // maxHeight: 430,

    // display: 'flex',
    flex: 1,
    // backgroundColor: 'blue',
    backgroundColor: colors.backgroundGray,
    overflowX: 'hidden',
    overflowY: 'auto',
    // maxHeight: '45vh',
  },
  chatFooter: {
    // height: 100,
    // backgroundColor: 'rgb(0,0,0,0)',
    backgroundColor: colors.backgroundGray,
    // border: '2px solid pink',
    paddingTop: 10,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
  },
  settings: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-evenly',
    padding: 10,
    width: '100%',
    // border: '1px solid',
    fontSize: 16,
    cursor: 'pointer',
  },
};

export default ChatConversationHereNow;
