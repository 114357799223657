import { SET_EVENT_DETAILS } from './types';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { eventId } from '../../config';

export default function setEventDetails() {
  return (dispatch, getState) => {
    Auth.currentSession().then((data) => {
      axios({
        method: 'get',
        url: '/events/details/' + eventId,
        headers: { idtoken: data.idToken.jwtToken },
      }).then((response) => {
        dispatch(setEventDetailsAsync(response.data));
      });
    });
  };
}

function setEventDetailsAsync(details) {
  return {
    type: SET_EVENT_DETAILS,
    payload: details,
  };
}
