import { getHereNow, getUsers, getHereNowClient } from '../Reducers/twilio';

export default function twilioRemoveHereNow(conversationSid) {
  return async (dispatch, getState) => {
    const user = getState().user.user;
    // const conversationsClient = getState()Client
    const hereNowClient = getHereNowClient(getState());
    const users = getUsers(getState());
    const hereNow = getHereNow(getState()) || {};

    if (user?.email && hereNowClient) {
      const newHereNow = { ...hereNow };
      const newHereNowRoom = newHereNow[conversationSid]
        ? { ...newHereNow[conversationSid] }
        : {};

      // see if anyone is offline
      if (newHereNow[conversationSid]) {
        // loop over everyone here
        Object.keys(newHereNow[conversationSid]).forEach((key, value) => {
          // offline must be false
          if (users?.[key]?.isOnline === false) {
            console.log(`found an offline user ${key}`);
            delete newHereNowRoom[key];
          }
        });
      }

      delete newHereNowRoom[user.email];

      newHereNow[conversationSid] = newHereNowRoom;
      // console.log('REMOVED newHereNowRoom', newHereNow, newHereNowRoom);
      await hereNowClient.set(newHereNow);
    }
  };
}
