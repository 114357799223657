import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConversations, getUsers } from '../Store/Reducers/twilio';
import twilioAddHereNow from '../Store/Actions/twilioAddHereNow';
import twilioRemoveHereNow from '../Store/Actions/twilioRemoveHereNow';
import getTwilioUserIdentity from '../Utilities/getTwilioUserIdentity';
import usersIcon from '../Assets/Icons/users.png';
import twilioSubscribeToIdentity from '../Store/Actions/twilioSubscribeToIdentity';

export default function ActiveUsers({
  uniqueName,
  friendlyName,
  embedded,
  onClick,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const conversations = useSelector(getConversations);
  const conversationSid = Object.keys(conversations).find(
    (sid) => conversations[sid].uniqueName === uniqueName
  );
  const conversation = conversations?.[conversationSid];
  let followingCount = 0;
  if (conversation) {
    Object.keys(conversation.participants).forEach((identity) => {
      if (conversation.participants[identity].attributes?.following) {
        followingCount++;
      }
    });

    // conversation?.client?.participants?.forEach(function (part) {
    //   if (part.attributes?.following) {
    //     followingCount++;
    //   }
    //   visitorsCount++;
    //   return true;
    // });
  }
  // const conversationsClient = useSelector(getConversationsClient);
  // const hereNowClient = useSelector(getHereNowClient);
  const users = useSelector(getUsers);
  const hereNowRoom =
    useSelector((state) => state.twilio.herenow?.[uniqueName]) || {};
  // const hereNowRoom = hereNow?.[uniqueName] || {};

  useEffect(() => {
    let savedList;
    (async () => {
      if (embedded) {
        dispatch(twilioAddHereNow(uniqueName));
      }
    })();
    return async () => {
      if (embedded) {
        dispatch(twilioRemoveHereNow(uniqueName));
      }
    };
  }, [user, uniqueName, embedded]);

  useEffect(() => {
    let savedList;
    (() => {
      // subscribe to here now so we know if they go offline
      Object.keys(hereNowRoom).forEach((identity) => {
        if (identity !== getTwilioUserIdentity(user) && !users?.[identity]) {
          dispatch(twilioSubscribeToIdentity(identity));
        }
      });
    })();
  }, [users, hereNowRoom]);

  // // Loop over connected user and count anonymous and active
  // let anonymous = 0;
  // let active = 0;

  // Object.keys(participantsAttributes).forEach((key) => {
  //   if (participantsAttributes[key]?.following) {
  //     active += 1;
  //   } else {
  //     anonymous += 1;
  //   }
  // });

  // const meFollowing =
  //   participantsAttributes[getTwilioUserIdentity(user)]?.following;

  const activeConnectedUsers = Object.keys(hereNowRoom)?.filter((key) => {
    return users?.[key]?.isOnline;
  });

  return (
    <div
      style={{
        fontSize: 14,
        color: '#757575',
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        // marginLeft: 15,
        cursor: onClick && 'pointer',
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <img
        src={usersIcon}
        style={{ width: 15, height: 9, margin: 5 }}
        alt="users icon"
      />{' '}
      Visitors: {Object.keys(activeConnectedUsers).length} here now{' '}
      {followingCount > 0 && `, ${followingCount} following`}
    </div>
  );
}
