import { DELETE_BRIEFCASE_ELEMENT } from './types';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { eventId } from '../../config';

export default function deleteBriefcaseElement(resourceId) {
  return (dispatch, getState) => {
    const items = getState().debug;

    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'delete',
          url: `/briefcase/${eventId}/${resourceId}`,
          headers: { idtoken: data.idToken.jwtToken },
        })
          .then((response) => {
            dispatch(deleteBriefcaseElementAsync(resourceId));
          })
          .catch((error) => {
            if (items.debug) {
              alert(`Error: Could not delete from briefcase. ${error}`);
            }
          });
      })
      .catch((error) => {
        if (items.debug) {
          alert(`Error: Could not update from briefcase. ${error}`);
        }
      });
  };
}

function deleteBriefcaseElementAsync(briefcaseElement) {
  return {
    type: DELETE_BRIEFCASE_ELEMENT,
    payload: briefcaseElement,
  };
}
