import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import AuditoriumCard from './AuditoriumCard';
import { withRouter } from 'react-router';
import Auditorium from './Auditorium';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { eventId } from '../../config';
import trackAction from '../../Utilities/tracker';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';

const useStyles = (theme) => ({
  gridContainer: {},
  gridCard: {
    width: '100%',
  },
});

class ConferenceCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webinars: [],
      rooms: [],
      auditoriumSelected: false,
      auditorium: null,
      topicMap: null,
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'roomName') {
          this.setState({
            auditoriumSelected: true,
            auditorium: param[1],
          });
        }
      }
    }

    this.setState({
      topicMap: JSON.parse(this.props.event.event.topic_map),
    });

    this.getPresentations();
    this.props.setBriefcase();

    const event = {
      action: 'Viewed',
      resource_type: 'ConferenceCenter',
      resource_id: null,
      url_visited: '/auditorium',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    trackAction(event);
  }

  getPresentations() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/agenda/${eventId}`,
          headers: { idtoken: data.idToken.jwtToken },
        })
          .then(
            (response) => {
              if (this.props.location.search) {
                const query = new URLSearchParams(this.props.location.search);
                for (let param of query.entries()) {
                  if (param[0] === 'presentation_id') {
                    const searchedPresentation = response.data.data.filter(
                      (abstract) => abstract.abstract_id === param[1]
                    );
                    if (searchedPresentation.length) {
                      this.setState({
                        auditoriumSelected: true,
                        auditorium: searchedPresentation[0].room,
                      });
                    }
                  }
                }
              }

              let authorizedPresentations = [];

              for (let x = 0; x < response.data.data.length; x++) {
                let abstract = response.data.data[x];

                if (abstract.cleared_for_public_view === 'Yes - Approved') {
                  authorizedPresentations.push(abstract);
                } else {
                  if (
                    this.props.user.user.roles.includes('Attendee') ||
                    this.props.user.user.roles.includes('Pharma Attendee')
                  ) {
                    authorizedPresentations.push(abstract);
                  }
                }
              }

              let roomsArray = [];

              for (let n = 0; n < authorizedPresentations.length; n++) {
                if (
                  !roomsArray.includes(authorizedPresentations[n].room) &&
                  authorizedPresentations[n].room !== 'Break'
                ) {
                  roomsArray.push(authorizedPresentations[n].room);
                }
              }

              roomsArray = roomsArray.filter((e) => e);
              roomsArray.sort();

              this.setState({
                webinars: authorizedPresentations,
                rooms: roomsArray,
              });
            },
            (error) => {
              if (this.props.debug.debug) {
                alert(`Error: Could not display Conference Center. ${error}`);
              }
            }
          )
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Conference Center. ${error}`);
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display Conference Center. ${error}`);
        }
      });
  }

  toggleAuditoriumFullscreenOn = (event, roomName) => {
    this.setState({
      auditoriumSelected: true,
      auditorium: roomName,
    });
  };

  toggleAuditoriumFullscreenOff = () => {
    this.setState({
      auditoriumSelected: false,
      auditorium: null,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      this.toggleAuditoriumFullscreenOff();
      return false;
    }
  }

  render() {
    const { classes } = this.props;
    let pageBody = null;

    const allRoutes = JSON.parse(
      this.props.event.event.available_routes
    ).routes;
    let pageTitle = '';
    allRoutes.forEach((route) => {
      if (route.route === '/auditorium') {
        pageTitle = route.title;
      }
    });

    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );

    if (this.state.auditoriumSelected) {
      pageBody = (
        <Auditorium
          roomName={this.state.auditorium}
          closeAuditorium={this.toggleAuditoriumFullscreenOff.bind(this)}
        />
      );
    } else {
      pageBody = (
        <Grid
          container
          className={classes.gridContainer}
          layout={'row'}
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          {this.state.rooms.length <= 0 ? loadingGraphic : null}
          {this.state.rooms.map((room, index) => {
            return (
              <Grid item xs={6} md={3} key={index} className={classes.gridCard}>
                <AuditoriumCard
                  roomPicture={this.state.topicMap[room]}
                  roomName={room}
                  roomCategory="Breakout"
                  toggleFullscreenHandlerOn={this.toggleAuditoriumFullscreenOn.bind(
                    this
                  )}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    }

    return <React.Fragment>{pageBody}</React.Fragment>;
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
    debug: state.debug,
    event: state.event,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(ConferenceCenter)));
