import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import trackAction from '../../Utilities/tracker';
import PosterChat from './ActivitiesChat';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpIconOutlined from '@material-ui/icons/ThumbUpOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { eventId } from '../../config';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';

const useStyles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  underTabsContainer: {
    // padding: theme.spacing(2),
    textAlign: 'center',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
    textDecoration: 'none',
    height: '100%',
    width: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  linePhoto: {
    borderRadius: '50%',
    width: '5rem',
    height: '5rem',
    [theme.breakpoints.down('xs')]: {
      width: '3rem',
      height: '3rem',
    },
  },
  lineTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingBottom: theme.spacing(1),
  },
  lineAbstract: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  lineSpeakerName: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  lineSpeakerInfo: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  tabSingle: {
    minWidth: 'auto',
  },
});

class PosterFull extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 2,
      poster: null,
      upvote: false,
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  recordEvent = (data) => {
    const event = {
      action: 'Clicked',
      resource_type: 'Poster',
      resource_id: data.abstract_id,
      url_visited: '/posterhall',
      resource_account_id: data.account_id,
      resource_account_name: data.name,
      resource_name: data.title,
    };

    trackAction(event);
  };

  async componentDidMount() {
    try {
      const user = await Auth.currentSession();

      let poster = await axios({
        method: 'get',
        url: `/posters/${eventId}/${this.props.posterId}`,
        headers: {
          idtoken: user.idToken.jwtToken,
        },
      });

      poster.data.data[0].presenters = poster.data.data[0].contacts.filter(
        (contact) => contact.role.toLowerCase() === 'presenter'
      );
      poster.data.data[0].authors = poster.data.data[0].contacts.filter(
        (contact) => contact.role.toLowerCase() === 'author'
      );
      poster.data.data[0].submitters = poster.data.data[0].contacts.filter(
        (contact) => contact.role.toLowerCase() === 'submitter'
      );

      await this.setState({
        poster: poster.data.data[0],
      });

      this.recordEvent(poster.data.data[0]);

      const liked = await axios({
        method: 'get',
        url: `/likes/${eventId}/${this.props.posterId}`,
        headers: {
          idtoken: user.idToken.jwtToken,
        },
      });

      if (liked.data.length) {
        if (liked.data[0].count === '1') {
          this.setState({ upvote: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async upvotePoster() {
    this.setState({ tabValue: this.state.tabValue });

    try {
      const data = await Auth.currentSession();

      if (!this.state.upvote) {
        await axios({
          method: 'POST',
          url: '/likes/' + eventId,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.state.poster.abstract_id,
            resource_type: this.state.poster.accepted_type,
          },
        });
        this.setState({ upvote: true });
        this.props.handleUpvote();
      } else {
        await axios({
          method: 'DELETE',
          url: '/likes/' + eventId + '/' + this.state.poster.abstract_id,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.state.poster.abstract_id,
          },
        });
        this.setState({ upvote: false });
        this.props.handleUpvote();
      }
    } catch (error) {
      console.log(error);
    }
  }

  briefcaseSelect() {
    this.setState({ tabValue: this.state.tabValue });

    let favorited = false;
    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (
          this.props.briefcase.briefcase[n].resource_id ===
          this.state.poster.abstract_id
        ) {
          favorited = true;
          break;
        }
      }
    }

    if (favorited) {
      this.props.deleteBriefcaseElement(this.state.poster.abstract_id);
    } else {
      this.props.addBriefcaseElement(
        'Poster',
        this.state.poster.abstract_id,
        this.state.poster.title,
        this.state.poster.account_id,
        this.props.user.user.account_id
      );
    }
  }

  render() {
    const { classes } = this.props;
    let posterContent;

    if (!this.state.poster) {
      return <CircularProgress color="inherit" />;
    }

    if (this.state.poster) {
      switch (this.state.tabValue) {
        case 2:
          posterContent = (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography variant="h4" color="textPrimary">
                  {this.state.poster.title}
                </Typography>
                <br />
                <Typography variant="h6" color="textPrimary">
                  <b>Topic: </b>
                  {this.state.poster.topic}
                </Typography>
                <br />
                <Divider className={classes.divider} variant="fullWidth" />
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={4}>
                  {this.state.poster.presentation_link ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.poster.presentation_link}
                      style={{ textDecoration: 'none' }}
                    >
                      <Button
                        color="inherit"
                        size="small"
                        variant="contained"
                        className={classes.button}
                      >
                        Live Presentation
                      </Button>
                    </a>
                  ) : (
                    <Button
                      disabled
                      color="inherit"
                      size="small"
                      variant="contained"
                      className={classes.button}
                    >
                      Live Presentation
                    </Button>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {this.state.poster.presentation_pdf ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.poster.presentation_pdf}
                      style={{ textDecoration: 'none' }}
                    >
                      <Button
                        color="inherit"
                        variant="contained"
                        className={classes.button}
                      >
                        PDF
                      </Button>
                    </a>
                  ) : (
                    <Button
                      disabled
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                    >
                      PDF
                    </Button>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {this.state.poster.presentation_video ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.poster.presentation_video}
                      style={{ textDecoration: 'none' }}
                    >
                      <Button
                        color="inherit"
                        variant="contained"
                        className={classes.button}
                      >
                        Intro Video
                      </Button>
                    </a>
                  ) : (
                    <Button
                      disabled
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                    >
                      Intro Video
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} variant="fullWidth" />
                <br />
                <Typography variant="h6" color="textPrimary" align="center">
                  <b>Description </b>
                </Typography>
                <pre style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    align="justify"
                  >
                    {this.state.poster.content}
                  </Typography>
                </pre>
              </Grid>
            </React.Fragment>
          );
          break;
        case 3:
          const keyValue = (key, value) => {
            if (value) {
              return (
                <>
                  <b>{key}:</b> {value}
                </>
              );
            }
          };
          posterContent = (
            <React.Fragment>
              <Grid item xs={12}>
                {this.state.poster.presenters.length ? (
                  <React.Fragment>
                    <Typography variant="h5" color="textPrimary">
                      <b>Presenters</b>
                    </Typography>
                    <Divider className={classes.divider} variant="fullWidth" />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {this.state.poster.presenters.map((presenter, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.lineSpeakerName}
                          variant="h6"
                        >
                          {`${presenter.first_name} ${presenter.last_name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {presenter.photo_url ? (
                          <img
                            className={classes.linePhoto}
                            src={presenter.photo_url}
                            alt={'Presenter'}
                          />
                        ) : null}
                      </Grid>
                      <Grid item xs={10} style={{ paddingLeft: '8px' }}>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Title', presenter.title)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Account', presenter.account_name)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Department', presenter.department)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Expertise', presenter.expertise_area)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Email', presenter.email)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Biography', presenter.bio)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider
                          className={classes.divider}
                          variant="fullWidth"
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '3vw' }}>
                {this.state.poster.authors.length ? (
                  <React.Fragment>
                    <Typography variant="h5" color="textPrimary">
                      <b>Authors</b>
                    </Typography>
                    <Divider className={classes.divider} variant="fullWidth" />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {this.state.poster.authors.map((author, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.lineSpeakerName}
                          variant="h6"
                        >
                          {`${author.first_name} ${author.last_name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {author.photo_url ? (
                          <img
                            className={classes.linePhoto}
                            src={author.photo_url}
                            alt={'Presenter'}
                          />
                        ) : null}
                      </Grid>
                      <Grid item xs={10} style={{ paddingLeft: '8px' }}>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Title', author.title)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Account', author.account_name)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Department', author.department)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Expertise', author.expertise_area)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Email', author.email)}
                        </Typography>
                        {/* <Typography variant='body1' className={classes.lineSpeakerInfo}>
                            {keyValue('Biography', author.bio)}
                          </Typography> */}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider
                          className={classes.divider}
                          variant="fullWidth"
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </React.Fragment>
          );
          break;
        default:
          posterContent = <div>No Content</div>;
          break;
      }
    } else {
      posterContent = <div></div>;
      return <div></div>;
    }

    let favorited = false;
    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (
          this.props.briefcase.briefcase[n].resource_id ===
          this.state.poster.abstract_id
        ) {
          favorited = true;
          break;
        }
      }
    }

    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} sm={2} align="left">
          <Button onClick={() => this.props.closeBooth()}>
            <ChevronLeftIcon /> Back
          </Button>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {favorited ? (
              <Tab
                className={classes.tabSingle}
                onClick={() => {
                  this.briefcaseSelect();
                }}
                icon={<WorkIcon />}
              />
            ) : (
              <Tab
                className={classes.tabSingle}
                onClick={() => {
                  this.briefcaseSelect();
                }}
                icon={<WorkOutlineIcon />}
              />
            )}
            {this.state.upvote ? (
              <Tab
                className={classes.tabSingle}
                onClick={() => {
                  this.upvotePoster();
                }}
                icon={<ThumbUpIcon />}
              />
            ) : (
              <Tab
                className={classes.tabSingle}
                onClick={() => {
                  this.upvotePoster();
                }}
                icon={<ThumbUpIconOutlined />}
              />
            )}
            <Tab className={classes.tabSingle} label={'Information'} />
            <Tab className={classes.tabSingle} label={'Contributors'} />
            {/* <Tab className={classes.exitTab, classes.tabSingle} label='Exit' onClick={() => this.props.closeBooth()} /> */}
          </Tabs>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={2} align="right" />
        </Hidden>
        <Grid item xs={12} md={6}>
          <div className={classes.paper}>
            <Grid
              className={classes.underTabsContainer}
              container
              spacing={2}
              justify="center"
              alignItems="center"
            >
              {posterContent}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.paper}>
            <PosterChat
              poster={this.state.poster}
              channel={this.state.poster.abstract_id}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(PosterFull));
