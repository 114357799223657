import React, { Component } from 'react';
import NavBar from '../Components/Navbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import DialogWrapper from '../Components/DialogWrapper';
import Privacy from './Privacy';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  landingPage,
  // attendeeGuide,
  eventId,
} from '../config';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  headings: {
    padding: theme.spacing(3),
  },
  listElement: {
    paddingLeft: '20px',
  },
  image: {
    height: '100px',
    width: '100px',
    borderRadius: '50%',
  },
  button: {
    position: 'fixed',
    bottom: '16px',
    left: '16px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  closeButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    width: '60%',
    marginLeft: '20%',
    marginRight: '20%',
    fontSize: '1rem',
  },
  footer: {
    height: '5vw',
    backgroundColor: theme.palette.primary.main,
  },
});

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privacyOpen: false,
    };
  }

  handleChange(value, inputIdentifier) {
    this.setState({ [inputIdentifier]: value });
  }

  render() {
    const { classes } = this.props;

    let landingPageMainText;
    if (this.props.event.event) {
      if (this.props.event.event.landing_page_content) {
        landingPageMainText =
          this.props.event.event.landing_page_content.split('<br/>');
      }
    }

    return (
      <div className={classes.root}>
        <NavBar event={eventId} />
        <Grid
          container
          layout={'row'}
          spacing={0}
          justify="center"
          align="center"
          style={{ marginTop: '60px' }}
        >
          <Grid item xs={12}>
            <img
              src={
                this.props.event
                  ? this.props.event.event.banner_location
                  : landingPage.banner
              }
              alt="Lobby"
              style={{
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                position: 'relative',
                padding: 0,
                margin: 0,
                top: '-4px',
              }}
            />
            <br />
            <br />
            <Typography
              variant="h5"
              gutterBottom
              align="center"
              style={{ marginLeft: '16px', marginRight: '16px' }}
            >
              Welcome to the {this.props.event.event.title}.
              <br />
              <br />
              {landingPageMainText
                ? landingPageMainText.map((text, i) => {
                    let breaks;
                    if (i !== 0) {
                      breaks = (
                        <>
                          <br />
                          <br />
                        </>
                      );
                    }

                    return (
                      <>
                        {breaks}
                        {text}
                      </>
                    );
                  })
                : null}
            </Typography>
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={'/registration'}
              size="large"
            >
              {this.props.event.event.under_construction
                ? 'Register for this Event'
                : 'Enter the Virtual Environment'}
            </Button>
            <br />
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={'https://player.vimeo.com/video/638833665?h=c66a1e6d20'}
              style={{ textDecoration: 'none', color: 'white' }}
            >
              <Button color="primary" size="large" variant="outlined">
                Login Instructions
              </Button>
            </a>
            <br />
            <br />
            {/* <br/><br/>
            <img 
            alt = 'logo'
            style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '36%',
            }}  
            src = 'https://pci-admin-kfc-portal.s3.amazonaws.com/SignatureSponsor_PerkinElmer.png'
            /> */}
            {/* <a target="_blank" rel="noopener noreferrer" href={attendeeGuide} style={{textDecoration: 'none', color: 'white',}}>
              <Button color="primary" size="large">User Guide</Button>
            </a> */}
          </Grid>
        </Grid>
        <DialogWrapper
          open={this.state.privacyOpen}
          handleClose={(e) => this.setState({ privacyOpen: false })}
        >
          <Privacy />
          <Button
            className={classes.closeButton}
            variant="contained"
            onClick={(e) => this.handleChange(false, 'privacyOpen')}
          >
            Close
          </Button>
        </DialogWrapper>
        <Button
          className={classes.button}
          variant="contained"
          onClick={(e) => this.handleChange(true, 'privacyOpen')}
        >
          View{' '}
          <Hidden smUp>
            <br />
          </Hidden>
          Privacy{' '}
          <Hidden smUp>
            <br />
          </Hidden>
          Policy
        </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    event: state.event,
  };
}

export default connect(
  mapStateToProps,
  null
)(withStyles(useStyles)(LandingPage));
