import React, { Component } from 'react';
import NavBar from '../../Components/Navbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import DialogWrapper from '../../Components/DialogWrapper';
import Privacy from '../Privacy';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  landingPage,
  // attendeeGuide,
  eventId,
} from '../../config';

import Overview from './Overview';
import Security from './Security';
import Posters from './Posters';
import Exhibitors from './Exhibitors';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
  cardRoot: {
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#f5f5f5',
    cursor: 'default',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  headings: {
    padding: theme.spacing(3),
  },
  listElement: {
    paddingLeft: '20px',
  },
  image: {
    height: '100px',
    width: '100px',
    borderRadius: '50%',
  },
  button: {
    position: 'fixed',
    bottom: '16px',
    left: '16px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  closeButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    width: '60%',
    marginLeft: '20%',
    marginRight: '20%',
    fontSize: '1rem',
  },
  footer: {
    height: '5vw',
    backgroundColor: theme.palette.primary.main,
  },
});

const cards = [
  {
    title: 'Summit',
    subtitle: 'Executives and Leaders',
    image:
      'https://images.unsplash.com/photo-1511578314322-379afb476865?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80',
    text: 'Spearhead new mission critical objectives by bringing decision makers and thought leaders together for a singular purpose.',
  },
  {
    title: 'Conference',
    subtitle: 'A Wide Audience',
    image:
      'https://images.unsplash.com/photo-1551818255-e6e10975bc17?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1973&q=80',
    text: 'Facilitate creative thinking and knowledge transfer with a large, open ended gathering to explore a diverse set of field related topics.',
  },
  {
    title: 'Road Show',
    subtitle: 'Prospective Clients',
    image:
      'https://images.unsplash.com/photo-1531058020387-3be344556be6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80',
    text: 'Qualify leads, profile prospects, and elevate existing clients with your own custom event. Engage prospects and educate clients.',
  },
  {
    title: 'Symposium',
    subtitle: 'Scientists, Engineers',
    image:
      'https://images.unsplash.com/photo-1588196749597-9ff075ee6b5b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80',
    text: 'Command the prestige of a premier conference with a focused event that highlights a dedicated area of science',
  },
];

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privacyOpen: false,
    };
  }

  handleChange(value, inputIdentifier) {
    this.setState({ [inputIdentifier]: value });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <NavBar event={eventId} />
        <Overview />
        <Security />
        <Posters />
        <Exhibitors />
        <Container>
          <Grid
            container
            layout={'row'}
            spacing={0}
            justify="center"
            align="center"
            style={{ marginTop: '10vw', marginBottom: '5vw' }}
          >
            <Grid
              item
              xs={12}
              style={{
                paddingLeft: '2vw',
                paddingRight: '2vw',
                // marginBottom: '4vw',
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                align="center"
                style={{ color: '#122857' }}
              >
                <b>Ways to Use EventHorizon</b>
              </Typography>
            </Grid>
            {cards.map((card, i) => {
              return (
                <Grid i item xs={12} sm={6} md={3} style={{ padding: '2vw' }}>
                  <Card className={classes.cardRoot}>
                    <CardHeader title={card.title} subheader={card.subtitle} />
                    <CardMedia
                      component="img"
                      height="194"
                      image={card.image}
                      alt={`image${i}`}
                    />
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        {card.text}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Container>
        {/* <Grid
          container
          layout={'row'}
          spacing={0}
          justify="center"
          align="center"
          style={{
            marginTop: '10vw',
            marginBottom: '5vw',
            backgroundColor: '#122857',
          }}
        >
          <Grid item xs={12} style={{ padding: '7vw 0vw' }}>
            <Container>
              <Typography
                variant="h4"
                align="center"
                style={{ color: 'white' }}
              >
                <b>
                  "Our experience working with PlanetConnect for our virtual
                  user meeting was outstanding!"
                </b>
              </Typography>
              <br />
              <br />
              <Typography
                variant="body1"
                align="center"
                style={{ color: 'white' }}
              >
                "They understood our needs and our user’s needs perfectly, kept
                up with a rapidly evolving agenda, and produced a virtual
                meeting environment that our customers really enjoyed. The
                virtual interface provided by [EventHorizon] was interactive and
                graphically interesting, and was easy and fun to use. We saw the
                highest ever attendance on any of our user meetings compared to
                both in person and virtual. Customer participation was very high
                so our virtual user meeting was a huge success."
              </Typography>
              <br />
              <br />
              <Typography
                variant="h5"
                align="center"
                style={{ color: 'white' }}
              >
                <b>- Patty Ahrweiler,</b> <br />
                Marketing Manager at Gyros Protein Technologies
              </Typography>
            </Container>
          </Grid>
        </Grid> */}
        <Container>
          <Grid
            container
            layout={'row'}
            spacing={0}
            justify="center"
            align="center"
            style={{ marginBottom: '5vw' }}
          >
            {/* <Grid item xs={12}>
              <Typography variant="h4" align="center">
                <b>Learn More About Our Platform and Get to Know Our Team!</b>
              </Typography>
              <iframe
                title="marketing video"
                // width="500"
                // height="500"
                src="https://player.vimeo.com/video/652988535?h=e2a6bf5242"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  height: '30vw',
                  width: '50vw',
                  padding: '5vw',
                }}
              />
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              style={{ paddingLeft: '2vw', paddingRight: '2vw' }}
            >
              <Typography
                variant="h4"
                gutterBottom
                align="center"
                style={{ color: '#122857' }}
              >
                <b>Get in Touch!</b>
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                style={{ color: '#122857', paddingRight: '40px' }}
              >
                <b>
                  <ul>
                    <li>The virtual platform built for life science events</li>
                    <li>Trusted for over 25 years by the industry's best</li>
                    <li>
                      Secure platform to share your scientific discoveries
                    </li>
                    <li>A flexible poster hall for any event</li>
                    <li>Fill your event with thought leaders and partners</li>
                  </ul>
                </b>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              style={{ paddingLeft: '1vw', paddingRight: '1vw' }}
              align="center"
            >
              <Paper
                className={classes.paper}
                style={{ backgroundColor: '#f5f5f5' }}
              >
                <iframe
                  title="ContactUs"
                  width="610px"
                  height="210px"
                  frameborder="0"
                  border="0"
                  cellspacing="0"
                  style={{
                    borderStyle: 'none',
                    width: '100%',
                    height: '330px',
                  }}
                  src="https://crm.zoho.com/crm/WebFormServeServlet?rid=5b533e180bb7cb402956573ffbc8abcd07dc7f84b723558c35dd8cf8e5845626gidb898faf31f81f7cb4aa9356f86d72a4289b01e765c1b5291bc67ef1020687224"
                ></iframe>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <Grid
          container
          layout={'row'}
          spacing={0}
          justify="center"
          align="center"
        >
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={'/registration'}
              size="large"
              style={{ marginBottom: '16px', width: '80%' }}
            >
              View Demo
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: '#122857', height: '100px' }}
          />
        </Grid>
      </div>
    );
  }
}

export default withStyles(useStyles)(LandingPage);
