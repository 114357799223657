import { getUsers, getConversationsClient } from '../Reducers/twilio';

export default function twilioSubscribeToIdentity(identity) {
  return async (dispatch, getState) => {
    const conversationsClient = getConversationsClient(getState());
    const users = getUsers(getState());

    if (
      conversationsClient &&
      (!users?.[identity] || !users[identity].isSubscribed)
    ) {
      conversationsClient
        .getUser(identity)
        .then((subscribedUser) => {
          // console.log(`subscribed to ${subscribedUser.identity}`);
        })
        .catch((error) => {
          // console.log('User has not registered', error.message);
        });
    }
  };
}
