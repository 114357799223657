import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = (theme) => ({
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(2, 4, 3),
  },
});

class SimpleDialog extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Dialog
        className={classes.dialog}
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
        maxWidth="lg"
        open={true}
        onClose={(e) => {
          this.props.onRequestClose(e);
          // this.props.onMouseLeave()
        }}
        // onExit={() => this.props.onMouseLeave()}
      >
        <div className={classes.paper}>
          {this.props.header}
          {this.props.children}
          <Button
            onClick={(e) => {
              this.props.onRequestClose(e);
              // this.props.onMouseLeave()
            }}
            variant="contained"
            color="primary"
            fullWidth
          >
            Close
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(useStyles)(SimpleDialog);
