import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import WorkIcon from '@material-ui/icons/Work';
// import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpIconOutlined from '@material-ui/icons/ThumbUpOutlined';
import IconButton from '@material-ui/core/IconButton';

import { Auth } from 'aws-amplify';
import axios from 'axios';
import { eventId } from '../../config';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';

const useStyles = (theme) => ({
  root: {
    width: '100%',
    '& .MuiTypography-root': {
      alignSelf: 'center',
    },
    '& .MuiChip-root	': {
      alignSelf: 'center',
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0.5),
    },
  },
  expansionPanelMain: { padding: '0px 8px 0px 8px' },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: '60%',
    flexShrink: 0,
  },
  panelExpanded: {
    '& .MuiGrid-item': {
      // textAlign: 'center',
    },
  },
  icon: {
    minWidth: '10em',
    maxWidth: '30em',
    maxHeight: '30em',
    width: '100%',
  },

  buttonLearn: {
    minWidth: '80%',
    fontSize: theme.typography.pxToRem(15),
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  textBody: {
    fontSize: theme.typography.pxToRem(15),
    alignSelf: 'center',
    textAlign: 'left',
  },
  lineSpeakerName: {
    fontWeight: 'bold',
    textAlign: 'left',
  },
  lineSpeakerTitle: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  lineSpeakerCompany: {
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'left',
  },
});

class PosterListItem extends Component {
  componentDidMount() {
    if (this.props.poster.contacts) {
      this.props.poster.contacts.sort((a, b) => {
        if (a.order === null) {
          a.order = 1000;
        }

        if (b.order === null) {
          b.order = 1000;
        }

        return a.order - b.order;
      });
    }
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  async upvotePoster() {
    try {
      const data = await Auth.currentSession();

      if (!this.props.poster.liked) {
        await axios({
          method: 'POST',
          url: `/likes/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.props.poster.abstract_id,
            resource_type: this.props.poster.accepted_type,
          },
        });
        this.props.handleUpvote();
      } else {
        await axios({
          method: 'DELETE',
          url: `/likes/${eventId}/${this.props.poster.abstract_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.props.poster.abstract_id,
          },
        });
        this.props.handleUpvote();
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { classes } = this.props;
    const poster = this.props.poster;

    // let favorited = false;
    // if (this.props.briefcase.briefcase) {
    //   for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
    //     if (
    //       this.props.briefcase.briefcase[n].resource_id ===
    //       this.props.poster.abstract_id
    //     ) {
    //       favorited = true;
    //       break;
    //     }
    //   }
    // }

    return (
      <Accordion key={`PosterListItem${poster.index}`} className={classes.root}>
        <AccordionSummary
          className={classes.expansionPanelMain}
          expandIcon={<ExpandMoreIcon />}
          id={poster.abstract_id}
        >
          <Grid
            className={classes.panelExpanded}
            container
            layout={'row'}
            spacing={2}
            alignItems="center"
            justify="space-between"
          >
            {/* <Grid item xs={1}>
            {favorited ?
              <IconButton
                aria-label="remove from briefcase"
                onClick={(event) => {
                  event.stopPropagation()
                  this.props.deleteBriefcaseElement(this.props.poster.abstract_id)
                }}
              >
                <WorkIcon />
              </IconButton>
              :
              <IconButton
                aria-label="add to briefcase"
                onClick={(event) => {
                  event.stopPropagation()
                  this.props.addBriefcaseElement(
                    'Poster',
                    this.props.poster.abstract_id,
                    this.props.poster.title,
                    this.props.poster.account_id,
                    this.props.user.user.account_id
                  )
                }}
              >
                <WorkOutlineIcon />
              </IconButton>
            }
          </Grid> */}
            <Grid item xs={2}>
              <IconButton
                aria-label="upvote"
                onClick={(event) => {
                  event.stopPropagation();
                  this.upvotePoster();
                }}
                onMouseEnter={() => this.handleChange(true, 'buttonHover')}
                onMouseLeave={() => this.handleChange(false, 'buttonHover')}
              >
                {this.props.poster.liked ? (
                  <ThumbUpIcon />
                ) : (
                  <ThumbUpIconOutlined />
                )}
                <Typography variant="subtitle1" style={{ marginLeft: '4px' }}>
                  {this.props.poster.count}
                </Typography>
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography className={classes.heading}>
                {poster.title ? poster.title : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Typography className={classes.heading}>
                {poster.presenter_first_name && poster.presenter_last_name
                  ? poster.presenter_first_name +
                    ' ' +
                    poster.presenter_last_name
                  : ''}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <Typography className={classes.heading}>
                {poster.presenter_title ? poster.presenter_title : ''}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <Typography className={classes.heading}>
                {poster.presenter_company ? poster.presenter_company : ''}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            className={classes.panelExpanded}
            container
            layout={'row'}
            spacing={2}
            alignItems="center"
            justify="space-between"
          >
            <Grid item xs={12} sm={12} md={7} lg={10}>
              {poster.contacts
                ? poster.contacts.map((contact, index) => {
                    if (
                      contact.roles.join().toLowerCase().includes('presenter')
                    ) {
                      return (
                        <Grid
                          container
                          key={index}
                          style={{ alignItems: 'center' }}
                        >
                          {contact.photo_url ? (
                            <Grid item xs={3}>
                              <img
                                className={classes.linePhoto}
                                src={contact.photo_url}
                                alt={'Presenter'}
                              />
                            </Grid>
                          ) : null}
                          <Grid item xs={9} style={{ paddingLeft: '8px' }}>
                            <Typography
                              className={classes.lineSpeakerName}
                              // variant='h6'
                              color="textPrimary"
                            >
                              {contact.first_name + ' ' + contact.last_name}
                            </Typography>
                            {contact.title ? (
                              <Typography className={classes.lineSpeakerTitle}>
                                {contact.title}
                              </Typography>
                            ) : (
                              <React.Fragment />
                            )}
                            {contact.account_name ? (
                              <Typography
                                className={classes.lineSpeakerCompany}
                              >
                                {contact.account_name}
                              </Typography>
                            ) : (
                              <React.Fragment />
                            )}
                          </Grid>
                        </Grid>
                      );
                    }
                    return null;
                  })
                : null}
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} align="right">
              <Button
                className={classes.buttonLearn}
                variant="contained"
                size="medium"
                onClick={(event) =>
                  this.props.toggleFullscreenHandlerOn(
                    event,
                    this.props.poster.abstract_id
                  )
                }
              >
                Art Details
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(PosterListItem));
