import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import PosterCard from './PosterCard';
import PosterListItem from './PosterListItem';
import PosterFull from './PosterFull';
import Dialog from '../../Components/DialogWrapper';
import { withRouter } from 'react-router';
import { eventId } from '../../config';
import trackAction from '../../Utilities/tracker';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';

const useStyles = (theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  filter: {
    margin: '0% 0% 1% 0%',
    '& #filterInput': {
      backgroundColor: 'white',
      borderRadius: '5%',
      boxShadow: '0px 1px 1px 0px rgba(120,120,120,0.75)',
      '&:hover': {},
    },
  },
  paginationGrid: {
    alignSelf: 'center',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  topicFilter: {
    fontWeight: '500',
    color: '#000000',
    fontSize: '1.2rem',
  },
});

class PosterSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posters: [],
      posterSelected: false,
      posterIndex: null,
      posterId: null,
      postersFiltered: [],
      listFormat: false,
      filterString: '',
      filterCategory: 'topic',
      filtering: false,
      pageCountStart: 1,
      perPage: 36,
      totalPages: 1,
      page: 1,
      topicMap: null,
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'poster_id') {
          this.setState({
            posterSelected: true,
            posterId: param[1],
          });
        }
      }
    }

    // this.startLiveNowCheck()
    // this.getTopics();
    this.getPosters();
    this.props.setBriefcase();

    const event = {
      action: 'Viewed',
      resource_type: 'PosterHall',
      resource_id: null,
      url_visited: '/posterhall',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    trackAction(event);
  }

  startLiveNowCheck = () => {
    this.intervalID = setInterval(
      this.changeLiveNow,
      250000 + Math.random() * 100000
    ); //check once every 5 or so minutes
  };

  changeLiveNow = () => {
    this.getPosters();
  };

  stopLiveNowCheck = () => {
    clearInterval(this.intervalID);
  };

  componentWillUnmount() {
    this.stopLiveNowCheck();
  }

  // getTopics() {
  //   Auth.currentSession().then((data) => {
  //     axios({
  //       method: 'get',
  //       url: `/group/topics/${eventId}`,
  //       headers: {
  //         idtoken: data.idToken.jwtToken,
  //       },
  //       params: {
  //         first_row: this.state.pageCountStart,
  //         rows_per_page: this.state.perPage,
  //         search_columns: this.state.filterCategory,
  //         search_value: this.state.filterString,
  //       },
  //     })
  //       .then((response) => {
  //         console.log(response);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   });
  // }

  getPosters() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/group/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          params: {
            first_row: this.state.pageCountStart,
            rows_per_page: this.state.perPage,
            filter_column: this.state.filterCategory,
            filter_value: this.state.filterString,
          },
        })
          .then((response) => {
            // console.log(response);

            // let authorizedPosters = []

            //   for (let i = 0; i < response.data.data.length; i++){

            //     let poster = response.data.data[i]
            //     if(poster.cleared_for_public_view === "Yes - Approved"){
            //       authorizedPosters.push(poster);
            //     } else {
            //     if(this.props.user.user.roles.includes('Attendee') || this.props.user.user.roles.includes('Pharma Attendee')){
            //       authorizedPosters.push(poster);
            //     }

            //   }
            // }

            // if (this.state.posters.length){
            //   let noChange = true
            //   for (let i = 0; i < this.state.posters.length; i++){
            //     if(authorizedPosters[i].live_now !== this.state.posters[i].live_now){
            //       noChange = false
            //     }
            //   }
            //   if(!noChange){
            //     this.setState({
            //       posters: authorizedPosters,
            //       authorizedPosters,
            //       totalPages: Math.ceil(
            //         response.data.recordsTotal / this.state.perPage
            //       ),
            //     });
            //   }

            // } else {

            this.setState({
              posters: response.data.data,
              authorizedPosters: response.data.data,
              totalPages: Math.ceil(
                response.data.recordsFiltered / this.state.perPage
              ),
              topicMap: JSON.parse(this.props.event.event.topic_map),
            });

            // }
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Poster Hall. ${error}`);
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display Poster Hall. ${error}`);
        }
      });
  }

  togglePosterFullscreenOn = (event, id) => {
    this.setState({
      posterSelected: true,
      posterId: id,
    });
  };

  togglePosterFullscreenOff = () => {
    this.setState({
      posterSelected: false,
      posterId: null,
    });
  };

  handleGridListToggle = () => {
    this.setState({ listFormat: !this.state.listFormat });
  };

  handleFilterClick = (e) => {
    this.setState({
      filterString: e.target.value,
      // filterCategory: category,
      filtering: true,
      pageCountStart: 1,
      page: 1,
    });
    this.getPosters();
  };

  handleRemoveFilter = () => {
    this.setState({
      filterString: '',
      filtering: false,
      pageCountStart: 1,
      page: 1,
    });
    this.getPosters();
  };

  handlePageChange = (event, value) => {
    this.setState({
      page: value,
      pageCountStart: value * this.state.perPage - (this.state.perPage - 1),
    });

    this.getPosters();
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      this.setState({
        posterSelected: false,
        posterIndex: null,
        posterId: null,
      });
      return false;
    }
  }

  handleClose() {
    this.setState({
      posterSelected: false,
      posterIndex: null,
      posterId: null,
    });
  }

  handleUpvote = (index) => {
    let items = [...this.state.posters];
    let item = { ...items[index] };
    if (item.liked === true) {
      item.count = item.count - 1;
      item.liked = false;
    } else {
      item.count = item.count + 1;
      item.liked = true;
    }
    items[index] = item;
    this.setState({ posters: items });
  };

  handleUpvotePosterFull = (posterId) => {
    let items = [...this.state.posters];
    items.forEach((item) => {
      if (item.abstract_id === posterId) {
        if (item.liked === true) {
          item.count = item.count - 1;
          item.liked = false;
        } else {
          item.count = item.count + 1;
          item.liked = true;
        }
      }
    });
    this.setState({ posters: items });
  };

  handleGroupAdd = (index) => {
    let items = [...this.state.posters];
    let item = { ...items[index] };
    if (item.groupInterested === true) {
      item.groupInterested = false;
      item.groupCount = item.groupCount - 1;
    } else {
      item.groupInterested = true;
      item.groupCount = item.groupCount + 1;
    }
    items[index] = item;
    this.setState({ posters: items });
  };

  handleGroupAddPosterFull = (posterId) => {
    let items = [...this.state.posters];
    items.forEach((item) => {
      if (item.abstract_id === posterId) {
        if (item.groupInterested === true) {
          item.groupCount = item.groupCount - 1;
          item.groupInterested = false;
        } else {
          item.groupCount = item.groupCount + 1;
          item.groupInterested = true;
        }
      }
    });
    this.setState({ posters: items });
  };

  render() {
    const { classes } = this.props;

    const allRoutes = JSON.parse(
      this.props.event.event.available_routes
    ).routes;
    let pageTitle = '';
    allRoutes.forEach((route) => {
      if (route.route === '/posterhall') {
        pageTitle = route.title;
      }
    });

    let sidebarOpen;
    if (this.props.sidebar.chatOpen || this.props.sidebar.open) {
      sidebarOpen = true;
    } else {
      sidebarOpen = false;
    }

    let pageBody;
    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );
    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <Typography variant="h4" color="textPrimary">
          No Results Found
        </Typography>
      </Grid>
    );
    let contentLoading = (
      <Grid
        container
        className={classes.gridContainer}
        layout={'row'}
        spacing={2}
      >
        <Grid item xs={12}>
          <Paper>
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="h4"
              color="textPrimary"
            >
              {pageTitle}
            </Typography>
          </Paper>
        </Grid>
        {this.state.filtering && this.state.posters.length === 0
          ? noResultsFound
          : loadingGraphic}
      </Grid>
    );

    let allTopics = Object.keys(JSON.parse(this.props.event.event.topic_map));
    allTopics.shift(); //remove Default from the array
    let topicsMenuItems;
    topicsMenuItems = allTopics.map((topic) => {
      return (
        <MenuItem value={topic}>
          <b>{topic}</b>
        </MenuItem>
      );
    });

    if (this.state.listFormat) {
      pageBody = (
        <Grid
          container
          className={classes.gridContainer}
          layout={'row'}
          spacing={2}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          <Grid
            container
            className={classes.gridContainer}
            layout={'row'}
            spacing={2}
          >
            <Grid
              align="left"
              justify="center"
              key="pagination"
              item
              xs={4}
              className={classes.paginationGrid}
            >
              <Pagination
                count={this.state.totalPages}
                page={this.state.page}
                onChange={this.handlePageChange}
              />
            </Grid>
            <Grid item align="right" xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  <Typography variant="body1" className={classes.topicFilter}>
                    Topic Filter
                  </Typography>
                </InputLabel>
                <Select
                  value={this.state.filterString}
                  label="Topic"
                  onChange={(e) => this.handleFilterClick(e)}
                >
                  {topicsMenuItems}
                </Select>
              </FormControl>
            </Grid>
            <Grid item align="right" xs={2}>
              {this.state.filtering ? (
                <Button
                  variant="contained"
                  size="large"
                  onClick={this.handleRemoveFilter}
                  className={classes.button}
                >
                  Remove Filter
                </Button>
              ) : null}
            </Grid>
            <Grid item align="right" xs={2}>
              <Hidden xsDown>
                <Button onClick={this.handleGridListToggle}>
                  Grid
                  <Switch
                    checked={this.state.listFormat}
                    onChange={this.handleGridListToggle}
                    color="primary"
                    name="listFormatSwitch"
                    inputProps={{ 'aria-label': 'listFormatSwitch' }}
                  />
                  List
                </Button>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {this.state.posters.map((poster, index) => {
              return (
                <PosterListItem
                  key={index}
                  index={index}
                  poster={poster}
                  toggleFullscreenHandlerOn={this.togglePosterFullscreenOn.bind(
                    this
                  )}
                  handleUpvote={() => this.handleUpvote(index)}
                  handleGroupAdd={() => this.handleGroupAdd(index)}
                />
              );
            })}
            <Dialog
              open={this.state.posterSelected}
              handleClose={this.handleClose.bind(this)}
              scroll="body"
            >
              {this.state.posters.length ? (
                <PosterFull
                  posterId={this.state.posterId}
                  allPosters={this.state.posters}
                  closeBooth={this.togglePosterFullscreenOff.bind(this)}
                  handleUpvote={() =>
                    this.handleUpvotePosterFull(this.state.posterId)
                  }
                  handleGroupAdd={() =>
                    this.handleGroupAddPosterFull(this.state.posterId)
                  }
                />
              ) : null}
            </Dialog>
          </Grid>
          <Grid key="pagination" item xs={12}>
            <Pagination
              count={this.state.totalPages}
              page={this.state.page}
              onChange={this.handlePageChange}
            />
          </Grid>
        </Grid>
      );
    } else {
      pageBody = (
        <Grid
          container
          className={classes.gridContainer}
          layout={'row'}
          spacing={2}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          <Grid
            container
            className={classes.gridContainer}
            layout={'row'}
            spacing={2}
          >
            <Grid
              align="left"
              justify="center"
              key="pagination"
              item
              xs={12}
              sm={4}
              className={classes.paginationGrid}
            >
              <Pagination
                count={this.state.totalPages}
                page={this.state.page}
                onChange={this.handlePageChange}
              />
            </Grid>
            <Grid item align="right" xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  <Typography variant="body1" className={classes.topicFilter}>
                    Topic Filter
                  </Typography>
                </InputLabel>
                <Select
                  value={this.state.filterString}
                  label="Topic"
                  onChange={(e) => this.handleFilterClick(e)}
                >
                  {topicsMenuItems}
                </Select>
              </FormControl>
            </Grid>
            <Grid item align="right" xs={12} sm={2}>
              {this.state.filtering ? (
                <Button
                  variant="contained"
                  size="large"
                  onClick={this.handleRemoveFilter}
                  className={classes.button}
                >
                  Remove Filter
                </Button>
              ) : null}
            </Grid>
            <Grid item align="right" xs={2}>
              <Hidden xsDown>
                <Button onClick={this.handleGridListToggle}>
                  Grid
                  <Switch
                    checked={this.state.listFormat}
                    onChange={this.handleGridListToggle}
                    color="primary"
                    name="listFormatSwitch"
                    inputProps={{ 'aria-label': 'listFormatSwitch' }}
                  />
                  List
                </Button>
              </Hidden>
            </Grid>
          </Grid>
          {this.state.posters.map((poster, index) => {
            return (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={sidebarOpen ? 6 : 4}
                lg={sidebarOpen ? 4 : 3}
                xl={sidebarOpen ? 3 : 2}
              >
                <PosterCard
                  topicMap={this.state.topicMap}
                  poster={poster}
                  index={index}
                  toggleFullscreenHandlerOn={this.togglePosterFullscreenOn.bind(
                    this
                  )}
                  handleUpvote={() => this.handleUpvote(index)}
                  handleGroupAdd={() => this.handleGroupAdd(index)}
                />
              </Grid>
            );
          })}
          <Grid key="pagination" item xs={12}>
            <Pagination
              count={this.state.totalPages}
              page={this.state.page}
              onChange={this.handlePageChange}
            />
          </Grid>
          <Dialog
            open={this.state.posterSelected}
            handleClose={this.handleClose.bind(this)}
            scroll="body"
          >
            {this.state.posters.length ? (
              <PosterFull
                posterId={this.state.posterId}
                allPosters={this.state.posters}
                closeBooth={this.togglePosterFullscreenOff.bind(this)}
                handleUpvote={(posterId) =>
                  this.handleUpvotePosterFull(posterId)
                }
                handleGroupAdd={() =>
                  this.handleGroupAddPosterFull(this.state.posterId)
                }
              />
            ) : null}
          </Dialog>
        </Grid>
      );
    }

    let page = this.state.posters.length > 0 ? pageBody : contentLoading;

    return page;
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
    sidebar: state.sidebar,
    debug: state.debug,
    event: state.event,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(PosterSessions)));
