import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import PosterCard from './SciArtCard';
import PosterListItem from './SciArtListItem';
import PosterFull from './SciArtFull';
import Filter from './SciArtFilter';
import Dialog from '../../Components/DialogWrapper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router';
import { eventId } from '../../config';
import trackAction from '../../Utilities/tracker';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';

const useStyles = (theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  filter: {
    margin: '0% 0% 1% 0%',
    '& #filterInput': {
      backgroundColor: 'white',
      borderRadius: '5%',
      boxShadow: '0px 1px 1px 0px rgba(120,120,120,0.75)',
      '&:hover': {},
    },
  },
  gridContainer: {},
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
});

class PosterSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posters: [],
      posterSelected: false,
      posterIndex: null,
      posterId: null,
      postersFiltered: [],
      listFormat: false,
      filterString: '',
      filterCategory: 'presenter_first_name',
      filtering: false,
      pageCountStart: 1,
      perPage: 12,
      totalPages: 1,
      page: 1,
      topicMap: null,
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'poster_id') {
          this.setState({
            posterSelected: true,
            posterId: param[1],
          });
        }
      }
    }

    this.startLiveNowCheck();
    this.getPosters();
    this.props.setBriefcase();

    const event = {
      action: 'Viewed',
      resource_type: 'Science As Art',
      resource_id: null,
      url_visited: '/scienceasart',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    trackAction(event);
  }

  startLiveNowCheck = () => {
    this.intervalID = setInterval(this.changeLiveNow, 60000); //check once a minute
  };

  changeLiveNow = () => {
    this.getPosters();
  };

  stopLiveNowCheck = () => {
    clearInterval(this.intervalID);
  };

  componentWillUnmount() {
    this.stopLiveNowCheck();
  }

  getPosters() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/posters/subset/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          params: {
            search_columns: 'accepted_type',
            search_value: 'Science as Art',
          },
        })
          .then((response) => {
            let authorizedPosters = [];

            for (let i = 0; i < response.data.length; i++) {
              let poster = response.data[i];
              if (poster.cleared_for_public_view === 'Yes - Approved') {
                authorizedPosters.push(poster);
              } else {
                if (
                  this.props.user.user.roles.includes('Attendee') ||
                  this.props.user.user.roles.includes('Pharma Attendee')
                ) {
                  authorizedPosters.push(poster);
                }
              }
            }
            this.setState({
              posters: authorizedPosters,
              authorizedPosters,
            });
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Poster Hall. ${error}`);
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display Poster Hall. ${error}`);
        }
      });
  }

  togglePosterFullscreenOn = (event, id) => {
    this.setState({
      posterSelected: true,
      posterId: id,
    });
  };

  togglePosterFullscreenOff = () => {
    this.setState({
      posterSelected: false,
      posterId: null,
    });
  };

  handleGridListToggle = () => {
    this.setState({ listFormat: !this.state.listFormat });
  };

  handleFilterClick = (string, category) => {
    let filteredPosters = this.state.posters.filter((poster) => {
      switch (category) {
        case 'title':
          if (poster.title.toLowerCase().includes(string.toLowerCase())) {
            return poster;
          }
          break;
        case 'topic':
          if (poster.topic.toLowerCase().includes(string.toLowerCase())) {
            return poster;
          }
          break;
        case 'presenter_first_name':
          let firstNameFound = false;
          poster.contacts.forEach((contact) => {
            if (
              contact.first_name.toLowerCase().includes(string.toLowerCase())
            ) {
              firstNameFound = true;
            }
          });
          if (firstNameFound) {
            return poster;
          }
          break;
        case 'presenter_last_name':
          let lastNameFound = false;
          poster.contacts.forEach((contact) => {
            if (
              contact.last_name.toLowerCase().includes(string.toLowerCase())
            ) {
              lastNameFound = true;
            }
          });
          if (lastNameFound) {
            return poster;
          }
          break;
        case 'presenter_title':
          let jobTitleFound = false;
          poster.contacts.forEach((contact) => {
            if (contact.title.toLowerCase().includes(string.toLowerCase())) {
              jobTitleFound = true;
            }
          });
          if (jobTitleFound) {
            return poster;
          }
          break;
        case 'presenter_company':
          let companyFound = false;
          poster.contacts.forEach((contact) => {
            if (
              contact.account_name.toLowerCase().includes(string.toLowerCase())
            ) {
              companyFound = true;
            }
          });
          if (companyFound) {
            return poster;
          }
          break;
        default:
          break;
      }

      return null;
    });
    this.setState({
      posters: filteredPosters,
      filterString: string,
      filterCategory: category,
      filtering: true,
      pageCountStart: 1,
      page: 1,
    });
  };

  handleRemoveFilter = () => {
    this.setState({
      filterString: '',
      filterCategory: '',
      filtering: false,
      pageCountStart: 1,
      page: 1,
      posters: [...this.state.authorizedPosters],
    });
    // this.getPosters();
  };

  handlePageChange = (event, value) => {
    this.setState({
      page: value,
      pageCountStart: value * this.state.perPage - (this.state.perPage - 1),
    });

    this.getPosters();
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      this.setState({
        posterSelected: false,
        posterIndex: null,
        posterId: null,
      });
      return false;
    }
  }

  handleClose() {
    this.setState({
      posterSelected: false,
      posterIndex: null,
      posterId: null,
    });
  }

  handleUpvote = (index) => {
    let items = [...this.state.posters];
    let item = { ...items[index] };
    if (item.liked === true) {
      item.count = item.count - 1;
      item.liked = false;
    } else {
      item.count = item.count + 1;
      item.liked = true;
    }
    items[index] = item;
    this.setState({ posters: items });
  };

  handleUpvotePosterFull = (posterId) => {
    let items = [...this.state.posters];
    items.forEach((item) => {
      if (item.abstract_id === posterId) {
        if (item.liked === true) {
          item.count = item.count - 1;
          item.liked = false;
        } else {
          item.count = item.count + 1;
          item.liked = true;
        }
      }
    });
    this.setState({ posters: items });
  };

  render() {
    const { classes } = this.props;

    let pageTitle = 'Science as Art';

    let sidebarOpen;
    if (this.props.sidebar.chatOpen || this.props.sidebar.open) {
      sidebarOpen = true;
    } else {
      sidebarOpen = false;
    }

    let pageBody;
    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );
    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <Typography variant="h4" color="textPrimary">
          No Results Found
        </Typography>
      </Grid>
    );
    let contentLoading = (
      <Grid
        container
        className={classes.gridContainer}
        layout={'row'}
        spacing={2}
      >
        <Grid item xs={12}>
          <Paper>
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="h4"
              color="textPrimary"
            >
              {pageTitle}
            </Typography>
          </Paper>
        </Grid>
        <Filter
          handleGridListToggle={this.handleGridListToggle.bind(this)}
          handleRemoveFilter={this.handleRemoveFilter.bind(this)}
          handleFilterClick={this.handleFilterClick.bind(this)}
          listFormat={this.state.listFormat}
          filterString={this.state.filterString}
          filterCategory={this.state.filterCategory}
          filtering={this.state.filtering}
        />
        {this.state.filtering && this.state.posters.length === 0
          ? noResultsFound
          : loadingGraphic}
      </Grid>
    );

    if (this.state.listFormat) {
      pageBody = (
        <Grid
          container
          className={classes.gridContainer}
          layout={'row'}
          spacing={2}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          <Filter
            handleGridListToggle={this.handleGridListToggle.bind(this)}
            handleRemoveFilter={this.handleRemoveFilter.bind(this)}
            handleFilterClick={this.handleFilterClick.bind(this)}
            listFormat={this.state.listFormat}
            filterString={this.state.filterString}
            filterCategory={this.state.filterCategory}
            filtering={this.state.filtering}
          />
          <Grid item xs={12}>
            {this.state.posters.map((poster, index) => {
              return (
                <PosterListItem
                  key={index}
                  index={index}
                  poster={poster}
                  toggleFullscreenHandlerOn={this.togglePosterFullscreenOn.bind(
                    this
                  )}
                  handleUpvote={() => this.handleUpvote(index)}
                />
              );
            })}
            <Dialog
              open={this.state.posterSelected}
              handleClose={this.handleClose.bind(this)}
              scroll="body"
            >
              {this.state.posters.length ? (
                <PosterFull
                  posterId={this.state.posterId}
                  allPosters={this.state.posters}
                  closeBooth={this.togglePosterFullscreenOff.bind(this)}
                  handleUpvote={() =>
                    this.handleUpvotePosterFull(this.state.posterId)
                  }
                />
              ) : null}
            </Dialog>
          </Grid>
        </Grid>
      );
    } else {
      pageBody = (
        <Grid
          container
          className={classes.gridContainer}
          layout={'row'}
          spacing={2}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          <Filter
            handleGridListToggle={this.handleGridListToggle.bind(this)}
            handleRemoveFilter={this.handleRemoveFilter.bind(this)}
            handleFilterClick={this.handleFilterClick.bind(this)}
            listFormat={this.state.listFormat}
            filterString={this.state.filterString}
            filterCategory={this.state.filterCategory}
            filtering={this.state.filtering}
          />
          {this.state.posters.map((poster, index) => {
            return (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={sidebarOpen ? 6 : 4}
                lg={sidebarOpen ? 4 : 3}
              >
                <PosterCard
                  topicMap={this.state.topicMap}
                  poster={poster}
                  index={index}
                  toggleFullscreenHandlerOn={this.togglePosterFullscreenOn.bind(
                    this
                  )}
                  handleUpvote={() => this.handleUpvote(index)}
                />
              </Grid>
            );
          })}
          <Dialog
            open={this.state.posterSelected}
            handleClose={this.handleClose.bind(this)}
            scroll="body"
          >
            {this.state.posters.length ? (
              <PosterFull
                posterId={this.state.posterId}
                allPosters={this.state.posters}
                closeBooth={this.togglePosterFullscreenOff.bind(this)}
                handleUpvote={(posterId) =>
                  this.handleUpvotePosterFull(posterId)
                }
              />
            ) : null}
          </Dialog>
        </Grid>
      );
    }

    let page = this.state.posters.length > 0 ? pageBody : contentLoading;

    return page;
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
    sidebar: state.sidebar,
    debug: state.debug,
    event: state.event,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(PosterSessions)));
