import { SET_AUTHENTICATED_USER } from './types';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { eventId } from '../../config';

export default function setAuthenticatedUser() {
  return (dispatch, getState) => {
    const items = getState().debug;

    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/show-attendees/${eventId}/${data.idToken.payload.email}`,
          headers: { idtoken: data.idToken.jwtToken },
        })
          .then((response) => {
            console.log(
              '🚀 ~ file: setUser.js ~ line 18 ~ .then ~ response',
              response
            );
            if (response.data.length) {
              dispatch(setAuthenticatedUserAsync(response.data[0]));
            } else {
              dispatch(setAuthenticatedUserAsync('unregistered'));
            }
          })
          .catch((error) => {
            if (items.debug) {
              alert(`Error: Could not get the user information. ${error}`);
            }
          });
      })
      .catch((error) => {
        if (items.debug) {
          alert(`Error: Could not update the user information. ${error}`);
        }
      });
  };
}

function setAuthenticatedUserAsync(user) {
  return {
    type: SET_AUTHENTICATED_USER,
    payload: user,
  };
}
