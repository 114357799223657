import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConversations, getChatWindow } from '../Store/Reducers/twilio';
import PersonIcon from '@material-ui/icons/Person';

import getTwilioUserIdentity from '../Utilities/getTwilioUserIdentity';
// Redux

const useStyles = (theme) => ({});

const MyEngagementZoneProfile = () => {
  // console.log(
  //   '🚀 ~ file: ChatProfile.js ~ line 11 ~ ChatProfile ~ identity',
  //   identity
  // );
  const dispatch = useDispatch();
  // const conversationsClient = useSelector(getConversationsClient);
  // const contactsClient = useSelector(getContactsClient);
  const isChatWindow = useSelector(getChatWindow);
  const me = useSelector((state) => state.user.user);
  const user = useSelector(
    (state) => state.twilio?.users?.[getTwilioUserIdentity(me)]
  );
  const conversations = useSelector(getConversations);

  let unreadCount = 0;

  Object.keys(conversations).forEach((key) => {
    if (conversations[key]?.uniqueName?.startsWith('GROUP_')) {
      if (conversations[key]?.myAttributes?.following) {
        unreadCount += conversations[key].unread || 0;
      }
    } else {
      unreadCount += conversations[key].unread || 0;
    }
  });

  return (
    <div
      style={{
        position: 'relative',
        cursor: 'pointer',
        // border: '1px solid red',
        margin: 3,
        width: 60,
        height: 60,
      }}
      onClick={() => {
        // dispatch(setOpenConversation(null));
        // if (isChatWindow ) {
        //   dispatch(setEngagement('profile'));
        //   dispatch(setOpenProfile(user));
        // }
      }}
    >
      <div style={{ ...styles.circle, width: 60, height: 60 }}>
        {!user?.attributes?.photo_url ? (
          <PersonIcon
            // className={classes.linePhoto}
            style={{
              width: 60,
              height: 60,
              background: '#CCCCCC',
              borderRadius: '50%',
            }}
          />
        ) : (
          <img
            src={user?.attributes?.photo_url}
            style={{ width: 60, height: 60, borderRadius: 50 }}
          />
        )}
      </div>

      {/* <div
        style={{
          minWidth: size >= 50 ? 17 : size > 20 ? 14 : 8,
          height: size >= 50 ? 17 : size > 20 ? 14 : 8,
          backgroundColor: user?.isOnline ? '#1DDD47' : 'white',
          borderRadius: 20,
          position: 'absolute',
          right: 0,
          // left: 40,
          bottom: -2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // padding: 5,
          color: 'white',
          border: user?.isOnline ? '2px solid #fff' : '2px solid #757575',
        }}
      ></div> */}
      {unreadCount > 0 && (
        <div
          style={{
            minWidth: 17,
            height: 17,
            backgroundColor: 'red',
            borderRadius: 20,
            position: 'absolute',
            right: 0,
            bottom: -2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 5,
            color: 'white',
            fontSize: 12,
            fontWeight: 'bold',
            //   border: user?.isOnline ? '2px solid #fff' : '2px solid #757575',
          }}
        >
          {unreadCount}
        </div>
      )}

      {/* {!isMe && user?.identity && (
        <button
          onClick={() => {
            dispatch(twilioCreateJoinOneOnOneConversation(user.identity));
          }}
        >
          start chat
          {user?.attributes?.photo_url}
        </button>
      )} */}
    </div>
  );
  // return (
  //   <div>
  //     <div>
  //       {isMe ? 'ME' : user?.identity} ({user?.isOnline ? 'online' : 'offline'})
  //     </div>
  // {!isMe && user?.identity && (
  //   <button
  //     onClick={() => {
  //       dispatch(twilioCreateJoinOneOnOneConversation(user.identity));
  //     }}
  //   >
  //     start chat
  //     {user?.attributes?.photo_url}
  //   </button>
  // )}
  //   </div>
  // );
};
const styles = {
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },

  circle: {
    borderRadius: 50,
    width: 50,
    height: 50,
    backgroundColor: '#b0b0b0',
    // border: '2px solid pink',
  },
};

export default MyEngagementZoneProfile;
