import React, { useEffect } from 'react';
import twilioInitialize from '../Store/Actions/twilioInitialize';
import { getInitilized } from '../Store/Reducers/twilio';
import { useDispatch, useSelector } from 'react-redux';
import Router from '../router';

export default function RootScreen() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const twilioInitialized = useSelector(getInitilized);
  // const location = useLocation();
  // console.log(location);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(twilioInitialize(user));
      } catch (err) {
        console.log(err.message);
      }
    })();
  }, [user]);

  return <Router />;
}
