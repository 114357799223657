import React from 'react';
import { makeStyles } from '@material-ui/core/Styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import ScienceShared from '../../Assets/LandingPageImages/ScienceShared.png';
import PosterHall from '../../Assets/LandingPageImages/PosterHall.png';

const useStyles = makeStyles((theme) => ({}));

const Security = () => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        layout={'row'}
        spacing={0}
        justify="center"
        align="center"
        style={{ marginTop: '60px' }}
      >
        <Grid item xs={12}>
          <img
            src={ScienceShared}
            alt="Science Shared"
            style={{
              width: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
              position: 'relative',
              padding: 0,
              margin: 0,
              // top: '-4px',
            }}
          />
          <br />
          <br />
        </Grid>
      </Grid>
      <Container>
        <Grid
          container
          layout={'row'}
          spacing={0}
          justify="center"
          align="center"
          style={{ marginTop: '5vw', marginBottom: '5vw' }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            style={{ paddingLeft: '2vw', paddingRight: '2vw' }}
          >
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              style={{ color: '#122857' }}
            >
              <b>A Poster Hall Built by Scientists, For Scientists</b>
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              align="justify"
              style={{ color: '#122857', paddingRight: '40px' }}
            >
              <b>
                <ul>
                  <li>Abstract submission and voting process</li>
                  <li>Poster presenters able to deliver live presentations</li>
                  <li>
                    Supplier partners able to present in a collaborative
                    scientific setting
                  </li>
                  <li>Related poster algorithm by subject matter type</li>
                  <li>Reporting by topic to gauge attendees' interests in</li>
                </ul>
              </b>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            style={{ paddingLeft: '2vw', paddingRight: '2vw' }}
          >
            <img
              src={PosterHall}
              alt="Poster Hall"
              style={{
                width: '100%',
                marginLeft: 'auto',
                marginRight: '0px',
                display: 'block',
                position: 'relative',
                padding: 0,
                margin: 0,
                // top: '-4px',
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          layout={'row'}
          spacing={0}
          justify="center"
          align="center"
          // style={{
          //   marginTop: '10vw',
          //   marginBottom: '5vw',
          //   backgroundColor: '#122857',
          // }}
        >
          <Grid
            item
            xs={12}
            // style={{ padding: '7vw 0vw' }}
          >
            <Container>
              {/* <Typography
                variant="h4"
                align="center"
                style={{ color: '#122857' }}
              >
                <b>
                  "They Made it so Easy for us to Pivot to a Virtual Meeting..."
                </b>
              </Typography>
              <br />
              <br /> */}
              <Typography
                variant="h6"
                align="center"
                style={{ color: '#122857' }}
              >
                <b>
                  "We want to thank the PlanetConnect team for your support in
                  the preparation work for setting up the platform communication
                  and poster ranking tools, but also for the on time delivery of
                  all daily data and fast responses to our requests."
                </b>
              </Typography>
              <br />
              <br />
              <Typography
                variant="h5"
                align="center"
                style={{ color: '#122857' }}
              >
                <b>- Mark V.</b> <br />
                Associate Director Global Pharmatechnology
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Security;
