import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getConversationsClient,
  setOpenConversation,
  setOpenProfile,
  setEngagement,
  setChatWindow,
} from '../Store/Reducers/twilio';
import getTwilioUserIdentity from '../Utilities/getTwilioUserIdentity';
import twilioCreateJoinOneOnOneConversation from '../Store/Actions/twilioCreateJoinOneOnOneConversation';
import ChatProfile from './ChatProfile';
import { colors } from '../config';
import PersonIcon from '@material-ui/icons/Person';
import twilioSubscribeToIdentity from '../Store/Actions/twilioSubscribeToIdentity';

// Redux

const useStyles = (theme) => ({});

const ChatProfileRow = ({ profileUser, page }) => {
  // console.log(
  //   '🚀 ~ file: ChatProfileRow.js ~ line 20 ~ ChatProfileRow ~ identity',
  //   identity
  // );
  const identity = profileUser.identity || profileUser.email;

  const dispatch = useDispatch();
  const conversationsClient = useSelector(getConversationsClient);
  const me = useSelector((state) => state.user.user);
  const user = useSelector((state) => state.twilio?.users?.[identity]);

  const [isCreatingChat, setIsCreatingChat] = useState(false);
  // console.log(
  //   '🚀 ~ file: ChatProfileRow.js ~ line 31 ~ ChatProfileRow ~ isCreatingChat',
  //   isCreatingChat
  // );
  useEffect(() => {
    if (!user || user.identity !== identity) {
      if (!user?.isSubscribed) {
        dispatch(twilioSubscribeToIdentity(identity));
        // // console.log(`subscribing to ${identity}`);
        // conversationsClient
        //   .getUser(identity)
        //   .then((subscribedUser) => {
        //     // setUser(subscribedUser);
        //   })
        //   .catch((error) => {
        //     // console.log(
        //     //   '🚀 ~ file: ChatProfile.js ~ line 82 ~ useEffect ~ error',
        //     //   error
        //     // )
        //   });
      }
    }
  }, [identity, user]);
  const isMe = user && me && getTwilioUserIdentity(me) === user.identity;
  const onHover = `${user?.attributes?.first_name || profileUser?.first_name} ${
    user?.attributes?.last_name || profileUser?.last_name
  } ${user?.attributes?.title || profileUser?.title || ''}\n${
    user?.attributes?.account_name || profileUser?.account_name
  }`;

  const bgColor = () => {
    // let roles = user?.attributes.roles?.split(',')?.at(-1).toLowerCase();
    let roles = user?.attributes.roles?.toLowerCase();

    if (roles) {
      if (roles.includes('attendee')) {
        return '#c0f0c5';
      } else {
        return '#f5afae';
      }
    }
    return '';
  };

  return (
    <div
      style={{ ...styles.row, backgroundColor: page === 'chat' && bgColor() }}
      onClick={async (e) => {
        e.stopPropagation();

        if (user && !isMe && !isCreatingChat) {
          setIsCreatingChat(true);
          const conv = await dispatch(
            twilioCreateJoinOneOnOneConversation(identity)
          );
          dispatch(setOpenProfile(null));
          dispatch(setEngagement('chat'));
          dispatch(setChatWindow(true));
          // dispatch(setOpenConversation({ page, conversation: conv }));
          setIsCreatingChat(false);
        }

        // dispatch(setOpenConversation(null));
        // dispatch(setOpenProfile(user));
        // dispatch(setEngagement('profile'));
        // dispatch(setChatWindow(true));
      }}
    >
      <ChatProfile
        identity={identity}
        size={50}
        photo_url={profileUser.photo_url}
        directory={undefined}
        onClick={undefined}
      />
      {/* <a title={onHover}> */}
      <a
        title={onHover}
        style={{ padding: 10, textAlign: 'left', width: '85%' }}
      >
        <div style={{ fontSize: 18, fontWeight: 'bold', color: colors.main }}>
          {user?.attributes.first_name || profileUser.first_name}{' '}
          {user?.attributes.last_name || profileUser.last_name}
        </div>
        {/* <a title={user?.attributes.title}> */}
        <div
          style={{
            fontSize: 14,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%',
            lineHeight: '15px',
          }}
        >
          {user?.attributes?.title || profileUser.title}
        </div>

        {/* {user &&
            user?.attributes.title !== '' &&
            user?.attributes.account_name !== '' &&
            ', '}{' '}
          {user?.attributes.account_name} */}
        {/* </a> */}
        {/* <a title={user?.attributes.account_name}> */}
        <div
          style={{
            fontSize: 14,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%',
            lineHeight: '15px',
          }}
        >
          {user?.attributes?.account_name || profileUser?.account_name}
        </div>
        {/* </a> */}
        {/* </div> */}
      </a>
    </div>
  );
};
const styles = {
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 10,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },

  circle: {
    borderRadius: 50,
    width: 50,
    height: 50,
    backgroundColor: '#b0b0b0',
    // border: '2px solid pink',
  },
};

export default ChatProfileRow;
