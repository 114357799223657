import { SET_CHATBAR } from './types';

export default function setChatbar(chatBarState) {
  return (dispatch) => {
    dispatch(setChatbarAsync(chatBarState));
  };
}

function setChatbarAsync(chatBarState) {
  return {
    type: SET_CHATBAR,
    payload: chatBarState,
  };
}
