import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Container } from '@material-ui/core';

// redux
import { connect } from 'react-redux';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
});

class Settings extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid container layout={'row'} spacing={2} className={classes.root}>
        <Paper>
          <Grid item xs={12}>
            <img
              src={require('../Assets/ArchiveLogo.jpg')}
              alt="Lobby"
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
              }}
            />
            {/* <Typography style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }} variant='h4' align='center' color='textPrimary'>
            Merck Archives
            </Typography> */}
          </Grid>
          <Container>
            <br />
            <br />
            <br />
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="body1"
              align="center"
              color="textPrimary"
            >
              As we celebrate the 25th anniversary of the Merck Tech Symposium,
              there's never been a better time to get inspired by our company's
              remarkable past. Stop by the Merck Archival Services booth to
              enjoy two talks by an MSD historian, see the results of our annual
              Rare Find Competition and become a detective in our Digital
              Scavenger Hunt.
            </Typography>
            <br />
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="h4"
              align="center"
              color="textPrimary"
            >
              There's still time to join the Hunt!
            </Typography>
            <br />
            <br />
            <Grid container layout={'row'} spacing={2} className={classes.root}>
              <Grid item xs={12} md={12} lg={6}>
                <Typography
                  style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                  variant="body1"
                  align="center"
                  color="textPrimary"
                >
                  Calling all super sleuths! Merck Archival Services needs your
                  help with a Hunt for History. As part of the 2021 Tech
                  Symposium, we’re hosting a Digital Scavenger Hunt from
                  September 15–22. Follow the clues to uncover a secret password
                  hidden within the{' '}
                  <a
                    style={{ textDecoration: 'none' }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://merck.netx.net/portals/merck/#"
                  >
                    Merck Digital Archive
                  </a>
                  , where participation details will be posted beginning on
                  September 15. For any questions, please contact us at
                  archives@merck.com. Happy hunting!
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <img
                  alt="microscopist"
                  style={{
                    borderRadius: '5%',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '60%',
                  }}
                  src={require('../Assets/PerkinElmer_Logo.png')}
                />
              </Grid>
            </Grid>
            <br />
            <br />
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="body1"
              align="center"
              color="textPrimary"
            >
              For more information, visit the Merck Digital Archives, featuring
              more than 150,000 photos, publications, speeches and more that
              represent over 130 years of company history. Come explore anytime!
            </Typography>
            <br />
            <br />
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="body1"
              align="center"
              color="textPrimary"
            >
              Related Link:{' '}
              <a
                href="https://merck.netx.net/portals/merck/#"
                style={{ textDecoration: 'none' }}
                rel="noopener noreferrer"
                target="_blank"
              >
                https://merck.netx.net/portals/merck/#
              </a>
            </Typography>
          </Container>
          <br />
          <br />
          <br />
          <img
            alt="microscopist"
            style={{
              borderRadius: '5%',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '60%',
            }}
            src={require('../Assets/Microscopist.png')}
          />
          PerkinElmer_Logo
          <br />
          <br />
        </Paper>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    debug: state.debug,
    event: state.event,
  };
}

export default connect(mapStateToProps, null)(withStyles(useStyles)(Settings));
