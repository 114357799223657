import { SET_SIDEBAR } from './types';

export default function setSidebar(sideBarState) {
  return (dispatch) => {
    dispatch(setSidebarAsync(sideBarState));
  };
}

function setSidebarAsync(sideBarState) {
  return {
    type: SET_SIDEBAR,
    payload: sideBarState,
  };
}
