import getTwilioUserIdentity from '../../Utilities/getTwilioUserIdentity';
import { getHereNow, getHereNowClient } from '../Reducers/twilio';

export default function twilioAddHereNow(conversationSid) {
  return async (dispatch, getState) => {
    const user = getState().user.user;
    // const conversationsClient = getState()Client
    const hereNowClient = getHereNowClient(getState());
    const hereNow = getHereNow(getState()) || {};

    if (user?.email && hereNowClient) {
      const newHereNow = { ...hereNow };
      const newHereNowRoom = newHereNow[conversationSid]
        ? { ...newHereNow[conversationSid] }
        : {};
      newHereNowRoom[user.email] = getTwilioUserIdentity(user);
      newHereNow[conversationSid] = newHereNowRoom;
      // console.log('ADDED newHereNowRoom', newHereNow, newHereNowRoom);
      await hereNowClient.set(newHereNow);
    }
  };
}
