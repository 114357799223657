import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import HomeIcon from '@material-ui/icons/Home';
import BlockIcon from '@material-ui/icons/Block';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import ChatProfile from './ChatProfile';
import ChatProfileRow from './ChatProfileRow';
import {
  setOpenConversation,
  getUsers,
  setChatWindow,
} from '../Store/Reducers/twilio';
import ChatItem from '../Containers/ChatItem';
import twilioLoadOpenConversation from '../Store/Actions/twilioLoadOpenConversation';
import twilioBlockUser from '../Store/Actions/twilioBlockUser';
import twilioLoadPreviousMessages from '../Store/Actions/twilioLoadPreviousMessages';
import ChatConversationHereNow from './ChatConversationHereNow';

import { colors, conversationsServiceSid } from '../config';
import backArrow from '../Assets/Icons/backArrow.png';
import dots from '../Assets/Icons/dots.png';
import getTwilioUserIdentity from '../Utilities/getTwilioUserIdentity';
import leave from '../Assets/Icons/leave.png';
import sortParticipantsByLastName from '../Utilities/sortParticipantsByLastName';

const ChatConversation = forwardRef(({ conversationSid, page }, ref) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  // console.log('🚀 ~ file: ChatConversation.js ~ line 43 ~ user', user);
  // const userTwilio = useSelector((state) => state.twilio.user?.client);
  const users = useSelector(getUsers);

  const openConversation = useSelector(
    (state) => state.twilio.openCoversation?.[page]
  );
  const conversation = useSelector(
    (state) => state.twilio.conversations?.[conversationSid]
  );
  const messages = useSelector(
    (state) => state.twilio.conversationsMessages?.[conversationSid]
  );

  // console.log(
  //   '🚀 ~ file: ChatConversation.js ~ line 44 ~ ChatConversation ~ openConversation',
  //   openConversation
  // );

  const exhibitionId = conversation?.client?.attributes?.exhibitionId;
  const abstractId = conversation?.client?.attributes?.abstractId;

  const admins = conversation?.attributes?.admins || [];
  const participants = conversation?.participants
    ? Object.keys(conversation.participants).map(
        (identity) => conversation.participants[identity]
      )
    : [];
  participants.sort(sortParticipantsByLastName(users));

  const myParticipant =
    conversation?.participants?.[getTwilioUserIdentity(user)];

  // const contactsClient = useSelector(getContactsClient);
  let scrollDiv = useRef(null);
  // console.log('🚀 ~ file: ChatConversation.js ~ line 50 ~ users', users);
  // const contacts = useSelector((state) => state.twilio?.contacts);

  const isGroup = conversation?.client?.uniqueName?.startsWith('GROUP_');
  // console.log('🚀 ~ file: ChatConversation.js ~ line 53 ~ isGroup', isGroup);

  const [loading, setLoading] = useState(false);
  // const [typing, setTyping] = useState([]);
  // const [participants, setParticipants] = useState([]);

  // const [messages, setMessages] = useState(openConversationMessages);
  // console.log(
  //   '🚀 ~ file: ChatConversation.js ~ line 60 ~ messages',
  //   messages.length
  // );
  // const [conversation, setConversation] = useState(null);
  const [text, setText] = useState('');
  const [isPopUp, setIsPopUp] = useState(null);
  const [showAllParticipants, setShowAllParticipants] = useState(false);
  const [isMuted, setIsMuted] = useState('');
  const [attributes, setAttributes] = useState({});
  // const [isActive, setIsActive] = useState(null);
  const [scrollToId, setScrollToId] = useState(null);

  const isUserAdmin = conversation?.attributes?.admins?.find(
    (ele) => ele.email && ele.email === user?.email
  );

  useImperativeHandle(ref, () => ({
    toggleShowAllParticipants() {
      setShowAllParticipants(!showAllParticipants);

      // setIsActive(activeUsers);
    },
  }));

  useEffect(() => {
    (async () => {
      try {
        await dispatch(twilioLoadOpenConversation(page, conversationSid));
        scrollToBottom();
      } catch (err) {
        console.log(`load conversation error ${err.message}`);
      }
    })();
  }, [conversationSid]);

  useEffect(() => {
    (async () => {
      if (conversation?.client) {
        try {
          const ele = document.getElementById(scrollToId || 'NOT_AN_ELEMENT');
          if (scrollToId && ele) {
            // console.log(`scrolling to ${scrollToId}`);
            ele.scrollIntoView(true);
            setScrollToId(null);
          } else {
            // if (!scrollToId) {
            // console.log(`scrolling to bottom`);
            scrollToBottom();
          }
          // console.log('marking all as read');
          // Anytime we receive a new message, mark them as read
          await conversation.client.setAllMessagesRead();

          // }
        } catch (err) {
          console.log(
            '🚀 ~ file: ChatConversation.js ~ line 304 ~ err marking messages read',
            err
          );
        }
      }
    })();
  }, [messages]);

  // const participantCount = participants.length;
  // useEffect(() => {
  //   if (!isGroup && participantCount === 1) {
  //     // this happens when a user is blocked.
  //     dispatch(setOpenConversation({ page, conversation: null }));
  //   }
  // }, [isGroup, participantCount]);

  const updateText = (e) => setText(e);

  const scrollToBottom = () => {
    if (scrollDiv.current) {
      const scrollHeight = scrollDiv.current.scrollHeight;
      const height = scrollDiv.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  const sendMessage = async () => {
    if (!loading && text) {
      if (!conversation?.myAttributes?.joined) {
        if (conversation?.participants?.[getTwilioUserIdentity(user)])
          conversation.participants[
            getTwilioUserIdentity(user)
          ].client.updateAttributes({
            following: true,
          });
      }
      setLoading(true);
      // Run sync
      const messageIndex = await conversation.client.sendMessage(
        String(text).trim()
      );
      setText('');
      setLoading(false);
    }
  };
  const isDisplayTime = (message, idx) => {
    if (idx !== 0) {
      if (messages.messages[idx - 1]?.author === message.author) {
        const time1 = moment(messages.messages[idx - 1]?.dateCreated);
        const time2 = moment(message?.dateCreated);
        var duration = moment.duration(time2.diff(time1));

        var minutes = duration.minutes();
        return minutes >= 2;
      }
      return true;
    } else {
      return true;
    }
  };
  const isChangeMessageDate = (message, idx) => {
    if (idx !== 0) {
      if (
        !moment(messages.messages[idx - 1]?.dateCreated).isSame(
          message?.dateCreated,
          'day'
        )
      ) {
        return true;
      }

      return false;
    }
    return true;
  };

  if (openConversation?.error)
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'relative',
          flex: 1,
        }}
      >
        <div style={styles.chatRoot}>
          <div style={styles.chatContainer} ref={scrollDiv}>
            <div
              style={{
                height: 333,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {conversation.error}
            </div>
          </div>
        </div>
      </div>
    );

  if (!conversation || openConversation?.loading)
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'relative',
          flex: 1,
        }}
      >
        <div style={styles.chatRoot}>
          <div style={styles.chatContainer} ref={scrollDiv}>
            <div
              style={{
                height: 333,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          </div>
        </div>
      </div>
    );

  if (!isGroup && participants.length < 2) return null;

  if (showAllParticipants && isGroup && isUserAdmin) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'relative',
          backgroundColor: colors.backgroundGray,
          flex: 1,
          borderBottomRightRadius: 20,
          borderBottomLeftRadius: 20,
          height: '100%',
          // backgroundColor: 'green',
        }}
      >
        <div
          style={{
            width: '100%',
            height: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            position: 'relative',
            padding: '10px 10px ',
            backgroundColor: 'white',
            boxShadow: '0 4px 15px -7px #000',
            // zIndex: 100000,
          }}
        >
          <a
            title={'Back'}
            onClick={() => {
              setShowAllParticipants(false);
              // setIsActive(false);
            }}
            style={{ position: 'absolute', left: 15, cursor: 'pointer' }}
          >
            <img
              src={backArrow}
              style={{ width: 12, height: 20 }}
              alt="back arrow"
            />
          </a>
          <div
            style={{
              width: '75%',
              textAlign: 'center',
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 18,
            }}
          >
            {!isGroup && 'Chat Participants'}
          </div>
        </div>
        <ChatConversationHereNow conversation={conversation} />
      </div>
    );
  }
  if (showAllParticipants) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'relative',
          backgroundColor: colors.backgroundGray,
          flex: 1,
          borderBottomRightRadius: 20,
          borderBottomLeftRadius: 20,
          height: '100%',
          // backgroundColor: 'green',
        }}
      >
        <div
          style={{
            width: '100%',
            height: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            position: 'relative',
            padding: '10px 10px ',
            backgroundColor: 'white',
            boxShadow: '0 4px 15px -7px #000',
            // zIndex: 100000,
          }}
        >
          <a
            title={'Back'}
            onClick={() => {
              setShowAllParticipants(false);
              // setIsActive(false);
            }}
            style={{ position: 'absolute', left: 15, cursor: 'pointer' }}
          >
            <img
              src={backArrow}
              style={{ width: 12, height: 20 }}
              alt="back arrow"
            />
          </a>
          <div
            style={{
              width: '75%',
              textAlign: 'center',
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 18,
            }}
          >
            {!isGroup && 'Chat Participants'}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            // height: 500,
            width: '100%',
            height: '100%',

            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <div style={styles.chatRoot}>
            {isGroup && admins && (
              <div
                style={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  color: colors.main,
                }}
              >
                {abstractId &&
                  (admins.length === 1
                    ? 'Poster Presenter'
                    : 'Poster Presenters')}
                {exhibitionId && 'Booth Staff'}
              </div>
            )}
            {isGroup &&
              admins &&
              admins.map((participant, index) => (
                <div
                  key={`participantsContents_${conversation.sid}_${participant.sid}`}
                >
                  <ChatProfileRow profileUser={participant} page={undefined} />
                </div>
              ))}
            {isGroup && isUserAdmin && (
              <div
                style={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  color: colors.main,
                }}
              >
                Participants
              </div>
            )}
            {isGroup &&
              isUserAdmin &&
              participants &&
              participants
                .filter((participant) => {
                  // Don't admins
                  for (let i = 0; i < admins.length; i++) {
                    if (participant.identity === admins[i].identity) {
                      return false;
                    }
                  }
                  return true;
                })
                .map((participant, index) => (
                  <div
                    key={`participantsContents_${conversation.sid}_${participant.sid}`}
                  >
                    <ChatProfileRow
                      profileUser={participant}
                      page={undefined}
                    />
                  </div>
                ))}
            {!isGroup &&
              participants &&
              participants
                .filter((participant) => {
                  // Don't show myself
                  return getTwilioUserIdentity(user) !== participant.identity;
                })
                .map((participant, index) => (
                  <div
                    key={`participantsContents_${conversation.sid}_${participant.sid}`}
                  >
                    <ChatProfileRow
                      profileUser={participant}
                      page={undefined}
                    />
                  </div>
                ))}
          </div>
        </div>
      </div>
    );
  }

  const notify = myParticipant?.attributes?.notify || 'FIRST';

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        flex: 1,
      }}
    >
      <div style={styles.chatRoot}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: page === 'poster' ? 'flex-start' : 'space-between',
            flexDirection: 'row',
            position: 'relative',
            padding: '15px 10px ',
            backgroundColor: 'white',
            boxShadow: '0 4px 7px -7px #000',
            zIndex: 1100,
            height: 76,
          }}
        >
          {page === 'sidebar' && (
            <a
              title={'Back to Home Page'}
              onClick={() => {
                dispatch(setOpenConversation({ page, conversation: null }));
              }}
              style={{ cursor: 'pointer' }}
            >
              <HomeIcon style={{ width: 20, height: 20, color: colors.main }} />
            </a>
          )}
          {page === 'chat' && (
            <div style={{ cursor: 'pointer', width: 4 }}></div>
          )}
          {isGroup ? (
            <a
              style={{
                maxWidth: '75%',
              }}
              title={conversation?.friendlyName}
            >
              {page !== 'poster' && (
                <div
                  style={{
                    maxWidth: '100%',
                    textAlign: 'center',
                    // border: '1px solid green',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  onClick={
                    exhibitionId || abstractId
                      ? () => {
                          // console.log('conversation', conversation?.attributes);
                          if (exhibitionId) {
                            history.push(
                              `/exhibithall/?exhibition_id=${exhibitionId}`
                            );
                          }
                          if (abstractId) {
                            history.push(
                              `/posterhall/?poster_id=${abstractId}`
                            );
                          }
                          dispatch(setChatWindow(false));
                        }
                      : undefined
                  }
                >
                  {conversation?.friendlyName}

                  {/* {admins &&
                    admins.map((participant, index) => {
                      if (index < 4) {
                        return (
                          <div
                            key={`adminsContents_${conversation.sid}_${participant.sid}`}
                          >
                            <ChatProfile
                              identity={participant.identity}
                              size={32}
                            />
                          </div>
                        );
                      }
                    })}
                  {admins?.length > 4 && (
                    <div
                      style={{ marginLeft: 5, fontSize: 16, color: '#122857' }}
                    >
                      +{admins.length - 4}
                    </div>
                  )} */}
                </div>
              )}
              {page === 'poster' && (
                <div
                  style={{
                    maxWidth: '100%',
                    textAlign: 'center',
                    // border: '1px solid green',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAllParticipants(true);
                  }}
                  // onClick={() => setShowAllParticipants(true)}
                >
                  {abstractId &&
                    (admins.length === 1
                      ? 'Poster Presenter: '
                      : 'Poster Presenters: ')}
                  {exhibitionId && 'Booth Staff: '}
                  {admins &&
                    admins.map((participant, index) => {
                      if (index < 4) {
                        return (
                          <div
                            key={`adminsContents_${conversation.sid}_${participant.sid}`}
                          >
                            <ChatProfile
                              identity={participant.identity}
                              size={32}
                              onClick={() => {
                                setShowAllParticipants(true);
                              }}
                              photo_url={participant.photo_url}
                              directory={undefined}
                            />
                          </div>
                        );
                      }
                    })}
                  {admins?.length > 4 && (
                    <div
                      style={{
                        marginLeft: 5,
                        fontSize: 16,
                        color: '#122857',
                      }}
                    >
                      +{admins.length - 4}
                    </div>
                  )}
                </div>
              )}
            </a>
          ) : (
            <div
              style={{
                width: '75%',
                textAlign: 'center',
                // border: '1px solid green',
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => setShowAllParticipants(true)}
            >
              {participants &&
                participants
                  .filter((participant) => {
                    // console.log(
                    //   '🚀 ~ file: ChatConversation.js ~ line 639 ~ .filter ~ participant.identity',
                    //   participant.identity
                    // );
                    // Don't show myself
                    if (getTwilioUserIdentity(user) === participant.identity) {
                      return false;
                    } else {
                      return true;
                    }
                    // if (isAdmin(userTwilio, exhibitionId, abstractId)) {
                    //   // If the viewing user is an admin, show all users
                    //   return true;
                    // } else {
                    //   // If the viewing user is not an admin, only show admins
                    //   return isAdmin(users?.[participant.identity], abstractId);
                    // }
                  })
                  .map((participant, index) => (
                    <div
                      key={`participantsContents_${conversation.sid}_${participant.sid}`}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        // justifyContent: 'center',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                      }}
                    >
                      <ChatProfile
                        identity={participant.identity}
                        size={32}
                        photo_url={undefined}
                        directory={undefined}
                        onClick={undefined}
                      />
                      <a
                        title={
                          users?.[participant.identity]?.attributes.first_name +
                          ' ' +
                          users?.[participant.identity]?.attributes.last_name +
                          '\n' +
                          users?.[participant.identity]?.attributes.title +
                          '\n' +
                          users?.[participant.identity]?.attributes.account_name
                        }
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          marginLeft: 10,
                          flex: 1,
                          textAlign: 'left',
                        }}
                      >
                        <div
                          style={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            color: colors.main,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            width: '100%',
                          }}
                        >
                          {users?.[participant.identity]?.attributes.first_name}{' '}
                          {users?.[participant.identity]?.attributes.last_name}
                        </div>
                        <div
                          style={{
                            fontSize: 14,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              fontSize: 14,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              width: '100%',
                              lineHeight: '15px',
                            }}
                          >
                            {users?.[participant.identity]?.attributes.title}
                          </div>
                          <div
                            style={{
                              fontSize: 14,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              width: '100%',
                              lineHeight: '15px',
                            }}
                          >
                            {
                              users?.[participant.identity]?.attributes
                                .account_name
                            }
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
            </div>
          )}

          {page !== 'poster' && (
            <a
              title={'Settings'}
              style={{
                cursor: 'pointer',
                paddingRight: 5,
                marginRight: 10,
              }}
              onClick={(e) => {
                setIsPopUp(e.shiftKey ? 'debug' : 'open');
              }}
            >
              <img src={dots} style={{ width: 4, height: 16 }} />
            </a>
          )}
        </div>
        <div style={styles.chatContainer} ref={scrollDiv}>
          <div style={{ height: 333 }}>
            {messages?.hasPrevPage && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <button
                  onClick={async () => {
                    const firstMessageSid = messages.items[0].sid;
                    setScrollToId(firstMessageSid);
                    await dispatch(
                      twilioLoadPreviousMessages({
                        page,
                      })
                    );
                  }}
                >
                  Load More Messages
                </button>
              </div>
            )}

            <div className="chatContents">
              {messages?.messages &&
                messages.messages.map((message, index) => (
                  <div key={message.sid}>
                    {isChangeMessageDate(message, index) && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: 5,
                        }}
                      >
                        <hr
                          style={{
                            width: '-webkit-fill-available',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                          }}
                        />
                        <div
                          style={{
                            padding: 6,
                            borderRadius: 50,
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            whiteSpace: 'nowrap',
                            fontSize: 12,
                          }}
                        >
                          {moment(message.dateCreated).format('dddd, MMM Do')}
                        </div>
                        <hr
                          style={{
                            width: '-webkit-fill-available',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                          }}
                        />
                      </div>
                    )}
                    <ChatItem
                      key={`chatItem_${message.sid}`}
                      message={message}
                      email={user.email}
                      time={isDisplayTime(message, index)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="chatFooter" style={styles.chatFooter}>
          <div style={{ height: 131 }}>
            <div
              style={{
                padding: 5,
                flexDirection: 'row',
                display: 'flex',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                height: 30,
              }}
            >
              {openConversation?.typing &&
                Object.keys(openConversation.typing).map(
                  (typingUser, index) => {
                    // console.log(
                    //   '🚀 ~ file: ChatConversation.js ~ line 484 ~ {typing.map ~ typingUser',
                    //   typingUser
                    // );
                    // console.log(
                    //   '🚀 ~ file: ChatConversation.js ~ line 488 ~ {typing.map ~ users',
                    //   users?.[typingUser]
                    // );

                    return (
                      <div
                        key={`typing_${typingUser}`}
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          height: 30,
                          paddingLeft: 5,
                          paddingRight: 5,
                        }}
                      >
                        {users?.[typingUser]?.attributes?.first_name}
                        {/* {typing.length > 1 && typing.length - 1 !== index && ', '} */}
                        {Object.keys(openConversation.typing).length - 1 ===
                        index
                          ? ' is typing'
                          : ', '}
                      </div>
                    );
                  }
                )}
              {/* {!Object.keys(typing).length && '&nbsp;'} */}

              {/* {users && <div>{typing}</div>} : {typing.join(', ')} */}
            </div>

            <input
              type="text"
              placeholder="Type Message"
              onChange={(e) => {
                // tell the client the user is typing
                // it is debounded within the client
                console.log('typng!');
                conversation.client.typing();
                updateText(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  sendMessage();
                }
              }}
              value={text}
              style={{
                width: '90%',
                padding: 10,
                marginLeft: 10,
                marginRight: 10,
                borderRadius: 5,
                border: '1px solid grey',
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                width: '100%',
                paddingRight: 15,
                marginTop: 10,
              }}
            >
              {/* <button
                // onClick={sendMessage}
                style={{
                  borderRadius: 20,
                  backgroundColor: '#122857',
                  color: 'white',
                  padding: 8,
                  border: '0px solid grey',
                  marginRight: 5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 35,
                  height: 35,
                }}
              >
                <img src={calendar} style={{ width: 18, height: 20 }} />
              </button>
              <button
                // onClick={sendMessage}
                style={{
                  borderRadius: 20,
                  backgroundColor: '#122857',
                  color: 'white',
                  padding: 8,

                  border: '0px solid grey',
                  marginRight: 5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 35,
                  height: 35,
                }}
              >
                <img src={mic} style={{ width: 14, height: 19 }} />
              </button> */}
              <button
                onClick={sendMessage}
                style={{
                  borderRadius: 20,
                  backgroundColor: '#122857',
                  color: 'white',
                  padding: 8,
                  border: '0px solid grey',
                  fontSize: 16,
                  paddingLeft: 16,
                  paddingRight: 16,
                  cursor: 'pointer',
                }}
              >
                Send
              </button>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>

      {isPopUp && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'rgba(0,0,0,0.2)',
            flex: 1,
            borderBottomRightRadius: page !== 'chat' && 20,
            borderBottomLeftRadius: page !== 'chat' && 20,
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            left: 0,
            zIndex: 100000,

            // backgroundColor: 'green',
          }}
          onClick={() => setIsPopUp(null)}
        >
          <div
            style={{
              position: 'absolute',
              right: 15,
              top: 30,
              width: 'fit-content',
              backgroundColor: 'white',
              // border: '1px solid purple',
              padding: 5,
              boxShadow: '0 4px 15px -7px #000',
              borderRadius: 5,
              justifyContent: 'center',
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                fontSize: 16,
                width: '90%',
                margin: 5,
                // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              }}
            >
              Notify On:
            </div>
            <div
              style={styles.settings}
              onClick={async (e) => {
                // e.stopPropagation();
                // await conversation.client.setUserNotificationLevel('default');
                console.log(
                  '🚀 ~ file: ChatConversation.js ~ line 1060 ~ onClick={ ~ myParticipant',
                  myParticipant
                );
                if (myParticipant) {
                  myParticipant.client.updateAttributes({
                    ...attributes,
                    notify: 'ALL',
                  });
                  conversation.client.setUserNotificationLevel('default');
                }
              }}
            >
              {notify === 'ALL' ? (
                <RadioButtonCheckedIcon style={{ marginRight: 10 }} />
              ) : (
                <RadioButtonUncheckedIcon style={{ marginRight: 10 }} />
              )}
              Every Message
            </div>
            <div
              style={styles.settings}
              onClick={async (e) => {
                // e.stopPropagation();
                // await conversation.client.setUserNotificationLevel('default');
                if (myParticipant) {
                  myParticipant.client.updateAttributes({
                    ...attributes,
                    notify: 'FIRST',
                  });
                  conversation.client.setUserNotificationLevel('default');
                }
              }}
            >
              {notify === 'FIRST' ? (
                <RadioButtonCheckedIcon style={{ marginRight: 10 }} />
              ) : (
                <RadioButtonUncheckedIcon style={{ marginRight: 10 }} />
              )}
              Next Unread Message
            </div>
            <div
              style={styles.settings}
              onClick={async (e) => {
                // e.stopPropagation();
                // await conversation.client.setUserNotificationLevel('default');
                if (myParticipant) {
                  myParticipant.client.updateAttributes({
                    ...attributes,
                    notify: 'NONE',
                  });
                  conversation.client.setUserNotificationLevel('muted');
                }
              }}
            >
              {notify === 'NONE' ? (
                <RadioButtonCheckedIcon style={{ marginRight: 10 }} />
              ) : (
                <RadioButtonUncheckedIcon style={{ marginRight: 10 }} />
              )}
              Never (mute)
            </div>

            <div
              style={{
                fontSize: 16,
                width: '90%',
                margin: 5,
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              }}
            ></div>

            {/* {isMuted === 'muted' ? (
              <div
                style={styles.settings}
                onClick={async (e) => {
                  e.stopPropagation();
                  await conversation.client.setUserNotificationLevel('default');
                }}
              >
                <img
                  src={mute}
                  style={{ width: 17, height: 20, marginRight: 13 }}
                />
                Unmute Chat
              </div>
            ) : (
              <div
                style={styles.settings}
                onClick={async (e) => {
                  e.stopPropagation();
                  await conversation.client.setUserNotificationLevel('muted');
                }}
              >
                <img
                  src={unmute}
                  style={{ width: 17, height: 20, marginRight: 13 }}
                />
                Mute Chat
              </div>
            )} */}

            {/* <div style={styles.settings}>
              <img
                src={report}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              Report Chat
            </div> */}
            {isGroup && (
              <div
                style={styles.settings}
                onClick={async () => {
                  await conversation.client.leave();
                  dispatch(setOpenConversation({ page, conversation: null }));
                }}
              >
                <img
                  src={leave}
                  style={{ width: 20, height: 18, marginRight: 10 }}
                />
                Unfollow
              </div>
            )}
            {!isGroup && (
              <div
                style={styles.settings}
                onClick={async () => {
                  // await conversation.client.leave();
                  // dispatch(setOpenConversation(null));

                  // console.log('twilio user ', userTwilio.attributes);
                  dispatch(twilioBlockUser(page, conversation.client));
                }}
              >
                <BlockIcon style={{ marginRight: 10 }} />
                Block
              </div>
            )}

            {isPopUp === 'debug' && (
              <button
                onClick={async () => {
                  // Ask the backend for a twilio token
                  const data = await Auth.currentSession();
                  const response = await axios({
                    method: 'delete',
                    url: `/twilio/conversations/${conversation.client.sid}?conversationsServiceSid=${conversationsServiceSid}`,
                    headers: { idtoken: data.idToken.jwtToken },
                  });
                  dispatch(setOpenConversation({ page, conversation: null }));
                  // console.log(
                  //   '🚀 ~ file: ChatConversation.js ~ line 229 ~ onClick={ ~ response',
                  //   response
                  // );
                }}
              >
                DELETE CONVERSATION
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
});

const styles = {
  chatRoot: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // height: 500,
    width: '100%',
    height: '100%',
    // border: '1px solid',
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    // backgroundColor: 'purple',
  },
  chatHeader: {
    height: 100,
    backgroundColor: 'green',
  },
  chatContainer: {
    // flex: 1,
    // overflowX: 'hidden',
    // overflowY: 'scroll',
    // // maxHeight: 430,

    // display: 'flex',
    flex: 1,
    // backgroundColor: 'blue',
    backgroundColor: colors.backgroundGray,
    overflowX: 'hidden',
    overflowY: 'auto',
    // maxHeight: '45vh',
  },
  chatFooter: {
    // height: 100,
    // backgroundColor: 'rgb(0,0,0,0)',
    backgroundColor: colors.backgroundGray,
    // border: '2px solid pink',
    paddingTop: 10,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
  },
  settings: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-evenly',
    padding: 10,
    width: '100%',
    // border: '1px solid',
    fontSize: 16,
    cursor: 'pointer',
  },
};

export default ChatConversation;
