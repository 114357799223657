import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import NavBar from '../../Components/Navbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '80%',
  },
  formControlButton: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '50%',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
});

class ConfirmationCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      confirmationCode: '',
      confirmationCodeError: false,
      confirmationCodeErrorMessage: '',
      usernameError: false,
      usernameErrorMessage: '',
      userConfirmed: false,
    };
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event.target.value });
  }

  handleValidation = () => {
    Auth.confirmSignUp(this.state.username, this.state.confirmationCode)
      .then((response) => {
        this.setState({
          userConfirmed: true,
        });
      })
      .catch((error) => {
        if (error.code === 'NotAuthorizedException') {
          this.setState({
            confirmationCodeError: true,
            confirmationCodeErrorMessage: 'Incorrect Confirmation Code',
            usernameError: false,
            usernameErrorMessage: '',
          });
        } else if (error.code === 'UserNotFoundException') {
          this.setState({
            usernameError: true,
            usernameErrorMessage: 'Email not Found',
            confirmationCodeError: false,
            confirmationCodeErrorMessage: '',
          });
        } else {
          this.setState({
            usernameError: false,
            usernameErrorMessage: '',
            confirmationCodeError: true,
            confirmationCodeErrorMessage: 'Incorrect Confirmation Code',
          });
        }
      });
  };

  render() {
    const { classes } = this.props;

    let form;
    if (this.state.userConfirmed) {
      form = (
        <Paper className={classes.paper}>
          <Typography variant="h5">Success!</Typography>
          <FormControl className={classes.formControlButton}>
            <Button
              variant="contained"
              component={Link}
              to={'/signin'}
              className={classes.button}
            >
              Log In
            </Button>
          </FormControl>
        </Paper>
      );
    } else {
      form = (
        <Paper className={classes.paper}>
          <Typography variant="h5">Confirm Your Email Address</Typography>
          <br />
          <Typography variant="body1">
            Please check the email account you entered for your confirmation
            code and enter it below
          </Typography>
          <FormControl className={classes.formControl}>
            <TextField
              onChange={(event) => this.handleChange(event, 'username')}
              value={this.state.username}
              label="Email"
              error={this.state.usernameError}
              helperText={
                this.state.usernameErrorMessage
                  ? this.state.usernameErrorMessage
                  : ''
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              onChange={(event) => this.handleChange(event, 'confirmationCode')}
              value={this.state.confirmationCode}
              label="Confirmation Code"
              error={this.state.confirmationCodeError}
              helperText={
                this.state.confirmationCodeError
                  ? this.state.confirmationCodeErrorMessage
                  : ''
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Button
              variant="contained"
              onClick={this.handleValidation}
              className={classes.button}
            >
              Confirm Email
            </Button>
          </FormControl>
        </Paper>
      );
    }

    return (
      <div>
        <NavBar />
        <div className={classes.grid}>
          <Grid
            style={{ paddingTop: '85px' }}
            container
            layout={'row'}
            spacing={0}
            justify="center"
          >
            <Grid item xs={12} sm={3} md={3}>
              {form}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ConfirmationCode));
