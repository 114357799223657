// import { SyncClient } from 'twilio-sync';
var { SyncClient } = require('twilio-sync');

const getTwilioOneOnOneConversation = (
  conversations,
  userIdentity,
  identityToInvite
) => {
  let conversation;
  if (conversations) {
    // Search for a conversation where we are the only 2 particiapnts
    Object.keys(conversations).forEach((sid) => {
      const conv = conversations[sid];
      // console.log(
      //   '🚀 ~ file: twilioCreateJoinOneOnOneConversation.js ~ line 37 ~ Object.keys ~ conv',
      //   conv
      // );
      if (!conv?.client?.uniqueName?.startsWith('GROUP_')) {
        const participantIdenties = [];
        conv.client.participants.forEach((participant) => {
          participantIdenties.push(participant.identity);
        });
        // console.log(
        //   '🚀 ~ file: twilioCreateJoinOneOnOneConversation.js ~ line 46 ~ Object.keys ~ participantIdenties',
        //   participantIdenties
        // );
        if (
          participantIdenties.length === 2 &&
          participantIdenties.includes(userIdentity) &&
          participantIdenties.includes(identityToInvite)
        ) {
          console.log('found existing conversation');
          conversation = conv;
        }
      }
    });
  }

  return conversation;
};

export default getTwilioOneOnOneConversation;
