import {
  getConversationsClient,
  setCreatingConversation,
  setEngagement,
  setChatWindow,
  setOpenProfile,
  setConversation,
  storeConversation,
} from '../Reducers/twilio';
import getTwilioUserIdentity from '../../Utilities/getTwilioUserIdentity';
import getTwilioOneOnOneConversation from '../../Utilities/getTwilioOneOnOneConversation';
import { setOpenConversation } from '../Reducers/twilio';

/**
 *
 * @param {*} identityToInvite - twilio user identity
 * @returns
 */
export default function twilioCreateJoinOneOnOneConversation(identityToInvite) {
  return async (dispatch, getState) => {
    // Search Conversations to see if we already have a one on one conversation going

    const page = 'sidebar';

    const user = getState().user.user;

    const userTwilio = getState().twilio.user?.client;
    const otherUser = getState().twilio?.users?.[identityToInvite];

    // This user does not have a twilio id
    if (!otherUser) return null;

    // can't start a chat with ourselves
    if (userTwilio.identity === identityToInvite) return null;

    const haveIBlockedThisUser =
      userTwilio?.attributes?.blockList?.[identityToInvite];
    console.log(
      '🚀 ~ file: twilioCreateJoinOneOnOneConversation.js ~ line 33 ~ return ~ haveIBlockedThisUser',
      haveIBlockedThisUser
    );

    const haveIBeenBlocked =
      otherUser?.attributes?.blockList?.[userTwilio.identity];
    console.log(
      '🚀 ~ file: twilioCreateJoinOneOnOneConversation.js ~ line 37 ~ return ~ haveIBeenBlocked',
      haveIBeenBlocked
    );

    if (haveIBlockedThisUser || haveIBeenBlocked) {
      // console.log('opening profile');
      dispatch(setEngagement('profile'));
      dispatch(setOpenProfile(otherUser.client));
      return true;
    }

    const creatingConversation = getState().twilio.creatingConversation;
    const userIdentity = getTwilioUserIdentity(user);

    const client = getConversationsClient(getState());
    const inviteUser = await client?.getUser(identityToInvite);
    // console.log(
    //   '🚀 ~ file: twilioCreateJoinOneOnOneConversation.js ~ line 23 ~ return ~ inviteUser',
    //   inviteUser,
    //   inviteUser.attributes
    // );

    const conversations = getState().twilio.conversations;
    // console.log(
    //   '🚀 ~ file: twilioCreateJoinOneOnOneConversation.js ~ line 35 ~ return ~ conversations',
    //   conversations
    // );

    dispatch(setOpenConversation({ page, conversation: null }));

    let conversation = getTwilioOneOnOneConversation(
      conversations,
      userIdentity,
      identityToInvite
    );

    if (conversation) {
      console.log(
        '🚀 ~ file: twilioCreateJoinOneOnOneConversation.js ~ line 79 ~ return ~ conversation',
        conversation
      );
      dispatch(
        setOpenConversation({
          page,
          conversation: { sid: conversation['sid'] },
        })
      );
      // dispatch(twilioLoadOpenConversation({ page, conversation }));

      dispatch(setOpenProfile(null));
      dispatch(setEngagement('chat'));
      dispatch(setChatWindow(true));
      return conversation;
    }

    if (!creatingConversation) {
      console.log('creating conversation');
      dispatch(setCreatingConversation(true));
      dispatch(setOpenProfile(null));
      dispatch(setEngagement('chat'));
      dispatch(setChatWindow(true));
      // create conversation
      const conversationClient = await client.createConversation({
        attributes: {
          identity: identityToInvite,
        },
        friendlyName: `${inviteUser?.attributes?.first_name} ${inviteUser?.attributes?.last_name}`,
      });

      // Add myself
      await conversationClient.add(userIdentity);
      // Add desired user
      await conversationClient.add(identityToInvite);
      const createdConversation = storeConversation(conversationClient);

      dispatch(setConversation(createdConversation));
      dispatch(setCreatingConversation(false));
      dispatch(
        setOpenConversation({
          page,
          conversation: { sid: conversationClient.sid },
        })
      );
      // dispatch(twilioLoadOpenConversation(conversation));
      // console.log(
      //   '🚀 ~ file: twilioCreateJoinOneOnOneConversation.js ~ line 94 ~ return ~ conversation',
      //   conversation
      // );
      // console.log('NEW CONVERSATION CREATED');
      return createdConversation;
    }
    console.log('cannot create more than one conversation at a time');
    return false;
  };
}
