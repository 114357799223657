import React, { Component } from 'react';
// import axios from 'axios';
// import { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setDebug from '../../Store/Actions/setDebug';
// import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class Debug extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hostRoomUrl: 'https://whereby.com/john-onorato',
      roomUrl: 'https://whereby.com/john-onorato',
    };
  }

  // componentDidMount = async () => {

  // Auth.currentSession()
  //   .then((data) => {
  //     axios({
  //       method: 'post',
  //       url: '/conference/',
  //       headers: {
  //         idtoken: data.idToken.jwtToken,
  //       },
  //     })
  //       .then((response) => {
  //         console.log(response)
  //         this.setState({
  //             hostRoomUrl: response.data.hostRoomUrl,
  //             roomUrl: response.data.roomUrl,
  //         })
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //       });
  //   })
  // }

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        layout={'row'}
        spacing={2}
        className={classes.root}
        style={{ paddingTop: '50px' }}
      >
        <Grid item xs={12} md={12} lg={12}>
          <Paper className={classes.paper} variant="elevation">
            <div style={{ position: 'relative', paddingTop: '56.25%' }}>
              <iframe
                title="host"
                // src={this.state.hostRoomUrl}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                frameborder="0"
                src="https://planetconnectembed.whereby.com/d9f92d25-c44f-4014-b68d-1e3577d9db15?roomKey=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZWV0aW5nSWQiOiIzMTA1OTIxNSIsInJvb21SZWZlcmVuY2UiOnsicm9vbU5hbWUiOiIvZDlmOTJkMjUtYzQ0Zi00MDE0LWI2OGQtMWUzNTc3ZDlkYjE1Iiwib3JnYW5pemF0aW9uSWQiOiI5ODQ2NCJ9LCJpc3MiOiJodHRwczovL2FjY291bnRzLnNydi53aGVyZWJ5LmNvbSIsImlhdCI6MTYxODUwODU4NCwicm9vbUtleVR5cGUiOiJtZWV0aW5nSG9zdCJ9.R74Y07pSdJs7-VkbSSG0ZdigESODXvk81cPfYU7sP2A"
                allow="camera; microphone; fullscreen; speaker; display-capture"
              ></iframe>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const MyTheme = {
  button: { backgroundColor: '#E13E2F' },
};

function mapStateToProps(state) {
  return {
    debug: state.debug,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setDebug: setDebug,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(withAuthenticator(Debug, false, [], null, MyTheme)));
