import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import prepareObject from '../../Utilities/newObjectFromArrayFieldsAgenda';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import { SHARE_SITES } from 'react-add-to-calendar-hoc';
import DialogWrapperAgenda from '../../Components/DialogWrapperAgenda';
import ButtonWrapper from '../../Components/ButtonWrapper';
import AddIcon from '@material-ui/icons/Add';
import trackAction from '../../Utilities/tracker';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import moment from 'moment';

// icons
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';

const useStyles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  underTabsContainer: {
    // padding: theme.spacing(2, 1),
    textAlign: 'center',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
    textDecoration: 'none',
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  linePhoto: {
    borderRadius: '50%',
    width: '5rem',
    height: '5rem',
    [theme.breakpoints.down('xs')]: {
      width: '3rem',
      height: '3rem',
    },
  },
  lineTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingBottom: theme.spacing(1),
  },
  lineAbstract: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  lineSpeakerName: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  lineSpeakerInfo: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  tabSingle: {
    minWidth: 'auto',
  },
  mobileButtons: {
    maxWidth: '50%',
  },
  calendarContainer: {
    // display: 'flex',
  },
  linkStyles: {
    textDecoration: 'none',
    display: 'block',
    textAlign: 'center',
    padding: theme.spacing(1),
    color: 'black',
    fontSize: '1.25em',
  },
  calendarButton: {
    width: '40%',
    height: '100%',
    padding: theme.spacing(2),
    // borderRadius: '0px 15px 0px 5px',
  },
});

class AgendaFull extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      poster: null,
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  recordEvent = (data, name) => {
    let newName = name;
    if (name === 'Agenda' && this.props.agenda.archive_link) {
      newName = 'Archive';
    }
    const event = {
      action: 'Clicked',
      resource_type: newName,
      resource_id: data.abstract_id,
      url_visited: '/agenda',
      resource_account_id: data.account_id,
      resource_account_name: data.name,
      resource_name: data.title,
    };

    trackAction(event);
  };

  trackCalendarClick(e) {
    const event = {
      action: 'Added',
      resource_type: 'PersonalCalendar',
      resource_id: this.props.agenda.abstract_id,
      url_visited: '/agenda',
      resource_account_id: this.props.agenda.account_id,
      resource_account_name: this.props.agenda.account_name,
      resource_name: this.props.agenda.title,
    };

    trackAction(event);
  }

  clickPresentationPdf() {
    const event = {
      action: 'Clicked',
      resource_type: 'Agenda Pdf / Agenda',
      resource_id: this.props.agenda.abstract_id,
      url_visited: '/agenda',
      resource_account_id: this.props.agenda.account_id,
      resource_account_name: this.props.agenda.name,
      resource_name: this.props.agenda.title,
    };

    trackAction(event);

    window.open(this.state.agenda.presentation_pdf, '_blank');
  }

  clickIntroVideo() {
    const event = {
      action: 'Clicked',
      resource_type: 'Intro Video / Agenda',
      resource_id: this.props.agenda.abstract_id,
      url_visited: '/agenda',
      resource_account_id: this.props.agenda.account_id,
      resource_account_name: this.props.agenda.name,
      resource_name: this.props.agenda.title,
    };

    trackAction(event);

    window.open(this.state.agenda.presentation_video, '_blank');
  }

  clickViewPresentation() {
    const event = {
      action: 'Clicked',
      resource_type: 'Presentation',
      resource_id: this.props.agenda.abstract_id,
      url_visited: '/agenda',
      resource_account_id: this.props.agenda.account_id,
      resource_account_name: this.props.agenda.name,
      resource_name: this.props.agenda.title,
    };

    trackAction(event);

    window.open(this.state.agenda.presentation_link, '_blank');
  }

  clickViewArchive() {
    const event = {
      action: 'Clicked',
      resource_type: 'Presentation',
      resource_id: this.props.agenda.abstract_id,
      url_visited: '/agenda',
      resource_account_id: this.props.agenda.account_id,
      resource_account_name: this.props.agenda.name,
      resource_name: this.props.agenda.title,
    };

    trackAction(event);

    window.open(this.state.agenda.archive_link, '_blank');
  }

  componentDidMount() {
    if (this.props.agenda.contacts) {
      this.props.agenda.presenters = this.props.agenda.contacts.filter(
        (contact) =>
          contact.roles.includes('presenter') ||
          contact.roles.includes('Presenter')
      );
      this.props.agenda.authors = this.props.agenda.contacts.filter(
        (contact) =>
          contact.roles.includes('author') || contact.roles.includes('Author')
      );
      this.props.agenda.submitters = this.props.agenda.contacts.filter(
        (contact) =>
          contact.roles.includes('submitter') ||
          contact.roles.includes('Submitter')
      );
    } else {
      this.props.agenda.presenters = [];
      this.props.agenda.authors = [];
      this.props.agenda.submitters = [];
    }

    let requiredFields = [
      'description',
      'duration',
      'endDatetime',
      'location',
      'startDatetime',
      'title',
    ];
    // let requiredFields = ['description', 'duration', 'endDatetime', 'location', 'startDatetime', 'timezone', 'title']
    let calendarAbstract = prepareObject(
      this.props.agenda,
      requiredFields,
      'presentation'
    );

    this.setState({
      agenda: this.props.agenda,
      calendarAbstract,
    });

    // this.recordEvent(this.props.agenda, "Agenda")
  }

  briefcaseSelect() {
    this.setState({ tabValue: this.state.tabValue });

    let favorited = false;
    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (
          this.props.briefcase.briefcase[n].resource_id ===
          this.state.agenda.abstract_id
        ) {
          favorited = true;
          break;
        }
      }
    }

    if (favorited) {
      this.props.deleteBriefcaseElement(this.state.agenda.abstract_id);
    } else {
      this.props.addBriefcaseElement(
        'Presentation',
        this.state.agenda.abstract_id,
        this.state.agenda.title
        // this.state.agenda.account_id,
        // this.props.user.user.account_id
      );
    }
  }

  render() {
    const { classes } = this.props;

    let isInThePast = false;
    let archiveButton;

    if (this.state.agenda) {
      let date = this.state.agenda.date.split('T')[0];
      let time = this.state.agenda.end_time;
      time = moment(this.state.agenda.end_time)
        .add(15, 'minutes')
        .format('HH:mm:ss');
      let timeNow = moment().format('HH:mm:ss');
      let dateNow = moment().format('YYYY-MM-DD');

      switch (true) {
        case date < dateNow:
          // date is in the past
          isInThePast = true;
          break;
        case date === dateNow:
          // date is today
          if (time < timeNow) {
            // time is in the past
            isInThePast = true;
          } else {
            // time is in the future
          }
          break;
        default:
          // time is in the future
          break;
      }

      if (this.state.agenda.archive_link) {
        archiveButton = (
          <Button
            color="inherit"
            variant="contained"
            className={classes.button}
            onClick={() => this.clickViewArchive()}
          >
            View Archived Presentation
          </Button>
        );
      } else {
        archiveButton = (
          <Button
            disabled
            color="inherit"
            variant="contained"
            className={classes.button}
          >
            Archive Not Available
          </Button>
        );
      }
    }

    let AddToCalendarButton = AddToCalendarHOC(
      ButtonWrapper,
      DialogWrapperAgenda
    );

    let agendaContent;

    if (this.state.agenda) {
      switch (this.state.tabValue) {
        case 1:
          agendaContent = (
            <React.Fragment>
              <Grid item xs={12}>
                <Hidden xsDown>
                  <Typography variant="h4" color="textPrimary">
                    {this.state.agenda.title}
                  </Typography>
                  <br />
                  <Typography variant="h6" color="textPrimary">
                    <b>Topic: </b>
                    {this.state.agenda.topic}
                  </Typography>
                </Hidden>
                <Hidden smUp>
                  <Typography variant="body1" color="textPrimary">
                    <b>{this.state.agenda.title}</b>
                  </Typography>
                  <br />
                  <Typography variant="body2" color="textPrimary">
                    <b>Topic: </b>
                    {this.state.agenda.topic}
                  </Typography>
                </Hidden>
                <br />
                <Divider className={classes.divider} variant="fullWidth" />
              </Grid>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} sm={6}>
                  {isInThePast ? (
                    archiveButton
                  ) : (
                    <Button
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                      onClick={() => this.clickViewPresentation()}
                    >
                      Launch Presentation
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {this.state.agenda.presentation_pdf ? (
                    <Button
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                      onClick={() => this.clickPresentationPdf()}
                    >
                      Slides PDF
                    </Button>
                  ) : (
                    <Button
                      disabled
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                    >
                      Slides PDF
                    </Button>
                  )}
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  {this.state.agenda.presentation_video ? (
                    <Button
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                      onClick={() => this.clickIntroVideo()}
                    >
                      Intro Video
                    </Button>
                  ) : (
                    <Button
                      disabled
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                    >
                      Intro Video
                    </Button>
                  )}
                </Grid> */}
              </Grid>
              <Grid item xs={12}>
                <div onClick={(e) => this.trackCalendarClick(e)}>
                  <AddToCalendarButton
                    className={classes.calendarContainer}
                    event={this.state.calendarAbstract}
                    items={[
                      SHARE_SITES.GOOGLE,
                      SHARE_SITES.ICAL,
                      SHARE_SITES.OUTLOOK,
                    ]}
                    dropdownProps={{
                      header: (
                        <Typography
                          className={classes.linkStyles}
                          variant="h6"
                          color="textPrimary"
                        >
                          Select an option
                        </Typography>
                      ),
                    }}
                    buttonProps={{
                      variant: 'contained',
                      size: 'large',
                      color: 'primary',
                      className: classes.calendarButton,
                      startIcon: <AddIcon />,
                    }}
                    linkProps={{ className: classes.linkStyles }}
                  ></AddToCalendarButton>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} variant="fullWidth" />
                <br />
                <Hidden xsDown>
                  <Typography variant="h6" color="textPrimary" align="center">
                    <b>Abstract </b>
                  </Typography>
                  <pre
                    style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
                  >
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      align="justify"
                    >
                      {this.state.agenda.content}
                    </Typography>
                  </pre>
                </Hidden>
                <Hidden smUp>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    align="center"
                  >
                    <b>Abstract </b>
                  </Typography>
                  <pre
                    style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
                  >
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      align="justify"
                    >
                      {this.state.agenda.content}
                    </Typography>
                  </pre>
                </Hidden>
              </Grid>
            </React.Fragment>
          );
          break;
        case 2:
          const keyValue = (key, value) => {
            if (value) {
              return (
                <>
                  <b>{key}:</b> {value}
                </>
              );
            }
          };
          agendaContent = (
            <React.Fragment>
              <Grid item xs={12}>
                {this.state.agenda.presenters.length ? (
                  <React.Fragment>
                    <Typography variant="h5" color="textPrimary">
                      <b>Presenters</b>
                    </Typography>
                    <Divider className={classes.divider} variant="fullWidth" />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {this.state.agenda.presenters.map((presenter, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.lineSpeakerName}
                          variant="h6"
                        >
                          {`${presenter.first_name} ${presenter.last_name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {presenter.photo_url ? (
                          <img
                            className={classes.linePhoto}
                            src={presenter.photo_url}
                            alt={'Presenter'}
                          />
                        ) : null}
                      </Grid>
                      <Grid item xs={10} style={{ paddingLeft: '8px' }}>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Title', presenter.title)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Organization', presenter.account_name)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Department', presenter.department)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Expertise', presenter.expertise_area)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Email', presenter.email)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Biography', presenter.bio)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider
                          className={classes.divider}
                          variant="fullWidth"
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '3vw' }}>
                {this.state.agenda.authors.length ? (
                  <React.Fragment>
                    <Typography variant="h5" color="textPrimary">
                      <b>Authors</b>
                    </Typography>
                    <Divider className={classes.divider} variant="fullWidth" />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {this.state.agenda.authors.map((author, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.lineSpeakerName}
                          variant="h6"
                        >
                          {`${author.first_name} ${author.last_name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {author.photo_url ? (
                          <img
                            className={classes.linePhoto}
                            src={author.photo_url}
                            alt={'Presenter'}
                          />
                        ) : null}
                      </Grid>
                      <Grid item xs={10}>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Title', author.title)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Organization', author.account_name)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Department', author.department)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Expertise', author.expertise_area)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Email', author.email)}
                        </Typography>
                        {/* <Typography variant='body1' className={classes.lineSpeakerInfo}>
                            {keyValue('Biography', author.bio)}
                          </Typography> */}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider
                          className={classes.divider}
                          variant="fullWidth"
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </React.Fragment>
          );
          break;
        default:
          agendaContent = <div>No Content</div>;
          break;
      }
    } else {
      agendaContent = <div></div>;
      return <div></div>;
    }

    let favorited = false;
    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (
          this.props.briefcase.briefcase[n].resource_id ===
          this.state.agenda.abstract_id
        ) {
          favorited = true;
          break;
        }
      }
    }

    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} sm={2} align="left">
          <Button onClick={() => this.props.closeAgenda()}>
            <ChevronLeftIcon /> Back
          </Button>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {favorited ? (
              <Tab
                className={classes.tabSingle}
                onClick={() => {
                  this.briefcaseSelect();
                }}
                icon={<WorkIcon />}
              />
            ) : (
              <Tab
                className={classes.tabSingle}
                onClick={() => {
                  this.briefcaseSelect();
                }}
                icon={<WorkOutlineIcon />}
              />
            )}
            <Tab className={classes.tabSingle} label={'Presentation'} />
            <Tab className={classes.tabSingle} label={'Contributors'} />
          </Tabs>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={false} sm={2} align="right" />
        </Hidden>
        <Grid item xs={12}>
          {/* <Paper className={classes.paper}> */}
          <Grid
            className={classes.underTabsContainer}
            container
            spacing={2}
            justify="center"
            alignItems="center"
          >
            {agendaContent}
          </Grid>
          {/* </Paper> */}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(AgendaFull));
