import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';

const options = {
  // indexAxis: 'y',
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
    },
    title: {
      display: true,
      text: 'Activity by Site',
    },
  },
};

export default function Reporting(props) {
  const [filteredReport, setFilteredReport] = useState([]);

  useEffect(() => {
    let siteClickCount = {};

    props.report.map((datum) => {
      if (!Object.keys(siteClickCount).includes(datum.visitor_site)) {
        siteClickCount[datum.visitor_site] = 1;
      } else {
        siteClickCount[datum.visitor_site]++;
      }

      return true;
    });

    let sortedSiteClickCount = Object.entries(siteClickCount)
      .sort(([, a], [, b]) => b - a)
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    let labels = Object.keys(sortedSiteClickCount).slice(0, 10);
    let data = Object.values(sortedSiteClickCount).slice(0, 10);

    setFilteredReport({
      labels: labels,
      datasets: [
        {
          label: 'Total Clicks',
          data: data,
          fill: true,
          backgroundColor: [
            '#6F1A1D',
            '#055864',
            '#FB4D3D',
            '#50B2C0',
            '#FFEAAE',
            '#8CDEDC',
            '#94C5CC',
            '#86BA90',
            '#03CEA4',
            '#345995',
          ],
          hoverOffset: 4,
          borderColor: 'white',
        },
      ],
    });
  }, [props.report]);

  return props.loading ? (
    <CircularProgress />
  ) : (
    <Pie data={filteredReport} options={options} />
  );
}
