import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import axios from 'axios';
import { demoEnabled } from '../config.js';
// import logo from '../Assets/LandingPageImages/';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: 'none',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
  },
  centerButtons: {
    textAlign: 'center',
    lineHeight: 1.25,
  },
  toolbar: {
    minHeight: '64px', //required to make mobile behave properly
  },
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();
  const [abstractAvailable, setAbstractAvailable] = useState();

  useEffect(() => {
    axios({
      method: 'get',
      url: 'forms/' + props.event,
      // headers: { idtoken: data.idToken.jwtToken },
      params: { showId: props.event },
    })
      .then((response) => {
        let formObject = response.data.data;
        let abstractSubmission = formObject.filter(
          (item) => item.type === 'abstract_submission'
        )[0];
        setAbstractAvailable(abstractSubmission.is_enabled);
      })
      .catch((error) => console.log(error));
  }, [props.event]);

  return (
    <AppBar position="fixed" className={clsx(classes.appBar)}>
      <Toolbar variant="regular" className={classes.toolbar}>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
          component={Link}
          to={'/'}
        >
          Home
        </Typography>
        {/* {abstractAvailable ? (
          <Button
            className={classes.centerButtons}
            color="inherit"
            component={Link}
            to={'/abstractsubmission'}
          >
            Abstract
            <br />
            Submission
          </Button>
        ) : null} */}
        {/* <Button className={classes.centerButtons} color="inherit" component={Link} to={'/signin'}>Existing User</Button>
          <Button className={classes.centerButtons} color="inherit" component={Link} to={'/signup'}>New User</Button> */}
        {!demoEnabled ? (
          <Button
            className={classes.centerButtons}
            color="inherit"
            component={Link}
            to={'/registration'}
          >
            Enter
          </Button>
        ) : null}
        {demoEnabled ? (
          <Button
            className={classes.centerButtons}
            color="inherit"
            component={Link}
            to={'/registration'}
          >
            Demo <br />
            Environment
          </Button>
        ) : null}
      </Toolbar>
    </AppBar>
  );
}
