import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';

import { SHARE_SITES } from 'react-add-to-calendar-hoc';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import DialogWrapperAgenda from '../../Components/DialogWrapperAgenda';
import ButtonWrapper from '../../Components/ButtonWrapper';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';

const useStyles = (theme) => ({
  root: {
    width: '100%',
    '& .MuiTypography-root': {
      alignSelf: 'center',
    },
    '& .MuiChip-root	': {
      alignSelf: 'center',
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0.5),
    },
  },
  expansionPanelMain: {
    padding: '0px 8px 0px 8px',
    display: 'flex',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: '60%',
    flexShrink: 0,
  },
  panelExpanded: {},
  icon: {
    minWidth: '10em',
    maxWidth: '30em',
    maxHeight: '30em',
    width: '100%',
  },
  buttonLearn: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  textBody: {
    alignSelf: 'center',
  },
  calendarContainer: {
    height: '100%',
  },
  calendarButton: {
    height: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  linkStyles: {
    textDecoration: 'none',
    display: 'block',
    textAlign: 'center',
    padding: theme.spacing(1),
    color: 'black',
    fontSize: '1.25em',
  },
});

class BriefcaseListItem extends Component {
  navigate() {
    if (this.props.favorite.resource_id) {
      this.props.history.push(
        `/${this.props.route}?${this.props.type}=${this.props.favorite.resource_id}`
      );
    } else {
      this.props.history.push(
        `/${this.props.route}?${this.props.type}=${this.props.favorite.abstract_id}`
      );
    }
  }

  deleteFromBriefcase() {
    if (
      this.props.type === 'presentation_id' ||
      this.props.type === 'workshop_id'
    ) {
      this.props.deleteBriefcaseElement(this.props.favorite.abstract_id);
      this.props.deleteBriefcaseElement(
        this.props.briefcaseAbstract.resource_id
      );
      this.props.updateState(this.props.favorite.abstract_id);
    } else {
      this.props.deleteBriefcaseElement(this.props.favorite.resource_id);
    }
  }

  render() {
    const { classes } = this.props;
    let AddToCalendarButton = AddToCalendarHOC(
      ButtonWrapper,
      DialogWrapperAgenda
    );

    // handle case for presenter from presentations
    let firstPresenter = null;
    if (this.props.favorite.contacts) {
      this.props.favorite.contacts.forEach((contact, index) => {
        if (
          contact.roles.includes('presenter') ||
          contact.roles.includes('Presenter')
        ) {
          if (!firstPresenter) {
            firstPresenter = contact;
          }
        }
      });
    }

    // handle case for presenter from posters
    if (this.props.favorite.presenter) {
      this.props.favorite.presenterFormatted = JSON.parse(
        this.props.favorite.presenter
      );
    }

    return (
      <Accordion key={this.props.index} className={classes.root}>
        <AccordionSummary
          className={classes.expansionPanelMain}
          expandIcon={<ExpandMoreIcon />}
          id={this.props.index}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.heading}
                variant="subtitle1"
                style={{ flexGrow: 6, fontWeight: '500' }}
              >
                {this.props.favorite.resource_title
                  ? this.props.favorite.resource_title
                  : this.props.favorite.title}
              </Typography>
            </Grid>
            {this.props.type === 'presentation_id' ||
            this.props.type === 'workshop_id' ? (
              <Grid item>
                {firstPresenter ? (
                  <Typography
                    className={classes.heading}
                    variant="subtitle1"
                    style={{ flexGrow: 6 }}
                  >
                    {`${firstPresenter.first_name} ${firstPresenter.last_name} - ${firstPresenter.account_name}`}
                  </Typography>
                ) : null}
              </Grid>
            ) : null}
            {this.props.type === 'poster_id' ? (
              <Grid item>
                {this.props.favorite.presenterFormatted ? (
                  <Typography
                    className={classes.heading}
                    variant="subtitle1"
                    style={{ flexGrow: 6 }}
                  >
                    {`${this.props.favorite.presenterFormatted.first_name} ${this.props.favorite.presenterFormatted.last_name} - ${this.props.favorite.presenterFormatted.account_name}`}
                  </Typography>
                ) : null}
              </Grid>
            ) : null}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            className={classes.panelExpanded}
            container
            layout={'row'}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            {this.props.type === 'product_id' ? (
              <>
                <Grid item align="left" xs={12}>
                  <Typography className={classes.textBody}>
                    <b>Company:</b> {this.props.favorite.resource_owner}
                  </Typography>
                </Grid>
              </>
            ) : null}
            {this.props.type === 'presentation_id' ||
            this.props.type === 'workshop_id' ? (
              <>
                <Grid item align="left" xs={6}>
                  <Typography className={classes.textBody}>
                    <b>Start:</b>{' '}
                    {moment(this.props.favorite.start_time).format(
                      'dddd, MMMM Do'
                    )}
                  </Typography>
                </Grid>
                <Grid item align="right" xs={6}>
                  <Typography className={classes.textBody}>
                    <b>Time:</b>{' '}
                    {moment(this.props.favorite.start_time).format('h:mm a')} -{' '}
                    {moment(this.props.favorite.end_time).format('h:mm a')}
                  </Typography>
                </Grid>
              </>
            ) : null}
            {/* {this.props.type === 'workshop_id' ?
          <>
          <Grid item align='left' xs={6}>
            <Typography className={classes.textBody}>
            <b>Start:</b> {moment(this.props.favorite.start_time).format("dddd, MMMM Do")} 
            </Typography>
          </Grid>
          <Grid item align='right' xs={6}>
            <Typography className={classes.textBody}>
            <b>Time:</b> {moment(this.props.favorite.start_time).format("h:mm a")}
            </Typography>
          </Grid> 
          </> : 
          null} */}

            <Grid item align="right" style={{ flexShrink: 1 }}>
              <Button
                variant="contained"
                size="medium"
                onClick={() => {
                  this.deleteFromBriefcase();
                }}
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </Grid>
            <Grid item align="right" style={{ flexShrink: 1 }}>
              <Button
                className={classes.buttonLearn}
                variant="contained"
                size="medium"
                onClick={() => this.navigate()}
                startIcon={<CheckIcon />}
              >
                Revisit
              </Button>
            </Grid>
            {this.props.type === 'presentation_id' ||
            this.props.type === 'workshop_id' ? (
              <Grid item align="right" style={{ flexShrink: 1 }}>
                <AddToCalendarButton
                  className={classes.calendarContainer}
                  event={this.props.briefcaseAbstract}
                  items={[
                    SHARE_SITES.GOOGLE,
                    SHARE_SITES.ICAL,
                    SHARE_SITES.OUTLOOK,
                  ]}
                  dropdownProps={{
                    onMouseLeave: () => null,
                    header: (
                      <Typography
                        className={classes.linkStyles}
                        variant="h6"
                        color="textPrimary"
                      >
                        Select an option
                      </Typography>
                    ),
                    footer: (
                      <Button variant="contained" color="secondary" fullWidth>
                        Close
                      </Button>
                    ),
                  }}
                  buttonProps={{
                    variant: 'contained',
                    size: 'large',
                    color: 'primary',
                    className: classes.calendarButton,
                    startIcon: <AddIcon />,
                  }}
                  linkProps={{ className: classes.linkStyles }}
                ></AddToCalendarButton>
              </Grid>
            ) : null}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteBriefcaseElement: deleteBriefcaseElement,
    },
    dispatch
  );
}

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(BriefcaseListItem)));
